import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class DateJsonConverter implements JsonCustomConvert<Date | null> {
  public deserialize(date: string): Date | null {
    if (date) {
      const dateArray = date.split(/\D/);
      // TODO: Utiliser GMT+0
      // 2021-04-03T05:00:00.000Z+01:00 ???
      const parsedDate = new Date(
        parseInt(dateArray[0], 10),
        parseInt(dateArray[1], 10) - 1,
        parseInt(dateArray[2], 10),
        parseInt(dateArray[3], 10),
        parseInt(dateArray[4], 10),
        parseInt(dateArray[5], 10),
        parseInt(dateArray[6], 10)
      );
      return parsedDate;
    }
    return null;
  }

  public serialize(date: Date): string | null {
    if (date === null) {
      return null;
    }
    return date.toISOString();
  }
}
