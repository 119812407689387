import { WorkOrdersService } from '@al/state';
import { AlSyncHelperService } from '@al/sync-helper';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlSyncService {
  public constructor(
    private syncHelperService: AlSyncHelperService,
    private workOrdersService: WorkOrdersService
  ) {}

  public pushBeforeLogout(): Observable<boolean> {
    const obs = new BehaviorSubject<boolean>(false);
    this.syncHelperService.pushBeforeLogout().subscribe((res) => {
      obs.next(res);
    });
    return obs.asObservable();
  }

  public sync(): void {
    this.syncHelperService.sync(this.workOrdersService.get(true));
  }
}
