import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentOptions {
  public acgUserPoolClientCallback = '';

  public acgUserPoolClientId = '';

  public acgUserPoolDomain = '';

  public acgUserPoolLogoutEndpoint = '';

  public apiUrl = '';

  public apigwUrl = '';

  public canAddFile = false;

  public canAddPhoto = false;

  public development = false;

  public googleClientId = '';

  public googleDrive = '';

  public googleDriveFolderAutomaticMapping = false;

  public googleDriveFolderMap: any = {};

  public gtmContainerId = '';

  public pingUrl = '';

  public production = false;

  public title = '';
}
