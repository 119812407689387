<div class="login">
  <div class="wrapper">
    <img src="assets/images/logo_airliquide.png" alt="Air Liquide">
    <h1>{{ title }}</h1>
    <div class="subtitle" *ngIf="!previousLoginFailed">
      <span class="text" translate>Log in with</span>
      <div class="horizontal-separator"></div>
    </div>
    <div class="subtitle" *ngIf="previousLoginFailed">
      <span class="error" translate *ngIf="errorType === 'login_failed'">Login failed, check your account</span>
      <span class="error" translate *ngIf="errorType === 'site_missing'">No default site, check your account</span>
      <div class="horizontal-separator"></div>
    </div>
    <al-session-cas></al-session-cas>
  </div>
</div>
