import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { TranslateModule } from '@ngx-translate/core';
import { MatSidenavModule } from '@angular/material/sidenav';

import { AlDialogModule } from '@al/dialog';
import { AlNavigationModule } from '@al/navigation';
import { AlWorkOrderChildrenListModule } from '@al/work-order-children-list';
import { AlWorkOrderDescriptionModule } from '@al/work-order-description';
import { AlWorkOrderFailureReportModule } from '@al/work-order-failure-report';
import { AlWorkOrderSummaryModule } from '@al/work-order-summary';
import { AlWorkOrderTaskListModule } from '@al/work-order-task-list';

import { WorkOrderComponent } from './work-order.component';

@NgModule({
  declarations: [WorkOrderComponent],
  imports: [
    AlDialogModule,
    AlNavigationModule,
    AlWorkOrderChildrenListModule,
    AlWorkOrderDescriptionModule,
    AlWorkOrderFailureReportModule,
    AlWorkOrderSummaryModule,
    AlWorkOrderTaskListModule,
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatSidenavModule,
    TranslateModule,
  ],
})
export class WorkOrderModule {}
