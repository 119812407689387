import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { WorkOrder } from '@al/entities';
import { WorkOrdersQuery, WorkOrdersService } from '@al/state';
import { MatTableDataSource } from '@angular/material/table';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AlSpinnerService } from '@al/spinner';

@Component({
  selector: 'al-work-order-children-list',
  templateUrl: 'al-work-order-children-list.component.html',
  styleUrls: ['al-work-order-children-list.component.scss'],
})
export class AlWorkOrderChildrenListComponent implements OnInit, OnDestroy {
  @Input()
  public viewDetails = false;

  public dataSource = new MatTableDataSource<WorkOrder>([]);

  public displayedColumns: string[] = [
    'description',
    'assetNum',
    'location',
    'objectiveEndDate',
    'workType',
    'priority',
  ];

  public taskCount = 0;

  public workOrder!: WorkOrder;

  private ngUnsubscribe = new Subject();

  public constructor(
    private router: Router,
    private workOrdersQuery: WorkOrdersQuery,
    private workOrdersService: WorkOrdersService,
    private alSpinnerService: AlSpinnerService
  ) {}

  public getChildrens(workOrder: WorkOrder): void {
    this.alSpinnerService.startDisplay(9);

    this.workOrdersService
      .getWorkOrderByParent(workOrder.number)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (workOrders: WorkOrder[]) => {
          if (workOrders) {
            this.dataSource = new MatTableDataSource<WorkOrder>(workOrders);
            this.taskCount = workOrders.length;
          } else {
            this.dataSource = new MatTableDataSource<WorkOrder>([]);
          }
          this.alSpinnerService.stopDisplay(9);
        },
        error: () => {
          this.dataSource = new MatTableDataSource<WorkOrder>([]);
          this.alSpinnerService.stopDisplay(9);
        },
      });
  }

  public get getWorkOrderNumber(): string {
    return this.workOrder && this.workOrder.number ? this.workOrder.number : '';
  }

  public navigateSummary(row: WorkOrder): void {
    this.viewDetails = !this.viewDetails;
    this.workOrdersService.setActive(row.id);
    this.router.navigate(['/', 'workorder']);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    this.workOrdersQuery
      .selectActive()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: WorkOrder | undefined) => {
        if (res) {
          this.workOrder = res;
          this.getChildrens(this.workOrder);
        }
      });
  }

  public viewDetailsSwitch(): void {
    this.viewDetails = !this.viewDetails;
  }
}
