import { AlCommonComponent } from '@al/common';
import {
  RelatedWoNumber,
  WorkOrder,
  WorkOrderPut,
  WorkOrderPutResponse,
} from '@al/entities';
import { AlIndexedDbService } from '@al/indexed-db';
import { AlReferenceService } from '@al/reference';
import {
  AlRequestInformationDialogComponent,
  AlRequestInformationDialogData,
  AlRequestInformationDialogDataWoPut,
} from '@al/request-information-dialog';
import { AlSpinnerService } from '@al/spinner';
import {
  EquipmentsService,
  ProcessGroupsService,
  ProductionUnitsService,
  SiteQuery,
  WorkOrdersQuery,
  WorkOrdersService,
} from '@al/state';
import { SyncInfoService, SyncInfoStatus } from '@al/sync-services';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-create-bt',
  templateUrl: './al-create-bt.component.html',
  styleUrls: ['./al-create-bt.component.scss'],
})
export class AlCreateBtComponent
  extends AlCommonComponent
  implements OnInit, OnDestroy
{
  @ViewChild(FormGroupDirective)
  public formGroupDirective: FormGroupDirective | undefined;

  public inherit = false;

  public putRequest: WorkOrderPut | null;

  protected reloaded: WorkOrderPut | null;

  private workOrderParent!: WorkOrder;

  public get actualStartDateTime() {
    return this.form.get('actualStartDateTime');
  }

  public get actualFinishDateTime() {
    return this.form.get('actualFinishDateTime');
  }

  public constructor(
    public alReferenceService: AlReferenceService,
    public dialog: MatDialog,
    protected alIndexedDbService: AlIndexedDbService,
    protected router: Router,
    private activatedRoute: ActivatedRoute,
    private alSpinnerService: AlSpinnerService,
    private readonly formBuilder: FormBuilder,
    private synchroInfoService: SyncInfoService,
    private workOrdersQuery: WorkOrdersQuery,
    private workOrdersService: WorkOrdersService,
    fb: FormBuilder,
    processGroupService: ProcessGroupsService,
    productionUnitsService: ProductionUnitsService,
    siteQuery: SiteQuery,
    equipmentsService: EquipmentsService
  ) {
    super(
      dialog,
      alIndexedDbService,
      router,
      fb,
      processGroupService,
      productionUnitsService,
      equipmentsService,
      siteQuery
    );
    this.putRequest = this.getNewPutRequest();
    this.reloaded = null;
    this.references = alReferenceService.getReferenceData([
      'domain',
      'workOrderJobType',
      'taskPriority',
      'workOrderSource',
      'workType',
    ]);
    if (this.router.getCurrentNavigation()?.extras?.state?.inherit) {
      this.inherit = true;
    }
    this.form = this.buildFormGroup();
  }

  public checkDateValidators(): void {
    this.form.get('actualStartDateTime')?.updateValueAndValidity();
    this.form.get('actualFinishDateTime')?.updateValueAndValidity();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    const synchroInfoUUid = this.activatedRoute.snapshot.params.synchroInfoUuid;
    this.reloaded =
      this.synchroInfoService.getCreateWorkOrderPut(synchroInfoUUid);

    this.synchroInfoService
      .getErrorByUuid(synchroInfoUUid)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((info) => {
        if (info) {
          this.synchroInfo = info;
        }
      });
    this.synchroInfoService
      .getPendingByUuid(synchroInfoUUid)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((info) => {
        if (info) {
          this.synchroInfo = info;
        }
      });

    this.workOrdersQuery
      .selectActive()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: WorkOrder | undefined) => {
        if (res) {
          this.workOrderParent = res;
        }
      });

    this.initFormGroup();
  }

  public submitWorkOrder(): void {
    if (this.form.valid && this.putRequest) {
      const formValues = this.form.value;
      this.putRequest.siteId = this.getSiteId();
      this.putRequest.type = formValues.woType;
      this.putRequest.priority = formValues.woPriority;
      this.putRequest.source = formValues.source;
      if (formValues.woParent) {
        const woParentNumber = new RelatedWoNumber();
        woParentNumber.woNumber = formValues.woParent.toString();
        this.putRequest.relatedTo = [];
        this.putRequest.relatedTo.push(woParentNumber);
      } else {
        this.putRequest.relatedTo = [];
      }
      this.putRequest.report = new Date(formValues.actualStartDateTime);
      this.putRequest.objectiveEnd = new Date(formValues.actualFinishDateTime);
      this.putRequest.domain = formValues.domain;
      this.putRequest.jobType = formValues.jobTypeOption;
      this.putRequest.location = this.getLocation();
      this.putRequest.assetNum = this.getAsset();
      this.putRequest.remark = formValues.remarks;
      this.putRequest.remarkDetails = formValues.longDescription;
      this.putRequest.description = formValues.description;

      if (this.reloaded && this.reloaded.uuid) {
        this.putRequest.uuid = this.reloaded.uuid;
      }

      this.alSpinnerService.startDisplay(3);

      this.workOrdersService
        .create(this.putRequest)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: WorkOrderPutResponse) => {
          if (
            this.synchroInfo &&
            (res.status === SyncInfoStatus.OK ||
              res.status === SyncInfoStatus.PENDING)
          ) {
            this.synchroInfoService.deleteFromHistory(this.synchroInfo);
            this.synchroInfo = undefined;
            this.reloaded = null;
          }
          if (
            this.putRequest &&
            this.putRequest.uuid &&
            res &&
            res.workOrderNum
          ) {
            this.alIndexedDbService.updateWorkOrderNumber(
              this.putRequest.uuid,
              res.workOrderNum
            );
          }
          this.displayCreation(res);
        });
    }
  }

  protected buildDialogInformation(
    res: WorkOrderPutResponse
  ): AlRequestInformationDialogData<AlRequestInformationDialogDataWoPut> {
    const { type, error, siteId, status, workOrderId, workOrderNum } = res;
    return {
      title: 'createWorkOrder',
      type: 'woPut',
      information: {
        action: type,
        error: error ?? undefined,
        siteId,
        status,
        workOrderId: workOrderId ?? undefined,
        workOrderNum,
      },
    };
  }

  protected buildFormGroup(): FormGroup {
    return this.formBuilder.group({
      woType: this.formBuilder.control('', Validators.required),
      woPriority: this.formBuilder.control('', Validators.required),
      source: this.formBuilder.control('', Validators.required),
      woParent: this.formBuilder.control(''),
      actualStartDateTime: this.formBuilder.control(false, [
        Validators.required,
        this.wrongDateValidator(),
      ]),
      actualFinishDateTime: this.formBuilder.control(false, [
        Validators.required,
        this.wrongFinishDateValidator(),
      ]),
      domain: this.formBuilder.control('', Validators.required),
      jobTypeOption: this.formBuilder.control('', Validators.required),
      location: this.formBuilder.control(''),
      asset: this.formBuilder.control(''),
      remarks: this.formBuilder.control(
        '',
        Validators.maxLength(this.DESCRIPTION_MAX_LENGTH)
      ),
      description: this.formBuilder.control(
        this.reloaded && this.reloaded.description
          ? this.reloaded.description
          : '',
        Validators.maxLength(this.DESCRIPTION_MAX_LENGTH)
      ),
      longDescription: this.formBuilder.control(
        this.reloaded && this.reloaded.remarkDetails
          ? this.reloaded.remarkDetails
          : '',
        Validators.maxLength(this.LONG_DESCRIPTION_MAX_LENGTH)
      ),
    });
  }

  protected displayCreation(data: WorkOrderPutResponse): void {
    this.alSpinnerService.stopDisplay(3);
    AlRequestInformationDialogComponent.open<
      WorkOrderPutResponse,
      AlRequestInformationDialogDataWoPut
    >(data, this.dialog, this.buildDialogInformation.bind(this))
      .afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((status) => {
        if (status === 'OK' || status === 'PENDING') {
          this.resetForm();
        }
      });
  }

  protected getNewPutRequest(): WorkOrderPut {
    return new WorkOrderPut();
  }

  protected initFormGroup(): void {
    const today = new Date();
    const addOneMin = new Date();
    const timeZoneDiff = today.getTimezoneOffset();
    today.setMinutes(today.getMinutes() - timeZoneDiff);
    addOneMin.setMinutes(addOneMin.getMinutes() - timeZoneDiff + 1);
    this.initLocation();

    this.form.get('woType')?.setValue(this.reloaded ? this.reloaded.type : '');

    this.form
      .get('woPriority')
      ?.setValue(this.reloaded ? this.reloaded.priority : '');

    this.form
      .get('source')
      ?.setValue(this.reloaded ? this.reloaded.source : '');

    this.form
      .get('woParent')
      ?.setValue(
        this.reloaded &&
          this.reloaded.relatedTo &&
          this.reloaded.relatedTo.length > 0
          ? this.reloaded.relatedTo[0].woNumber
          : ''
      );

    this.form
      .get('jobTypeOption')
      ?.setValue(this.reloaded ? this.reloaded.jobType : '');

    this.form
      .get('domain')
      ?.setValue(this.reloaded ? this.reloaded.domain : '');

    this.form
      .get('asset')
      ?.setValue(this.reloaded ? this.reloaded.assetNum : '');

    this.form
      .get('remarks')
      ?.setValue(this.reloaded ? this.reloaded.remark : '');

    this.form
      .get('description')
      ?.setValue(this.reloaded ? this.reloaded.description : '');

    this.form
      .get('longDescription')
      ?.setValue(this.reloaded ? this.reloaded.remarkDetails : '');

    this.form
      .get('actualStartDateTime')
      ?.setValue(
        this.reloaded && this.reloaded.report
          ? new Date(this.reloaded.report).toISOString().substring(0, 16)
          : today.toISOString().substring(0, 16)
      );

    this.form
      .get('actualFinishDateTime')
      ?.setValue(
        this.reloaded && this.reloaded.objectiveEnd
          ? new Date(this.reloaded.objectiveEnd).toISOString().substring(0, 16)
          : addOneMin.toISOString().substring(0, 16)
      );

    this.form.get('actualStartDateTime')?.markAsTouched();
    this.form.get('actualFinishDateTime')?.markAsTouched();

    if (this.inherit) {
      this.form
        .get('woParent')
        ?.setValue(
          this.reloaded && this.reloaded.number
            ? this.reloaded && this.reloaded.number
            : this.workOrderParent.number
        );
    }
    this.checkDateValidators();
  }

  private wrongDateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let forbidden = false;
      if (control.value) {
        const futureDate = new Date(control.parent?.value.actualFinishDateTime);
        const pastDate = new Date(control.value);
        forbidden = +futureDate <= +pastDate;
      } else {
        forbidden = false;
      }
      return forbidden ? { wrongDate: { value: control.value } } : null;
    };
  }

  private wrongFinishDateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let forbidden = false;
      if (control.value) {
        const futureDate = new Date(control.value);
        const pastDate = new Date(control.parent?.value.actualStartDateTime);
        forbidden = +futureDate <= +pastDate;
      } else {
        forbidden = false;
      }
      return forbidden ? { wrongDate: { value: control.value } } : null;
    };
  }
}
