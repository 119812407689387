import { AlReferenceData } from './model/al-reference-data.model';

export const TaskPriorityReference: ReadonlyArray<AlReferenceData> = [
  { priority: 'P1', label: 'Urgent' },
  { priority: 'P2', label: 'High' },
  { priority: 'P3', label: 'Medium' },
  { priority: 'P5', label: 'For Very Small Works' },
  { priority: 'P8', label: 'Next Opportunity' },
  { priority: 'P9', label: 'Next TAR' },
];
