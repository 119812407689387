export enum SyncInfoType {
  FR_CREATION = 'FR_CREATION',
  FR_UPDATE = 'FR_UPDATE',
  QR_CREATION = 'QR_CREATION',
  QR_UPDATE = 'QR_UPDATE',
  SR_CREATION = 'SR_CREATION',
  SR_UPDATE = 'SR_UPDATE',
  TASK_CREATION = 'TASK_CREATION',
  TASK_UPDATE = 'TASK_UPDATE',
  WO_CREATION = 'WO_CREATION',
  WO_UPDATE = 'WO_UPDATE',
}
