import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AlEnvironmentService, AwsEndPoint } from '@al/environment';
import {
  LocalStorageKey,
  EquipmentPut,
  EquipmentPutResponse,
  Equipment,
  Site,
} from '@al/entities';

import { Observable } from 'rxjs';
import { AbstractRestService } from './abstract-rest.service';

@Injectable({
  providedIn: 'root',
})
// use abstract Put types due to the miss of put action on this kind of model
export class EquipmentsRestService extends AbstractRestService<
  Equipment,
  EquipmentPut,
  EquipmentPutResponse
> {
  protected activeSite!: Site;

  protected keyLastDate: LocalStorageKey;

  protected keyLastRowstamp: LocalStorageKey;

  protected get createEndPoint(): string {
    throw new Error('Method not implemented.');
  }

  protected get getEndPoint(): string {
    return this.activeSite
      ? `${AwsEndPoint.equipmentGet}/${this.activeSite.uuid}`
      : '';
  }

  protected get updateEndPoint(): string {
    throw new Error('Method not implemented.');
  }

  public constructor(
    protected alEnvironmentService: AlEnvironmentService,
    protected http: HttpClient
  ) {
    super(http);
    this.keyLastDate = LocalStorageKey.EQUIPMENT_SYNCHRO_LAST_DATE;
    this.keyLastRowstamp = LocalStorageKey.EQUIPMENT_SYNCHRO_LAST_ROWSTAMP;
  }

  public getFromSite(site: Site): Observable<Equipment[]> {
    this.activeSite = site;
    return this.get();
  }

  protected getClazz(): typeof Equipment {
    return Equipment;
  }

  protected getPutRequestClazz(): typeof EquipmentPut {
    return EquipmentPut;
  }

  protected getPutResponseClazz(): typeof EquipmentPutResponse {
    return EquipmentPutResponse;
  }
}
