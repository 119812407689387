<div class="al-work-order-children-list" *ngIf="taskCount !== 0">
  <div class="header">
    <h1 translate>CHILD WORK ORDERS:</h1>
    <span class="completedCount">{{taskCount}}</span>
    <ng-container *ngIf="viewDetails; else elseTemplate">
      <span class="details" (click)="viewDetailsSwitch()" translate>Hide details</span>
    </ng-container>
    <ng-template #elseTemplate>
      <span class="details" (click)="viewDetailsSwitch()" translate>View details</span>
    </ng-template>
  </div>
  <div class="content" [ngClass]="{ show: viewDetails, hide: !viewDetails }">
    <div class="al-work-order-list">
       <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!--/ WO Short description /-->
        <ng-container matColumnDef="description">
          <mat-cell *matCellDef="let element" data-label="WO Short description" >
            <span>
              <p class="description">{{ element.description }}
              <p>{{ element.number }}</p>
            </span>
          </mat-cell>
        </ng-container>

        <!--/ Equipment /-->
        <ng-container matColumnDef="assetNum">
          <mat-cell *matCellDef="let element" data-label="Equipment">
            {{ element.assetNum }}
          </mat-cell>
        </ng-container>

        <!--/ Location /-->
        <ng-container matColumnDef="location">
          <mat-cell *matCellDef="let element" data-label="Location">
            {{ element.location }}
          </mat-cell>
        </ng-container>

        <!--/ Objective end date /-->
        <ng-container matColumnDef="objectiveEndDate">
          <mat-cell *matCellDef="let element" data-label="Objective End Date">
            <span *ngIf="element.schedDateFin">
             {{  element.schedDateFin  | localizedDate :'shortDate' }}
            </span>
            <span *ngIf="!element.schedDateFin">
             {{ element.targetCompDate| localizedDate :'shortDate' }}
            </span>
           </mat-cell>
        </ng-container>

        <!--/ Work type /-->
        <ng-container matColumnDef="workType">
          <mat-cell *matCellDef="let element" data-label="Work Type">
            <mat-chip-list>
              <mat-chip> {{ element.type }} </mat-chip>
            </mat-chip-list>
          </mat-cell>
        </ng-container>

        <!--/ Priority /-->
        <ng-container matColumnDef="priority">
          <mat-cell *matCellDef="let element" data-label="Priority">
            <mat-chip-list>
              <mat-chip> {{'Priority' | translate}} {{ element.priority }} </mat-chip>
            </mat-chip-list>
          </mat-cell>
        </ng-container>

        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="navigateSummary(row)"
        ></mat-row>
      </mat-table>
    </div>

  </div>
</div>
