import { AlGoogleDriveService } from '@al/google-drive';
import { AlSpinnerService } from '@al/spinner';
import {
  FailureReportSyncService,
  QuickReportSyncService,
  ServiceRequestSyncService,
  StartCompletionSyncService,
  SubmitAndCloseSyncService,
  SubmitAndCompleteSyncService,
  SyncInfoService,
  TaskSyncService,
  WorkOrderSyncService,
} from '@al/sync-services';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AlSyncHelperService implements OnDestroy {
  public valid: Observable<boolean>;

  private countIterationsSync = 0;

  private logoutTimer = new BehaviorSubject<boolean>(false);

  private ngUnsubscribe = new Subject();

  private ngUnsubscribeFromSync = new Subject();

  private requestsList: any[] = [];

  private requestsLogoutList: any[] = [];

  private time!: number;

  private timeOut!: NodeJS.Timeout;

  private valid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private validBoolean = true;

  public constructor(
    private alSpinnerService: AlSpinnerService,
    private failureReportSyncService: FailureReportSyncService,
    private quickReportSyncService: QuickReportSyncService,
    private serviceRequestSyncService: ServiceRequestSyncService,
    private startCompletionSyncService: StartCompletionSyncService,
    private submitAndCloseSyncService: SubmitAndCloseSyncService,
    private submitAndCompleteSyncService: SubmitAndCompleteSyncService,
    private syncInfoService: SyncInfoService,
    private taskSyncService: TaskSyncService,
    private workOrderSyncService: WorkOrderSyncService,
    private alGoogleDriveService: AlGoogleDriveService
  ) {
    this.resetChrono();

    this.valid = this.valid$.asObservable();
  }

  public getSynchroElementCount(): number {
    return this.syncInfoService.getPendingCountBehaviourSubject().getValue();
  }

  public getTime(): number {
    return this.time || 0;
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public pushBeforeLogout(): Observable<boolean> {
    this.logoutTimer.next(false);
    const submitAndCompleteCreateSub = this.submitAndCompleteSyncService;
    const submitAndCloseCreateSub = this.submitAndCloseSyncService;
    const startCompletionCreateSub = this.startCompletionSyncService;

    const taskSub = this.taskSyncService;
    const workOrderUpdateSub = this.workOrderSyncService;

    const failureReportSub = this.failureReportSyncService;
    const quickReportSub = this.quickReportSyncService;
    const serviceRequestSub = this.serviceRequestSyncService;
    const workOrderCreateSub = this.workOrderSyncService;

    const submitAndCompleteUpdateSub = this.submitAndCompleteSyncService;
    const submitAndCloseUpdateSub = this.submitAndCloseSyncService;
    const startCompletionUpdateSub = this.startCompletionSyncService;

    this.countIterationsSync = 0;
    this.requestsLogoutList.push({ value: quickReportSub, type: 'CREATE' });
    this.requestsLogoutList.push({
      value: submitAndCompleteCreateSub,
      type: 'CREATE',
    });
    this.requestsLogoutList.push({
      value: submitAndCloseCreateSub,
      type: 'CREATE',
    });
    this.requestsLogoutList.push({ value: serviceRequestSub, type: 'CREATE' });
    this.requestsLogoutList.push({ value: workOrderCreateSub, type: 'CREATE' });

    this.requestsLogoutList.push({
      value: startCompletionUpdateSub,
      type: 'UPLOAD',
    });
    this.requestsLogoutList.push({ value: taskSub, type: 'UPDATE' });
    this.requestsLogoutList.push({ value: failureReportSub, type: 'CREATE' });
    this.requestsLogoutList.push({ value: workOrderUpdateSub, type: 'UPDATE' });
    this.requestsLogoutList.push({
      value: startCompletionCreateSub,
      type: 'UPDATE',
    });
    this.requestsLogoutList.push({
      value: submitAndCompleteUpdateSub,
      type: 'UPDATE',
    });
    this.requestsLogoutList.push({
      value: submitAndCloseUpdateSub,
      type: 'UPDATE',
    });
    this.sendLogoutRequests(this.requestsLogoutList[this.countIterationsSync]);

    return this.logoutTimer.asObservable();
  }

  /**
   * Launch sync
   * @param woGetSub : Observable from woGet
   */
  public sync(woGetSub: Observable<void>) {
    const taskSub = this.taskSyncService;
    const workOrderUpdateSub = this.workOrderSyncService;

    const failureReportSub = this.failureReportSyncService;
    const quickReportSub = this.quickReportSyncService;
    const serviceRequestSub = this.serviceRequestSyncService;
    const workOrderCreateSub = this.workOrderSyncService;

    const submitAndCompleteUpdateSub = this.submitAndCompleteSyncService;
    const submitAndCloseUpdateSub = this.submitAndCloseSyncService;
    const startCompletionUpdateSub = this.startCompletionSyncService;

    this.countIterationsSync = 0;
    this.validBoolean = true;
    this.requestsList.push({ value: quickReportSub, type: 'CREATE' });
    this.requestsList.push({ value: serviceRequestSub, type: 'CREATE' });
    this.requestsList.push({ value: workOrderCreateSub, type: 'CREATE' });

    // type UPLOAD : type update se trouvant immédiatement après
    // les create QR, SR, WO
    this.requestsList.push({
      value: startCompletionUpdateSub,
      type: 'UPLOAD',
    });
    this.requestsList.push({ value: taskSub, type: 'UPDATE' });
    this.requestsList.push({ value: failureReportSub, type: 'CREATE' });
    this.requestsList.push({ value: workOrderUpdateSub, type: 'UPDATE' });
    this.requestsList.push({
      value: submitAndCompleteUpdateSub,
      type: 'UPDATE',
    });
    this.requestsList.push({
      value: submitAndCloseUpdateSub,
      type: 'UPDATE',
    });
    this.sendSyncRequests(
      this.requestsList[this.countIterationsSync],
      woGetSub
    );
  }

  private handlerLogError(error: any): void {
    // TODO: Utiliser un logger global
    // eslint-disable-next-line no-console
    console.error(error);
  }

  private prepareSyncRequests(request: any) {
    if (request.type === 'CREATE') {
      return request.value.pushCreateCache();
    }
    if (request.type === 'UPLOAD') {
      return request;
    }
    return request.value.pushUpdateCache();
  }

  private resetChrono(): void {
    this.stopChrono();
    this.time = 0;
    this.startChrono();
  }

  private sendLogoutRequests(request: any) {
    if (request.type && request.type === 'UPLOAD') {
      this.alGoogleDriveService
        .upload()
        .pipe(takeUntil(this.ngUnsubscribeFromSync))
        .subscribe((res) => {
          if (res === true) {
            request.type = 'UPDATE';
            this.sendLogoutRequests(request);
          }
        });
    } else {
      this.prepareSyncRequests(request)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (result: boolean) => {
            if (
              this.countIterationsSync + 1 < this.requestsLogoutList.length &&
              result === true
            ) {
              this.countIterationsSync += 1;
              this.sendLogoutRequests(
                this.requestsLogoutList[this.countIterationsSync]
              );
            } else if (
              this.countIterationsSync + 1 === this.requestsLogoutList.length &&
              result === true
            ) {
              this.logoutTimer.next(true);
            }
          },
          error: (err: any) => {
            if (this.countIterationsSync + 1 < this.requestsLogoutList.length) {
              this.countIterationsSync += 1;
              this.sendLogoutRequests(
                this.requestsLogoutList[this.countIterationsSync]
              );
            } else if (
              this.countIterationsSync + 1 ===
              this.requestsLogoutList.length
            ) {
              this.logoutTimer.next(true);
            }
            this.handlerLogError(err);
          },
          complete: () => {
            // TODO: Utiliser un logger global
            // eslint-disable-next-line no-console
            console.info('Synchro Complete');
          },
        });
    }
  }

  private sendSyncRequests(request: any, woGetSub: Observable<void>) {
    if (request.type && request.type === 'UPLOAD') {
      this.alGoogleDriveService
        .upload()
        .pipe(takeUntil(this.ngUnsubscribeFromSync))
        .subscribe((res) => {
          if (res === true) {
            request.type = 'UPDATE';
            this.sendSyncRequests(request, woGetSub);
          }
        });
    } else {
      this.prepareSyncRequests(request)
        .pipe(takeUntil(this.ngUnsubscribeFromSync))
        .subscribe({
          next: (res: boolean) => {
            this.syncInfoService.initStoreFromCache();
            this.validBoolean = this.validBoolean && true;
            this.valid$.next(this.validBoolean);
            this.resetChrono();
            if (
              this.countIterationsSync + 1 < this.requestsList.length &&
              res === true
            ) {
              this.countIterationsSync += 1;
              this.sendSyncRequests(
                this.requestsList[this.countIterationsSync],
                woGetSub
              );
            } else if (
              this.countIterationsSync + 1 === this.requestsList.length &&
              res === true
            ) {
              this.requestsList = [];
              this.countIterationsSync = 0;
              this.sendSyncRequestsWoGet(woGetSub);
              this.ngUnsubscribeFromSync.next();
              this.ngUnsubscribeFromSync.complete();
            }
          },
          error: () => {
            this.syncInfoService.initStoreFromCache();
            this.validBoolean = false;
            this.valid$.next(this.validBoolean);
            this.resetChrono();
            if (this.countIterationsSync < this.requestsList.length) {
              this.countIterationsSync += 1;
              this.sendSyncRequests(
                this.requestsList[this.countIterationsSync],
                woGetSub
              );
            } else if (
              this.countIterationsSync + 1 ===
              this.requestsList.length
            ) {
              this.requestsList = [];
              this.countIterationsSync = 0;
              this.sendSyncRequestsWoGet(woGetSub);
              this.ngUnsubscribeFromSync.next();
              this.ngUnsubscribeFromSync.complete();
            }
          },
          complete: () => {
            this.syncInfoService.initStoreFromCache();
            this.valid$.next(this.validBoolean);
            this.resetChrono();
          },
        });
    }
  }

  private sendSyncRequestsWoGet(request: Observable<void>) {
    request.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: () => {
        this.syncInfoService.initStoreFromCache();
        this.validBoolean = this.validBoolean && true;
        this.valid$.next(this.validBoolean);
        this.resetChrono();
        this.alSpinnerService.stopDisplay(15);
      },
      error: () => {
        this.syncInfoService.initStoreFromCache();
        this.validBoolean = false;
        this.valid$.next(this.validBoolean);
        this.resetChrono();
        this.alSpinnerService.stopDisplay(15);
      },
      complete: () => {
        this.syncInfoService.initStoreFromCache();
        this.valid$.next(this.validBoolean);
        this.resetChrono();
      },
    });
  }

  private startChrono(): void {
    this.timeOut = setInterval(() => {
      this.time += 1;
    }, 1000 * 60);
  }

  private stopChrono(): void {
    if (this.timeOut) {
      clearInterval(this.timeOut);
    }
  }
}
