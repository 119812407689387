<div class="al-site-selector">
  <ng-container *ngIf="isSelectable; else elseTemplate">
    <div class="is-selectable">
      <ng-container *ngIf="enabled; else elseTemplate">
        <div class="dropdown" [ngClass]="{ 'dropdown' : isOnline && isSynchronized,
          'dropdown-disable' : !(isOnline && isSynchronized) }">
          <mat-icon>place</mat-icon>
          <mat-form-field appearance="fill">
            <mat-select [disabled]="!(isOnline && isSynchronized)"
              [(value)]="selectedItemUuid" (valueChange)="onValueChange($event)"
              [ngClass]="{ isOnline : 'disable' }">
              <mat-option *ngFor="let item of items" [value]="item.uuid">
                {{item.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>
      <ng-template #elseTemplate>
        <mat-card class="mat-card">
          <div class="content">
            <mat-icon>place</mat-icon>
            <span class="site-name">{{selectedItemName}}</span>
          </div>
        </mat-card>
      </ng-template>
    </div>
  </ng-container>
  <ng-template #elseTemplate>
    <div class="is-not-selectable">
      <mat-icon>place</mat-icon>
      <span>{{selectedItemName}}</span>
    </div>
  </ng-template>
</div>
