import { AttachmentItem } from '@al/entities';
import { AlEnvironmentService } from '@al/environment';
import { AlIndexedDbService } from '@al/indexed-db';
import { ServiceRequestService, WorkOrdersService } from '@al/state';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { AppContext } from './infrastructure/app.context';
import { FileInfo } from './models/file-info';

@Injectable({
  providedIn: 'root',
})
export class AlGoogleDriveService {
  public errorMessage: Observable<any>;

  private currentFile: AttachmentItem | null;

  private currentFileInfo: FileInfo | null;

  private currentIndex: number;

  private errorMessage$: BehaviorSubject<any>;

  private files: AttachmentItem[];

  private googleDriveFolderMap!: Map<string, string>;

  private isUploadFinished = new BehaviorSubject(false);

  public constructor(
    private alEnvironmentService: AlEnvironmentService,
    private alIndexedDbService: AlIndexedDbService,
    private appContext: AppContext,
    private serviceRequestService: ServiceRequestService,
    private workOrdersService: WorkOrdersService
  ) {
    this.currentFile = null;
    this.currentFileInfo = null;
    this.currentIndex = -1;
    this.files = [];

    this.errorMessage$ = new BehaviorSubject<any>(null);
    this.errorMessage = this.errorMessage$.asObservable();
  }

  public upload(): Observable<boolean> {
    this.isUploadFinished.next(false);
    this.gapiLoadAndSignIn().then(() => {
      this.list();
      this.uploadFile();
    });
    return this.isUploadFinished.asObservable();
  }

  public uploadFile(): void {
    this.alIndexedDbService.getAll().subscribe(async (items) => {
      this.currentIndex = -1;
      this.files = items.filter(
        (item) => !item.isUploaded && (item.ticketId || item.workOrderNumber)
      );
      if (this.files.length > 0) {
        this.uploadNextFile();
      } else {
        this.isUploadFinished.next(true);
      }
    });
  }

  private gapiLoadAndSignIn(): Promise<void> {
    const config = {
      access_type: 'offline',
      scope: 'https://www.googleapis.com/auth/drive',
      client_id: this.alEnvironmentService.env.googleClientId,
      discoveryDocs: [
        'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
      ],
    };
    return new Promise<void>((resolve) => {
      if (gapi.client) {
        gapi.auth2
          .getAuthInstance()
          .currentUser.get()
          .reloadAuthResponse()
          .then(() => {
            resolve();
          });
      } else {
        gapi.load('client', () => {
          gapi.auth2.init(config);
          if (!!gapi.auth2 && gapi.auth2.getAuthInstance().isSignedIn.get()) {
            resolve();
          } else {
            gapi.auth2
              .getAuthInstance()
              .grantOfflineAccess({ prompt: 'consent' })
              .then(() => {
                resolve();
              })
              .catch((error) => {
                // TODO: Utiliser un logger global
                // eslint-disable-next-line no-console
                console.error(error);
                resolve();
              });
          }
        });
      }
    });
  }

  private getFileInfo(item: AttachmentItem): FileInfo | null {
    const fileInfo = new FileInfo();
    fileInfo.Name = item.newFileName;
    if (item.file) {
      fileInfo.Blob = item.file;
    }
    return fileInfo;
  }

  private list(): void {
    const { googleDrive } = this.alEnvironmentService.env;
    const driveSharedId = googleDrive;
    const folderRootId = googleDrive;

    gapi.client.load('drive', 'v3', () => {
      gapi.client.drive.files
        .list({
          corpora: 'drive',
          includeItemsFromAllDrives: true,
          supportsAllDrives: true,
          driveId: driveSharedId,
          pageSize: 100,
          fields:
            'nextPageToken, files(id, name, mimeType, modifiedTime, size)',
          q: `'${folderRootId}' in parents and trashed = false`,
        })
        .then((res) => {
          // Liste des répertoires du Shared Drive de eMaintenance
          if (this.alEnvironmentService.env.googleDriveFolderAutomaticMapping) {
            const { result } = res;
            this.googleDriveFolderMap = new Map();
            result.files?.forEach((file) => {
              if (
                file.name &&
                file.id &&
                file.mimeType === 'application/vnd.google-apps.folder'
              ) {
                this.googleDriveFolderMap.set(file.name, file.id);
              }
            });
          }
        });
    });
  }

  private nextFile(): AttachmentItem | null {
    this.currentIndex += 1;
    if (this.currentIndex <= this.files.length - 1) {
      return this.files[this.currentIndex];
    }
    return null;
  }

  /**
   *
   * @param res {
   *    "kind": "drive#file",
   *    "id": "1AdK9Ly4na3bd3xybW5u9Iy9zssumrJP9",
   *    "name": "test11:24.pdf",
   *    "mimeType": "application/pdf",
   *    "teamDriveId": "0AN8IT5ZnaWzzUk9PVA",
   *    "driveId": "0AN8IT5ZnaWzzUk9PVA"
   * }
   */
  private onImportComplete(res: any): void {
    if (this.currentFile) {
      const resJson = JSON.parse(res);
      if (this.currentFile.workOrderNumber) {
        this.workOrdersService
          .addAttachment(this.currentFile, resJson.id, resJson.name)
          .subscribe((response: boolean) => {
            if (response && this.currentFile) {
              this.alIndexedDbService.setUploaded(this.currentFile);
              this.uploadNextFile();
              this.appContext.Session.File.uploadFinished.emit();
            }
          });
      } else if (this.currentFile.ticketId) {
        this.serviceRequestService
          .addAttachment(this.currentFile, resJson.id, resJson.name)
          .subscribe((response: boolean) => {
            if (response && this.currentFile) {
              this.alIndexedDbService.setUploaded(this.currentFile);
              this.uploadNextFile();
              this.appContext.Session.File.uploadFinished.emit();
            }
          });
      }
    }

    if (this.currentFileInfo) {
      this.currentFileInfo.Progress = 100;
    }
  }

  private onImportError(res: any): void {
    this.errorMessage$.next(res);
    this.isUploadFinished.next(true);
    this.appContext.Session.File.uploadFinished.emit();
  }

  private onImportProgress(event: any): void {
    if (this.currentFileInfo) {
      this.currentFileInfo.Progress = (event.loaded / event.total) * 100;
    }
  }

  private uploadCurrentFile(): void {
    if (this.currentFile && this.currentFileInfo) {
      this.currentFileInfo.Progress = 10;
      let siteUuid: string | undefined;
      if (this.alEnvironmentService.env.googleDriveFolderAutomaticMapping) {
        siteUuid = this.googleDriveFolderMap.get(this.currentFile.site);
      } else {
        siteUuid =
          this.alEnvironmentService.env.googleDriveFolderMap[
            this.currentFile.site
          ];
      }
      if (siteUuid) {
        this.appContext.Repository.File.importFile(
          siteUuid,
          this.currentFileInfo,
          new Blob([this.currentFile.arrayBuffer]),
          (res: any) => this.onImportError(res),
          (res: any) => this.onImportComplete(res),
          (res: any) => this.onImportProgress(res)
        );
      }
    }
  }

  private uploadNextFile(): void {
    this.currentFile = this.nextFile();
    this.currentFileInfo = null;
    if (this.currentFile) {
      this.currentFileInfo = this.getFileInfo(this.currentFile);
      this.uploadCurrentFile();
    } else {
      this.isUploadFinished.next(true);
      this.alIndexedDbService.deleteUploaded();
    }
  }
}
