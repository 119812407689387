<div class="al-work-order-description" *ngIf="getWorkOrderAssetDescription.length > 0">
  <div class="content" [ngClass]="{'line-clamp': !seeMore}">
    <p>{{getWorkOrderAssetDescription}}</p>
  </div>
  <ng-container *ngIf="seeMore; else elseTemplate">
    <span translate *ngIf="seeMore" class="link" (click)="seeMoreSwitch()">Hide</span>
  </ng-container>
  <ng-template #elseTemplate>
    <span translate *ngIf="!seeMore" class="link" (click)="seeMoreSwitch()">See more</span>
  </ng-template>
</div>
