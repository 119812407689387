import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AlEnvironmentService } from '@al/environment';

import { Credentials } from '../credentials';
import { SessionStore } from './session.store';
import { User } from './user';

@Injectable({ providedIn: 'root' })
export class SessionService {
  public constructor(
    private alEnvironmentService: AlEnvironmentService,
    private sessionStore: SessionStore,
    private http: HttpClient
  ) {}

  public login(credentials: Credentials): Observable<User> {
    const endpoint = `${this.alEnvironmentService.env.apiUrl}users`;
    return this.http.post<User>(endpoint, credentials).pipe(
      tap((user) => {
        this.sessionStore.update({
          name: `${user.firstName} ${user.lastName}`,
          token: user.googleToken,
        });
      })
    );
  }

  public logout(): void {
    this.sessionStore.reset();
  }

  public setImageUrl(imageUrl: string): void {
    this.sessionStore.update({ imageUrl });
  }

  public setName(firstName: string, lastName: string): void {
    const name = `${firstName} ${lastName}`;
    this.sessionStore.update({ name });
  }

  public setToken(googleToken: string): void {
    this.sessionStore.update({ token: googleToken });
  }
}
