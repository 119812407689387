import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import jwt_decode from 'jwt-decode';
import {
  LocalStorageConfiguration,
  RightActionEnum,
  RightStatusEnum,
  RightTypeEnum,
  UserRights,
} from '@al/entities';
import { UserRightsCreateCache } from './caches/user-rights-create.cache';
import { IdToken } from './al-session-cas/id-token';
import { AccessToken } from './al-session-cas/access-token';
import { SessionService } from './state/session.service';

@Injectable({
  providedIn: 'root',
})
export class AlSessionService {
  public constructor(private sessionService: SessionService) {}

  public getAccessToken(): Observable<string> {
    return from(Auth.currentSession()).pipe(
      switchMap((session) =>
        from(session ? session.getAccessToken().getJwtToken() : '')
      )
    );
  }

  public getIdToken(): Observable<string> {
    return from(Auth.currentSession()).pipe(
      switchMap((session) =>
        from(session ? session.getIdToken().getJwtToken() : '')
      )
    );
  }

  public initSessionStore(idTokenDecoded: IdToken): void {
    this.sessionService.setImageUrl(idTokenDecoded.picture);
    this.sessionService.setName(
      idTokenDecoded.firstname,
      idTokenDecoded.lastname
    );
    this.sessionService.setToken(idTokenDecoded['custom:kiteAccessToken']);
  }

  public setAccessTokenToLocalStorage(session: CognitoUserSession): void {
    if (session) {
      const accessTokenDecoded = jwt_decode<AccessToken>(
        session.getAccessToken().getJwtToken()
      );
      localStorage.setItem(
        LocalStorageConfiguration.ACCESS_TOKEN,
        session.getAccessToken().getJwtToken()
      );
      localStorage.setItem(
        LocalStorageConfiguration.ACCESS_TOKEN_DECODED,
        JSON.stringify(accessTokenDecoded)
      );
    }
  }

  public setDefaultSiteToLocalStorage(session: CognitoUserSession): void {
    if (session) {
      const idTokenDecoded = jwt_decode<IdToken>(
        session.getIdToken().getJwtToken()
      );
      if (!localStorage.getItem(LocalStorageConfiguration.SITE_KEY)) {
        localStorage.setItem(
          LocalStorageConfiguration.SITE_KEY,
          idTokenDecoded.defaultSite
        );
      }
    }
  }

  public setIdTokenToLocalStorage(session: CognitoUserSession): IdToken {
    const idTokenDecoded = jwt_decode<IdToken>(
      session.getIdToken().getJwtToken()
    );
    localStorage.setItem(
      LocalStorageConfiguration.ID_TOKEN,
      session.getIdToken().getJwtToken()
    );
    localStorage.setItem(
      LocalStorageConfiguration.ID_TOKEN_DECODED,
      JSON.stringify(idTokenDecoded)
    );
    return idTokenDecoded;
  }

  public setLanguage(session: CognitoUserSession): void {
    if (session) {
      const idTokenDecoded = jwt_decode<IdToken>(
        session.getIdToken().getJwtToken()
      );
      const LANGUAGES_LIST = [
        'nl',
        'en',
        'fr',
        'id',
        'it',
        'ja',
        'ko',
        'pt',
        'es',
        'th',
        'vi',
      ];
      const currentLanguage = idTokenDecoded.language.toLowerCase();
      if (LANGUAGES_LIST.includes(currentLanguage)) {
        localStorage.setItem(
          LocalStorageConfiguration.LANGUAGE_KEY,
          `${currentLanguage}`
        );
      } else {
        localStorage.setItem(LocalStorageConfiguration.LANGUAGE_KEY, `en`);
      }
    }
  }

  public setUserIdToLocalStorage(session: CognitoUserSession): void {
    if (session) {
      const idTokenDecoded = jwt_decode<IdToken>(
        session.getIdToken().getJwtToken()
      );
      localStorage.setItem(
        LocalStorageConfiguration.USER_ID,
        `${idTokenDecoded.id}`
      );
    }
  }

  public setUserRightsToCache(session: CognitoUserSession): void {
    if (session) {
      const userRightsCreateCache = new UserRightsCreateCache();
      const idTokenDecoded = jwt_decode<IdToken>(
        session.getIdToken().getJwtToken()
      );
      const rightsJson = JSON.parse(idTokenDecoded.rights);
      if (rightsJson.WOTRACK) {
        const toPush: UserRights = {};
        toPush.type = RightTypeEnum.WOTRACK;
        toPush.actions = rightsJson.WOTRACK.actions.map(
          (x: RightActionEnum) => RightActionEnum[x]
        );
        toPush.status = rightsJson.WOTRACK.status.map(
          (x: RightStatusEnum) => RightStatusEnum[x]
        );
        userRightsCreateCache.put(toPush);
      }
      if (rightsJson.QUICKREP) {
        const toPush: UserRights = {};
        toPush.type = RightTypeEnum.QUICKREP;
        toPush.actions = rightsJson.QUICKREP.actions.map(
          (x: RightActionEnum) => RightActionEnum[x]
        );
        toPush.status = rightsJson.QUICKREP.status.map(
          (x: RightStatusEnum) => RightStatusEnum[x]
        );
        userRightsCreateCache.put(toPush);
      }
      if (rightsJson.SR) {
        const toPush: UserRights = {};
        toPush.type = RightTypeEnum.SR;
        toPush.actions = rightsJson.SR.actions.map(
          (x: RightActionEnum) => RightActionEnum[x]
        );
        toPush.status = rightsJson.SR.status.map(
          (x: RightStatusEnum) => RightStatusEnum[x]
        );
        userRightsCreateCache.put(toPush);
      }
    }
  }
}
