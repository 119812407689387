/** *****************************************************************
 #*  Copyright Air Liquide S.A.
 #*  2020
 #***************************************************************** */

import { AlCardSliderModule } from '@al/card-slider';
import { AlDateSelectorModule } from '@al/date-selector';
import { AlSiteSelectorModule } from '@al/site-selector';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { AlSiteSummaryComponent } from './al-site-summary.component';

@NgModule({
  declarations: [AlSiteSummaryComponent],
  imports: [
    AlCardSliderModule,
    AlDateSelectorModule,
    AlSiteSelectorModule,
    CommonModule,
    TranslateModule,
  ],
  exports: [AlSiteSummaryComponent],
})
export class AlSiteSummaryModule {}
