import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public isServiceWorkerReady: Observable<boolean>;

  private isServiceWorkerReady$: BehaviorSubject<boolean>;

  public constructor() {
    this.isServiceWorkerReady$ = new BehaviorSubject<boolean>(false);
    this.isServiceWorkerReady = this.isServiceWorkerReady$.asObservable();
  }

  public setServiceWorkerState(ready: boolean) {
    this.isServiceWorkerReady$.next(ready);
  }
}
