import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';

import { AlOnlineService } from '@al/online';
import { AlSyncHelperService } from '@al/sync-helper';
import { filter, takeUntil } from 'rxjs/operators';
import { AlSpinnerService } from '@al/spinner';
import { AlSyncService } from '@al/sync';
import { SyncInfoService } from '@al/sync-services';

@Component({
  selector: 'al-maximo-synchronization',
  templateUrl: './al-maximo-synchronization.component.html',
  styleUrls: ['./al-maximo-synchronization.component.scss'],
})
export class AlMaximoSynchronizationComponent implements OnInit, OnDestroy {
  public displayProgressSpinner = false;

  public isSynchroValid!: boolean;

  public syncPendingCount!: number;

  private currentUrl: string;

  private isOnline!: boolean;

  private ngUnsubscribe = new Subject();

  public get isDisabled(): boolean {
    const regexp = /^\/(synchrosummary)/;
    return !this.isOnline || regexp.test(this.currentUrl);
  }

  public get itemsLength(): number {
    return this.synchroHelperService.getSynchroElementCount();
  }

  public get minutes(): number {
    return this.itemsLength ? 0 : this.synchroHelperService.getTime();
  }

  public constructor(
    private alSyncService: AlSyncService,
    private onLineService: AlOnlineService,
    private router: Router,
    private synchroHelperService: AlSyncHelperService,
    private synchroInfoService: SyncInfoService,
    private alSpinnerService: AlSpinnerService
  ) {
    this.currentUrl = this.router.url;
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((event: any) => {
        this.currentUrl = event.urlAfterRedirects;
      });
  }

  public gotToSynchroSummary(): void {
    this.router.navigate(['/', 'synchrosummary']);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    this.onLineService.status
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: boolean) => {
        this.isOnline = res;
        if (res === true && this.minutes >= 60) {
          this.alSpinnerService.startDisplay(15);
          this.alSyncService.sync();
        }
      });

    this.synchroInfoService
      .getErrorsCount()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((count: number) => {
        this.isSynchroValid = count === 0;
      });

    this.synchroInfoService
      .getPending()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((count: any[]) => {
        this.syncPendingCount = count.length;
        if (count.length === 0) {
          this.alSpinnerService.stopDisplay(7);
        }
      });
  }

  public refresh(): void {
    this.alSpinnerService.startDisplay(15);
    this.alSyncService.sync();
    if (this.syncPendingCount > 0) {
      this.alSpinnerService.startDisplay(7);
    }
  }
}
