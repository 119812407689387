import { AlCommonComponent } from '@al/common';
import { ServiceRequestPut, ServiceRequestPutResponse } from '@al/entities';
import { AlIndexedDbService } from '@al/indexed-db';
import {
  AlReferenceData,
  AlReferenceService,
  ClassStructureReference,
} from '@al/reference';
import {
  AlRequestInformationDialogComponent,
  AlRequestInformationDialogData,
  AlRequestInformationDialogDataSrPut,
} from '@al/request-information-dialog';
import { AlSpinnerService } from '@al/spinner';
import {
  EquipmentsService,
  ProcessGroupsService,
  ProductionUnitsService,
  ServiceRequestService,
  SiteQuery,
} from '@al/state';
import { SyncInfoService, SyncInfoStatus } from '@al/sync-services';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-create-sr',
  templateUrl: './al-create-sr.component.html',
  styleUrls: ['./al-create-sr.component.scss'],
})
export class AlCreateSrComponent
  extends AlCommonComponent
  implements OnInit, OnDestroy
{
  @ViewChild(FormGroupDirective)
  public formGroupDirective: FormGroupDirective | undefined;

  public filteredOptions!: Observable<AlReferenceData[] | undefined>;

  public putRequest: ServiceRequestPut | null;

  protected reloaded: ServiceRequestPut | null;

  public constructor(
    public alReferenceService: AlReferenceService,
    public dialog: MatDialog,
    protected alIndexedDbService: AlIndexedDbService,
    protected router: Router,
    private activatedRoute: ActivatedRoute,
    private alSpinnerService: AlSpinnerService,
    private readonly formBuilder: FormBuilder,
    private serviceRequestService: ServiceRequestService,
    private synchroInfoService: SyncInfoService,
    fb: FormBuilder,
    processGroupService: ProcessGroupsService,
    productionUnitsService: ProductionUnitsService,
    siteQuery: SiteQuery,
    equipmentsService: EquipmentsService
  ) {
    super(
      dialog,
      alIndexedDbService,
      router,
      fb,
      processGroupService,
      productionUnitsService,
      equipmentsService,
      siteQuery
    );
    this.putRequest = this.getNewPutRequest();
    this.reloaded = null;
    this.references = alReferenceService.getReferenceData([
      'domain',
      'workOrderJobType',
      'taskPriority',
      'workOrderSource',
      'workType',
      'classStructure',
    ]);
    this.form = this.buildFormGroup();
  }

  public displayFn(classStruct: AlReferenceData): string {
    if (classStruct && classStruct.label) {
      return classStruct.label;
    }
    return '';
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    const synchroInfoUUid = this.activatedRoute.snapshot.params.synchroInfoUuid;
    this.reloaded =
      this.synchroInfoService.getCreateServiceRequestPut(synchroInfoUUid);

    this.synchroInfoService
      .getErrorByUuid(synchroInfoUUid)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((info) => {
        if (info) {
          this.synchroInfo = info;
        }
      });

    this.synchroInfoService
      .getPendingByUuid(synchroInfoUUid)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((info) => {
        if (info) {
          this.synchroInfo = info;
        }
      });

    this.initFormGroup();

    this.filteredOptions = this.form.controls.hierarchyPath.valueChanges.pipe(
      startWith(''),
      map((val) => this.filter(val))
    );
  }

  public submitServiceRequest(): void {
    if (this.form.valid && this.putRequest) {
      const formValues = this.form.value;
      this.putRequest.siteId = this.getSiteId();
      this.putRequest.priority = formValues.srPriority;
      this.putRequest.reportedDate = new Date(formValues.reportedDate);
      this.putRequest.affectedTo = formValues.affectedTo;
      if (formValues.hierarchyPath) {
        this.putRequest.hierarchyPath =
          formValues.hierarchyPath.CLASSSTRUCTUREID;
      }
      this.putRequest.location = this.getLocation();
      this.putRequest.assetNum = this.getAsset();
      this.putRequest.summary = formValues.summary;
      this.putRequest.description = formValues.details;

      if (this.reloaded && this.reloaded.uuid) {
        this.putRequest.uuid = this.reloaded.uuid;
      }

      this.alSpinnerService.startDisplay(4);

      this.serviceRequestService
        .create(this.putRequest)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: ServiceRequestPutResponse) => {
          if (
            this.synchroInfo &&
            (res.status === SyncInfoStatus.OK ||
              res.status === SyncInfoStatus.PENDING)
          ) {
            this.synchroInfoService.deleteFromHistory(this.synchroInfo);
            this.synchroInfo = undefined;
            this.reloaded = null;
          }
          if (this.putRequest && this.putRequest.uuid && res && res.ticketId) {
            this.alIndexedDbService.updateTicketId(
              this.putRequest.uuid,
              res.ticketId
            );
          }
          this.displayCreation(res);
        });
    }
  }

  protected buildDialogInformation(
    res: ServiceRequestPutResponse
  ): AlRequestInformationDialogData<AlRequestInformationDialogDataSrPut> {
    const { type, error, siteId, status, ticketId } = res;

    return {
      title: 'Create Service Request',
      type: 'srPut',
      information: {
        action: type,
        error: error ?? undefined,
        siteId,
        status,
        ticketId: ticketId ?? undefined,
      },
    };
  }

  protected buildFormGroup(): FormGroup {
    return this.formBuilder.group({
      srPriority: this.formBuilder.control('', Validators.required),
      reportedDate: this.formBuilder.control('', Validators.required),
      affectedTo: this.formBuilder.control(''),
      hierarchyPath: this.formBuilder.control(''),
      location: this.formBuilder.control('', Validators.required),
      asset: this.formBuilder.control(''),
      uuid: this.formBuilder.control(''),
      summary: this.formBuilder.control('', [
        Validators.maxLength(this.DESCRIPTION_MAX_LENGTH),
        Validators.required,
      ]),
      details: this.formBuilder.control(
        '',
        Validators.maxLength(this.LONG_DESCRIPTION_MAX_LENGTH)
      ),
    });
  }

  protected displayCreation(data: ServiceRequestPutResponse): void {
    this.alSpinnerService.stopDisplay(4);
    AlRequestInformationDialogComponent.open<
      ServiceRequestPutResponse,
      AlRequestInformationDialogDataSrPut
    >(data, this.dialog, this.buildDialogInformation.bind(this))
      .afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((status) => {
        if (status === 'OK' || status === 'PENDING') {
          this.resetForm();
        }
      });
  }

  protected getNewPutRequest(): ServiceRequestPut {
    return new ServiceRequestPut();
  }

  protected initFormGroup(): void {
    const today = new Date();
    const timeZoneDiff = today.getTimezoneOffset();

    today.setMinutes(today.getMinutes() - timeZoneDiff);

    this.initLocation();

    this.form
      .get('srPriority')
      ?.setValue(this.reloaded ? this.reloaded.priority : '');

    this.form
      .get('reportedDate')
      ?.setValue(
        this.reloaded && this.reloaded.reportedDate
          ? new Date(this.reloaded.reportedDate).toISOString().substring(0, 16)
          : today.toISOString().substring(0, 16)
      );

    this.form
      .get('affectedTo')
      ?.setValue(this.reloaded ? this.reloaded.affectedTo : '');

    this.form
      .get('asset')
      ?.setValue(this.reloaded ? this.reloaded.assetNum : '');

    this.form.get('uuid')?.setValue(this.reloaded ? this.reloaded.uuid : '');

    this.form
      .get('summary')
      ?.setValue(this.reloaded ? this.reloaded.summary : '');

    this.form
      .get('details')
      ?.setValue(this.reloaded ? this.reloaded.description : '');

    if (this.reloaded) {
      const v = this.reloaded.hierarchyPath;
      const t = ClassStructureReference.filter((struct) => {
        return struct.CLASSSTRUCTUREID === v;
      })[0];
      this.form.get('hierarchyPath')?.setValue(t);
    }
  }

  private filter(value: string): AlReferenceData[] {
    if (this.references.classStructure) {
      if (!value || value === '') {
        return this.references.classStructure.concat();
      }
      return this.references.classStructure?.filter((s) =>
        new RegExp(value, 'gi').test(s.label + s.CLASSSTRUCTUREID)
      );
    }
    return [];
  }
}
