import {
  AfterViewInit,
  Component,
  NgZone,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlSpinnerService } from './al-spinner.service';

@Component({
  selector: 'al-spinner',
  templateUrl: './al-spinner.component.html',
  styleUrls: ['./al-spinner.component.scss'],
})
export class AlSpinnerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('overlayTemplate')
  public overlayTemplate!: ComponentPortal<any>;

  public disconnectMessage = false;

  public displayProgressSpinner: boolean | undefined;

  public overlayRef!: OverlayRef;

  private broadcast = new BroadcastChannel('snackbar-channel');

  private isInit = false;

  private ngUnsubscribe = new Subject();

  public constructor(
    private overlay: Overlay,
    private alSpinnerService: AlSpinnerService,
    private snackBar: MatSnackBar,
    private zone: NgZone
  ) {
    this.alSpinnerService
      .isSnackBarDisplayed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res.text) {
          this.launchSnackbar(res.text, res.duration);
        }
      });

    this.alSpinnerService
      .isProgressSpinnerDisplayed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (this.isInit) {
          if (res && !this.overlayRef.hasAttached()) {
            this.overlayRef.attach(this.overlayTemplate);
          } else if (!res && this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
          }
        }
      });

    this.alSpinnerService.disconnectMessage
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res === true) {
          this.disconnectMessage = true;
          // this.openDialog();
        } else {
          this.disconnectMessage = false;
        }
      });

    this.broadcast.onmessage = (event) => {
      this.launchSnackbar(event.data.text, event.data.duration);
    };
  }

  public createOverlay() {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      positionStrategy,
    });

    overlayConfig.hasBackdrop = true;

    this.overlayRef = this.overlay.create(overlayConfig);
  }

  public ngAfterViewInit(): void {
    this.createOverlay();
    this.isInit = true;
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private launchSnackbar(text: string, duration: number | null): void {
    if (duration) {
      this.zone.run(() => {
        this.snackBar.open(text, 'close', {
          duration: duration * 1000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
    } else {
      this.zone.run(() => {
        this.snackBar.open(text, 'close', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
    }
  }
}
