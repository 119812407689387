import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AlSessionModule } from '@al/session';

import { MatIconModule } from '@angular/material/icon';

import { LoginComponent } from './login.component';

@NgModule({
  declarations: [LoginComponent],
  imports: [AlSessionModule, CommonModule, MatIconModule],
})
export class LoginModule {}
