<span class="account" [matMenuTriggerFor]="mainMenu" [matTooltip]="fullName">
  <button mat-icon-button [ngStyle]="buttonStyle">
    <mat-icon *ngIf="!hasImage">person</mat-icon>
  </button>
</span>

<mat-menu #mainMenu="matMenu">
  <div class="panel">
    <button mat-menu-item [matMenuTriggerFor]="languageMenu" translate>
      Language
    </button>
    <button [disabled]="!isOnline" mat-menu-item (click)="logout()" translate>Logout</button>
  </div>
</mat-menu>

<mat-menu #languageMenu="matMenu">
  <div class="panel">
    <a mat-menu-item (click)="setLanguage('nl')" translate>Dutch</a>
    <a mat-menu-item (click)="setLanguage('en')" translate>English</a>
    <a mat-menu-item (click)="setLanguage('fr')" translate>French</a>
    <a mat-menu-item (click)="setLanguage('id')" translate>Indonesian</a>
    <a mat-menu-item (click)="setLanguage('it')" translate>Italian</a>
    <a mat-menu-item (click)="setLanguage('ja')" translate>Japanese</a>
    <a mat-menu-item (click)="setLanguage('ko')" translate>Korean</a>
    <a mat-menu-item (click)="setLanguage('pt')" translate>Portuguese</a>
    <a mat-menu-item (click)="setLanguage('es')" translate>Spanish</a>
    <a mat-menu-item (click)="setLanguage('th')" translate>Thai</a>
    <a mat-menu-item (click)="setLanguage('vi')" translate>Vietnamese</a>
  </div>
</mat-menu>
