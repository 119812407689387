import { AlReferenceData } from './model/al-reference-data.model';

export const WorkTypeReference: ReadonlyArray<AlReferenceData> = [
  { type: 'M11', label: 'Minor Preventive' },
  { type: 'M12', label: 'Legal & Predictive Controls' },
  { type: 'M13', label: 'Minor repairs' },
  { type: 'M14', label: 'Minor Improvements' },
  { type: 'M21', label: 'Systematic preventive' },
  { type: 'M22', label: 'Conditional preventive' },
  { type: 'M31', label: 'Breakdown Maintenance' },
  { type: 'M32', label: 'Corrective Maintenance' },
  { type: 'M35', label: 'Network incident H2gda (>2500e)' },
  { type: 'M41', label: 'Energy Saving' },
  { type: 'M42', label: 'Reliability / Obsolescence' },
  { type: 'M43', label: 'Condition Plant enhancement' },
  { type: 'M44', label: 'Quality' },
  { type: 'M45', label: 'Safety' },
  { type: 'M46', label: 'Environment' },
  { type: 'M47', label: 'refurbishing' },
  { type: 'M48', label: 'SCADA' },
  { type: 'M49', label: 'Dismantling or Scrapping' },
  { type: 'M71', label: 'Training' },
  { type: 'M72', label: 'Housekeeping' },
  { type: 'M73', label: 'Meeting' },
  { type: 'M74', label: 'Inventory / Kitting' },
  { type: 'M75', label: 'Support for Estimates (Commercial)' },
  { type: 'M76', label: 'Support to Planning' },
  { type: 'M77', label: 'Prototype/trials' },
  { type: 'M78', label: 'PM counter adjustments or JOB PLAN creation' },
  { type: 'M79', label: 'National Asset Displacement' },
  { type: 'M81', label: 'New Installation' },
  { type: 'M91', label: 'Servigas - Maintenance' },
  { type: 'M92', label: 'Servigas - General' },
  { type: 'M93', label: 'Gas Services' },
  { type: 'MT', label: '' },
];
