import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface SessionState {
  imageUrl: string | null;
  name: string;
  token: string | null;
}

export const createInitialState = (): SessionState => ({
  name: '',
  imageUrl: null,
  token: null,
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'session', resettable: true })
export class SessionStore extends Store<SessionState> {
  public constructor() {
    super(createInitialState());
  }
}
