import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { CalibrationStatus, Task, TaskStatus, WorkOrder } from '@al/entities';
import { WorkOrdersQuery } from '@al/state';

import { AlWorkOrderTaskListService } from './al-work-order-task-list.service';

@Component({
  selector: 'al-work-order-task',
  templateUrl: './al-work-order-task.component.html',
  styleUrls: ['./al-work-order-task.component.scss'],
})
export class AlWorkOrderTaskComponent implements OnInit, OnDestroy {
  @Input()
  public index!: number;

  @Input()
  public task!: Task;

  @Input()
  public workOrder!: WorkOrder;

  public canSkipOrDone: boolean;

  public isCalibration: boolean;

  public isCurrentTask: boolean;

  private ngUnsubscribe = new Subject();

  public get displaySecondCalibration(): boolean {
    return !!(
      this.task &&
      this.task.status1 !== null &&
      this.task.status1 !== CalibrationStatus.CHECKED &&
      (this.task.measurementAfterAdj ||
        this.task.measurementAfterAdj === 0 ||
        this.isCurrentTask)
    );
  }

  public constructor(
    private alWorkOrderTaskListService: AlWorkOrderTaskListService,
    private workOrdersQuery: WorkOrdersQuery
  ) {
    this.canSkipOrDone = false;
    this.isCalibration = false;
    this.isCurrentTask = false;
  }

  public get canEdit(): boolean {
    const value = !!(
      this.task &&
      this.task.endDate &&
      (this.task.status === TaskStatus.COMP ||
        this.task.status === TaskStatus.CAN) &&
      !this.isCurrentTask
    );
    return value;
  }

  public clickOnEdit(): void {
    this.alWorkOrderTaskListService.setCurrentTask(this.task);
  }

  public done(): void {
    this.alWorkOrderTaskListService.setDone(this.task);
    this.alWorkOrderTaskListService.nextTask();
  }

  public getIconStatus(): boolean {
    let status = false;
    if (this.task.status) {
      if (this.task.status === TaskStatus.COMP) {
        status = true;
      }
    }
    return !this.task.isTaskSkipped && status;
  }

  public get getStatus(): string {
    return this.task && this.task.status1 ? this.task.status1 : '';
  }

  public get getTaskType(): string {
    return this.task && this.task.taskType ? this.task.taskType : '';
  }

  public get isCalibrationTwoEditable(): boolean {
    return (
      !this.task.status2 && this.task.status1 === CalibrationStatus.CHECKED
    );
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    this.isCalibration =
      this.task.standardInput !== undefined && this.task.standardInput !== null;

    this.alWorkOrderTaskListService.currentTask
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((currentTask) => {
        this.isCurrentTask =
          currentTask && this.task ? currentTask.id === this.task.id : false;
      });
  }

  public skip(): void {
    this.alWorkOrderTaskListService.setSkipped(this.task);
    this.alWorkOrderTaskListService.nextTask();
  }
}
