import { Injectable, OnDestroy } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  QuickReportPut,
  WorkOrderPut,
  ServiceRequestPut,
  PutResponse,
  WorkOrder,
} from '@al/entities';

import {
  FailureReportCreateCache,
  FailureReportCreateHistoryService,
  HistoryElement,
  QuickReportCreateCache,
  QuickReportCreateHistoryService,
  ServiceRequestCreateCache,
  ServiceRequestCreateHistoryService,
  StartCompletionCreateCache,
  StartCompletionUpdateCache,
  StartCompletionUpdateHistoryService,
  SubmitAndCloseCreateCache,
  SubmitAndCloseUpdateCache,
  SubmitAndCompleteCreateCache,
  SubmitAndCompleteUpdateCache,
  TaskUpdateCache,
  TaskUpdateHistoryService,
  WorkOrderCreateCache,
  WorkOrderCreateHistoryService,
  WorkOrderUpdateCache,
  WorkOrderUpdateHistoryService,
} from '@al/cache';
import { SyncErrorInfoStore } from './state/sync-error-info.store';
import { SyncErrorInfoQuery } from './state/sync-error-info.query';
import { SyncInfo } from './state/sync-info.model';
import { SyncInfoStatus } from './enums/sync-info-status.enum';
import { SyncInfoType } from './enums/sync-info-type.enum';
import { SyncPendingInfoStore } from './state/sync-pending-info.store';
import { SyncPendingInfoQuery } from './state/sync-pending-info.query';
import { GtmSyncService } from './gtm-sync.service';

@Injectable({ providedIn: 'root' })
export class SyncInfoService implements OnDestroy {
  public isSynchronized: Observable<boolean>;

  private getWorkOrder: any;

  private isStoreEmpty: boolean;

  private isSynchronized$: BehaviorSubject<boolean>;

  private ngUnsubscribe = new Subject();

  private pendingCount: Observable<number>;

  private pendingCount$: BehaviorSubject<number>;

  public constructor(
    private failureReportCreateCache: FailureReportCreateCache,
    private failureReportCreateHistoryService: FailureReportCreateHistoryService,
    private quickReportCreateCache: QuickReportCreateCache,
    private quickReportCreateHistoryService: QuickReportCreateHistoryService,
    private serviceRequestCreateCache: ServiceRequestCreateCache,
    private serviceRequestCreateHistoryService: ServiceRequestCreateHistoryService,
    private startCompletionCreateCache: StartCompletionCreateCache,
    private startCompletionUpdateCache: StartCompletionUpdateCache,
    private startCompletionUpdateHistoryService: StartCompletionUpdateHistoryService,
    private submitAndCloseCreateCache: SubmitAndCloseCreateCache,
    private submitAndCloseUpdateCache: SubmitAndCloseUpdateCache,
    private submitAndCompleteCreateCache: SubmitAndCompleteCreateCache,
    private submitAndCompleteUpdateCache: SubmitAndCompleteUpdateCache,
    private syncErrorInfoQuery: SyncErrorInfoQuery,
    private syncErrorInfoStore: SyncErrorInfoStore,
    private syncPendingInfoQuery: SyncPendingInfoQuery,
    private syncPendingInfoStore: SyncPendingInfoStore,
    private taskUpdateCache: TaskUpdateCache,
    private taskUpdateHistoryService: TaskUpdateHistoryService,
    private workOrderCreateCache: WorkOrderCreateCache,
    private workOrderCreateHistoryService: WorkOrderCreateHistoryService,
    private workOrderUpdateCache: WorkOrderUpdateCache,
    private workOrderUpdateHistoryService: WorkOrderUpdateHistoryService,
    private gtmSyncService: GtmSyncService
  ) {
    this.pendingCount$ = new BehaviorSubject<number>(0);
    this.pendingCount = this.pendingCount$.asObservable();

    this.isSynchronized$ = new BehaviorSubject<boolean>(true);
    this.isSynchronized = this.isSynchronized$.asObservable();
    this.isStoreEmpty = true;

    this.getPendingCount().subscribe((count) => {
      this.pendingCount$.next(count);
    });
  }

  public deleteFromHistory(element: SyncInfo): void {
    this.removeFromHistory(element);
    this.syncErrorInfoStore.remove(element.uuid);
    this.gtmSyncService.deleteGtmTagByUuid(element.uuid);
    this.initStoreFromCache();
  }

  public deleteFromPending(element: SyncInfo): void {
    this.removeFromCache(element);
    this.gtmSyncService.deleteGtmTagByUuid(element.uuid);
    this.syncPendingInfoStore.remove(element.uuid);
    this.initStoreFromCache();
  }

  public getCreateQuickReportPut(uuid: UUID): QuickReportPut | null {
    if (this.quickReportCreateCache.get(uuid)) {
      return this.quickReportCreateCache.get(uuid);
    }
    if (this.quickReportCreateHistoryService.get(uuid)) {
      return this.quickReportCreateHistoryService.get(uuid)?.request || null;
    }
    return null;
  }

  public getCreateServiceRequestPut(uuid: UUID): ServiceRequestPut | null {
    if (this.serviceRequestCreateCache.get(uuid)) {
      return this.serviceRequestCreateCache.get(uuid);
    }
    if (this.serviceRequestCreateHistoryService.get(uuid)) {
      return this.serviceRequestCreateHistoryService.get(uuid)?.request || null;
    }
    return null;
  }

  public getCreateWorkOrderPut(uuid: UUID): WorkOrderPut | null {
    if (this.workOrderCreateCache.get(uuid)) {
      return this.workOrderCreateCache.get(uuid);
    }
    if (this.workOrderCreateHistoryService.get(uuid)) {
      return this.workOrderCreateHistoryService.get(uuid)?.request || null;
    }
    return null;
  }

  public getErrorByUuid(uuidInput: UUID): Observable<SyncInfo | undefined> {
    return this.syncErrorInfoQuery.selectEntity(
      (entity: SyncInfo) => entity.uuid === uuidInput
    );
  }

  public getErrors(): Observable<SyncInfo[]> {
    return this.syncErrorInfoQuery.selectAll();
  }

  public getErrorsCount(): Observable<number> {
    return this.syncErrorInfoQuery.selectCount(({ uuid }) => uuid != null);
  }

  public getPending(): Observable<SyncInfo[]> {
    return this.syncPendingInfoQuery.selectAll();
  }

  public getPendingByUuid(uuidInput: UUID): Observable<SyncInfo | undefined> {
    return this.syncPendingInfoQuery.selectEntity(
      (entity: SyncInfo) => entity.uuid === uuidInput
    );
  }

  public getPendingCount(): Observable<number> {
    return this.syncPendingInfoQuery.selectCount(({ uuid }) => uuid != null);
  }

  public getPendingCountBehaviourSubject(): BehaviorSubject<number> {
    return this.pendingCount$;
  }

  public getSynchonizedBehaviourSubject(): BehaviorSubject<boolean> {
    return this.isSynchronized$;
  }

  public initStoreFromCache(): void {
    this.syncPendingInfoStore.reset();
    this.syncErrorInfoStore.reset();
    this.isStoreEmpty = true;
    this.loadPending();
    this.loadErrors();
    this.isSynchronized$.next(this.isStoreEmpty);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public setGetWorkOrderHandler(handler: any): void {
    this.getWorkOrder = handler;
  }

  private getErrorDescription(response: HistoryElement<any, any>): string {
    let description = 'no way';
    if (
      response.response instanceof PutResponse &&
      response.response.error !== null
    ) {
      // @ts-ignore
      description = response.response.error;
    }

    // if statusText => error
    if (response.response.statusText) {
      if (response.response.status) {
        description = `HTTP error code ${response.response.status} : ${response.response.message}`;
      } else {
        description = response.response.statusText;
      }
    } else if (response.response.error) {
      description = response.response.error;
    } else {
      description = 'No message available';
    }

    return description;
  }

  private loadErrors(): void {
    const errorNumbers: Set<string> = new Set<string>();
    const errorSynchro: SyncInfo[] = [];
    this.workOrderCreateHistoryService.values().forEach((value) => {
      if (
        !value.response.status ||
        (value.response.status && value.response.status !== 'OK')
      ) {
        this.isStoreEmpty = false;

        this.syncErrorInfoStore.upsert(
          value.uuid,
          new SyncInfo(
            value.uuid,
            SyncInfoType.WO_CREATION,
            value.request.description ? `${value.request.description}` : '',
            SyncInfoStatus.KO,
            this.getErrorDescription(value),
            value.request.number ? `${value.request.number}` : ''
          )
        );
      }
    });

    this.workOrderUpdateHistoryService.values().forEach((value) => {
      if (
        !value.response.status ||
        (value.response.status && value.response.status !== 'OK')
      ) {
        errorSynchro.push(
          new SyncInfo(
            value.uuid,
            SyncInfoType.WO_UPDATE,
            value.request.description ? `${value.request.description}` : '',
            SyncInfoStatus.KO,
            this.getErrorDescription(value),
            value.request.number ? `${value.request.number}` : ''
          )
        );
        if (value.request.number) {
          errorNumbers.add(value.request.number);
        }
      }
    });

    this.serviceRequestCreateHistoryService.values().forEach((value) => {
      if (
        !value.response.status ||
        (value.response.status && value.response.status !== 'OK')
      ) {
        this.isStoreEmpty = false;

        this.syncErrorInfoStore.upsert(
          value.uuid,
          new SyncInfo(
            value.uuid,
            SyncInfoType.SR_CREATION,
            value.request.description ? `${value.request.description}` : '',
            SyncInfoStatus.KO,
            this.getErrorDescription(value),
            ''
          )
        );
      }
    });

    this.quickReportCreateHistoryService.values().forEach((value) => {
      if (
        !value.response.status ||
        (value.response.status && value.response.status !== 'OK')
      ) {
        this.isStoreEmpty = false;

        this.syncErrorInfoStore.upsert(
          value.uuid,
          new SyncInfo(
            value.uuid,
            SyncInfoType.QR_CREATION,
            value.request.description ? `${value.request.description}` : '',
            SyncInfoStatus.KO,
            this.getErrorDescription(value),
            ''
          )
        );
      }
    });

    this.failureReportCreateHistoryService.values().forEach((value) => {
      if (
        !value.response.status ||
        (value.response.status && value.response.status !== 'OK')
      ) {
        errorSynchro.push(
          new SyncInfo(
            value.uuid,
            SyncInfoType.FR_UPDATE,
            value.request.remark ? `${value.request.remark}` : '',
            SyncInfoStatus.KO,
            this.getErrorDescription(value),
            value.request.number ? `${value.request.number}` : ''
          )
        );
        if (value.request.number) {
          errorNumbers.add(value.request.number);
        }
      }
    });

    this.taskUpdateHistoryService.values().forEach((value) => {
      if (
        !value.response.status ||
        (value.response.status && value.response.status !== 'OK')
      ) {
        errorSynchro.push(
          new SyncInfo(
            value.uuid,
            SyncInfoType.TASK_UPDATE,
            'NO VALUE FOR THE MOMENT',
            // value.request.? `${value.request.remark}` : '',
            SyncInfoStatus.KO,
            this.getErrorDescription(value),
            value.request.number ? `${value.request.number}` : ''
          )
        );
        if (value.request.number) {
          errorNumbers.add(value.request.number);
        }
      }
    });
    this.mergeError(errorSynchro, errorNumbers);
  }

  private loadPending() {
    const pendingId: Set<string> = new Set<string>();
    const pendingSynchro: SyncInfo[] = [];
    this.workOrderCreateCache.reload();
    this.workOrderCreateCache.values().forEach((value) => {
      this.isStoreEmpty = false;

      this.syncPendingInfoStore.upsert(
        value.uuid,
        new SyncInfo(
          value.uuid,
          SyncInfoType.WO_CREATION,
          value.description ? `${value.description}` : '',
          SyncInfoStatus.PENDING,
          '',
          value.number ? `${value.number}` : ''
        )
      );
    });

    this.workOrderUpdateCache.reload();
    this.workOrderUpdateCache.values().forEach((value) => {
      pendingSynchro.push(
        new SyncInfo(
          value.uuid,
          SyncInfoType.WO_UPDATE,
          value.description ? value.description : '',
          SyncInfoStatus.PENDING,
          '',
          value.number ? `${value.number}` : ''
        )
      );
      if (value.number) {
        pendingId.add(value.number);
      }
    });

    this.quickReportCreateCache.reload();
    this.quickReportCreateCache.values().forEach((value) => {
      this.isStoreEmpty = false;

      this.syncPendingInfoStore.upsert(
        value.uuid,
        new SyncInfo(
          value.uuid,
          SyncInfoType.QR_CREATION,
          value.description ? value.description : '',
          SyncInfoStatus.PENDING,
          '',
          ''
        )
      );
    });

    this.serviceRequestCreateCache.reload();
    this.serviceRequestCreateCache.values().forEach((value) => {
      this.isStoreEmpty = false;

      this.syncPendingInfoStore.upsert(
        value.uuid,
        new SyncInfo(
          value.uuid,
          SyncInfoType.SR_CREATION,
          value.description ? value.description : '',
          SyncInfoStatus.PENDING,
          '',
          ''
        )
      );
    });

    this.failureReportCreateCache.reload();
    this.failureReportCreateCache.values().forEach((value) => {
      pendingSynchro.push(
        new SyncInfo(
          value.uuid,
          SyncInfoType.FR_UPDATE,
          value.remark ? value.remark : '',
          SyncInfoStatus.PENDING,
          '',
          value.number ? `${value.number}` : ''
        )
      );

      if (value.number) {
        pendingId.add(value.number);
      }
    });

    this.taskUpdateCache.reload();
    this.taskUpdateCache.values().forEach((value) => {
      pendingSynchro.push(
        new SyncInfo(
          value.uuid,
          SyncInfoType.TASK_UPDATE,
          'NO VALUE FOR THE MOMENT',
          SyncInfoStatus.PENDING,
          '',
          value.number ? `${value.number}` : ''
        )
      );
      if (value.number) {
        pendingId.add(value.number);
      }
    });

    this.submitAndCloseCreateCache.reload();
    this.submitAndCloseCreateCache.values().forEach((value) => {
      pendingSynchro.push(
        new SyncInfo(
          value.uuid,
          SyncInfoType.WO_UPDATE,
          'NO VALUE FOR THE MOMENT',
          SyncInfoStatus.PENDING,
          '',
          value.number ? `${value.number}` : ''
        )
      );
      if (value.number) {
        pendingId.add(value.number);
      }
    });

    this.submitAndCloseUpdateCache.reload();
    this.submitAndCloseUpdateCache.values().forEach((value) => {
      pendingSynchro.push(
        new SyncInfo(
          value.uuid,
          SyncInfoType.WO_UPDATE,
          'NO VALUE FOR THE MOMENT',
          SyncInfoStatus.PENDING,
          '',
          value.number ? `${value.number}` : ''
        )
      );
      if (value.number) {
        pendingId.add(value.number);
      }
    });

    this.submitAndCompleteCreateCache.reload();
    this.submitAndCompleteCreateCache.values().forEach((value) => {
      pendingSynchro.push(
        new SyncInfo(
          value.uuid,
          SyncInfoType.WO_UPDATE,
          'NO VALUE FOR THE MOMENT',
          SyncInfoStatus.PENDING,
          '',
          value.number ? `${value.number}` : ''
        )
      );
      if (value.number) {
        pendingId.add(value.number);
      }
    });

    this.submitAndCompleteUpdateCache.reload();
    this.submitAndCompleteUpdateCache.values().forEach((value) => {
      pendingSynchro.push(
        new SyncInfo(
          value.uuid,
          SyncInfoType.WO_UPDATE,
          'NO VALUE FOR THE MOMENT',
          SyncInfoStatus.PENDING,
          '',
          value.number ? `${value.number}` : ''
        )
      );
      if (value.number) {
        pendingId.add(value.number);
      }
    });

    this.startCompletionCreateCache.reload();
    this.startCompletionCreateCache.values().forEach((value: WorkOrderPut) => {
      pendingSynchro.push(
        new SyncInfo(
          value.uuid,
          SyncInfoType.WO_UPDATE,
          'NO VALUE FOR THE MOMENT',
          SyncInfoStatus.PENDING,
          '',
          value.number ? `${value.number}` : ''
        )
      );
      if (value.number) {
        pendingId.add(value.number);
      }
    });

    this.startCompletionUpdateCache.reload();
    this.startCompletionUpdateCache.values().forEach((value) => {
      pendingSynchro.push(
        new SyncInfo(
          value.uuid,
          SyncInfoType.WO_UPDATE,
          'NO VALUE FOR THE MOMENT',
          SyncInfoStatus.PENDING,
          '',
          value.number ? `${value.number}` : ''
        )
      );
      if (value.number) {
        pendingId.add(value.number);
      }
    });
    this.mergePending(pendingSynchro, pendingId);
  }

  /**
   * Merge error to create only one SynchroInfo in the store if many errors
   * @param errorSynchro : SynchroInfo[] to merge
   * @param errorNumbers : The id of the workorders
   * @private
   */
  private mergeError(
    errorSynchro: SyncInfo[],
    errorNumbers: Set<string>
  ): void {
    errorNumbers.forEach((num) => {
      const subscribe: Observable<WorkOrder> = this.getWorkOrder(num);

      const sub = subscribe
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((workorder) => {
          let message = '';
          errorSynchro
            .filter((error) => error.number === num)
            .forEach((info) => {
              message += `${info.message} `;
            });

          if (workorder) {
            // const uuid = UUID.UUID();
            this.isStoreEmpty = false;

            this.syncErrorInfoStore.upsert(
              workorder.id,
              new SyncInfo(
                num,
                SyncInfoType.WO_UPDATE,
                workorder.description,
                SyncInfoStatus.KO,
                message,
                num
              )
            );
          }
        });
      sub.unsubscribe();
    });
  }

  /**
   * Merge error to create only one SynchroInfo in the store if many errors
   * @param pendingSynchro : SynchroInfo[] to merge
   * @param pendingNumber : The id of the workorders
   * @private
   */
  private mergePending(
    pendingSynchro: SyncInfo[],
    pendingNumber: Set<string>
  ): void {
    pendingNumber.forEach((num) => {
      const subscribe: Observable<WorkOrder> = this.getWorkOrder(num);

      const sub = subscribe
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((workorder) => {
          if (workorder) {
            this.isStoreEmpty = false;

            this.syncPendingInfoStore.upsert(
              workorder.id,
              new SyncInfo(
                num,
                SyncInfoType.WO_UPDATE,
                workorder.description,
                SyncInfoStatus.PENDING,
                '',
                num
              )
            );
          }
        });
      sub.unsubscribe();
    });
  }

  private removeFromCache(element: SyncInfo): void {
    switch (element.type) {
      case SyncInfoType.FR_CREATION:
        this.failureReportCreateCache.removeByUuid(element.uuid);
        break;
      case SyncInfoType.QR_CREATION:
        this.quickReportCreateCache.removeByUuid(element.uuid);
        break;
      case SyncInfoType.SR_CREATION:
        this.serviceRequestCreateCache.removeByUuid(element.uuid);
        break;
      case SyncInfoType.WO_CREATION:
        this.workOrderCreateCache.removeByUuid(element.uuid);
        break;
      case SyncInfoType.WO_UPDATE:
        this.workOrderUpdateCache.removeByUuid(element.uuid);
        break;
      default:
        break;
    }
  }

  private removeFromHistory(element: SyncInfo): void {
    switch (element.type) {
      case SyncInfoType.FR_CREATION:
        this.failureReportCreateHistoryService.clearWorkOrderFromHistory(
          element.number
        );
        break;
      case SyncInfoType.QR_CREATION:
        this.quickReportCreateHistoryService.removeByUuid(element.uuid);
        break;
      case SyncInfoType.SR_CREATION:
        this.serviceRequestCreateHistoryService.removeByUuid(element.uuid);
        break;
      case SyncInfoType.WO_CREATION:
        this.workOrderCreateHistoryService.removeByUuid(element.uuid);
        break;
      case SyncInfoType.WO_UPDATE:
        this.workOrderUpdateHistoryService.clearWorkOrderFromHistory(
          element.number
        );
        // in this case we have to clean all update history service to delete corresponding info
        this.failureReportCreateHistoryService.clearWorkOrderFromHistory(
          element.number
        );
        this.startCompletionUpdateHistoryService.clearWorkOrderFromHistory(
          element.number
        );
        this.taskUpdateHistoryService.clearWorkOrderFromHistory(element.number);
        break;
      default:
        break;
    }
  }
}
