import { CommonModule, registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeId from '@angular/common/locales/id';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localeNl from '@angular/common/locales/nl';
import localePt from '@angular/common/locales/pt';
import localeTh from '@angular/common/locales/th';
import localeVi from '@angular/common/locales/vi';
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AlAccountModule } from '@al/account';
import { AlEnvironmentModule } from '@al/environment';
import { AlMaximoSynchronizationModule } from '@al/maximo-synchronization';
import { AlSyncModule } from '@al/sync';
import { AlSessionModule, SecurityHttpInterceptor } from '@al/session';
import { AlSiteSelectorModule } from '@al/site-selector';

/* Add Amplify imports */
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import Amplify from 'aws-amplify';
import { AlSpinnerModule } from '@al/spinner';

import { GoogleTagManagerModule } from 'angular-google-tag-manager';

import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { dbConfig } from '@al/indexed-db';
import awsConfig from '../environments/aws-exports';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { CreateBTModule } from './create-bt/create-bt.module';
import { CreateSRModule } from './create-sr/create-sr.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { LoginModule } from './login/login.module';
import { QuickReportModule } from './quick-report/quick-report.module';
import { SynchroSummaryModule } from './synchro-summary/synchro-summary.module';
import { WorkOrderModule } from './work-order/work-order.module';

/* Configure Amplify resources */
Amplify.configure(awsConfig);

// the second parameter 'fr' is optional
registerLocaleData(localeEs, 'es');
registerLocaleData(localeIt, 'it');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localePt, 'pt');

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeJa, 'ja');
registerLocaleData(localeId, 'id');
registerLocaleData(localeKo, 'ko');
registerLocaleData(localeTh, 'th');
registerLocaleData(localeVi, 'vi');

// AoT requires an exported function for factories
// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http);
// }

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    environment.production ? [] : AkitaNgDevtools.forRoot({ shallow: true }),
    AlEnvironmentModule.forRoot(environment),
    NgxIndexedDBModule.forRoot(dbConfig),

    AppRoutingModule,

    AmplifyUIAngularModule,

    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CommonModule,
    GoogleTagManagerModule.forRoot({
      id: environment.gtmContainerId,
    }),
    HttpClientModule,
    RouterModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),

    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSnackBarModule,

    AlAccountModule,
    AlMaximoSynchronizationModule,
    AlSessionModule,
    AlSiteSelectorModule,
    AlSyncModule,

    AlSpinnerModule,
    CreateBTModule,
    CreateSRModule,
    DashboardModule,
    LoginModule,
    QuickReportModule,
    SynchroSummaryModule,
    WorkOrderModule,
  ],
  providers: [
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SecurityHttpInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
/*
* providers: [
{
provide: HTTP_INTERCEPTORS,
useClass: MyHttpInterceptor,
multi: true
}
],
* */
export class AppModule {}
