<div class="al-table-column-header-filtering" *ngIf="fieldType == 'input'">
  <label class="title">{{ title }}</label>
  <form [formGroup]="form" (submit)="submit()" (change)="submit()">
    <div class="filter-wrapper">
      <mat-form-field class="form-field" style="width: 50%">
        <input matInput formControlName="value" type="text" />
      </mat-form-field>
      <button style="width: 10%" mat-button *ngIf="form.get('value')" matSuffix mat-icon-button aria-label="Clear"
        type="button" (click)="clearValue()">
        <mat-icon>close</mat-icon>
      </button>
      <button style="width: 10%" mat-button matSuffix mat-icon-button aria-label="Search" type="submit">
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </form>
</div>

<div class="al-table-column-header-filtering" *ngIf="fieldType == 'datePicker'">
  <label class="title">{{ title }}</label>
  <form [formGroup]="form" (submit)="submit()">
    <div class="filter-wrapper">
      <mat-form-field class="form-field" style="width: 50%">
        <input matInput formControlName="value" [(ngModel)]="objectiveEndDate" type="date" (change)="submit()">
      </mat-form-field>
      <button mat-button *ngIf="form.get('value')" matSuffix mat-icon-button aria-label="Clear" type="button"
        (click)="clearValue()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </form>
</div>


<div class="al-table-column-header-filtering" *ngIf="fieldType == 'dropDown'">
  <label class="title">{{ title }}</label>
  <form [formGroup]="form" (submit)="submit()" (change)="submit()">
    <div class="filter-wrapper">
      <mat-form-field class="form-field" style="width: 50%">
        <mat-select *ngIf="field == 'type'" [(value)]="selectedWorktype" (selectionChange)="submit()">
          <mat-option *ngFor="let worktype of tempWorktypes" [value]="worktype.type">
            {{worktype.type}} : {{worktype.label}}
          </mat-option>
        </mat-select>
        <mat-select *ngIf="field == 'priority'" [(value)]="selectedPriority" (selectionChange)="submit()">
          <mat-option *ngFor="let tempPriority of tempPrioritys" [value]="tempPriority.priority">
            {{tempPriority.priority}} : {{tempPriority.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-button *ngIf="form.get('value')" matSuffix mat-icon-button aria-label="Clear" type="button"
        (click)="clearValue()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </form>
</div>
