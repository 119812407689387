<!--
/*******************************************************************
#*  Copyright Air Liquide S.A.
#*  2020
#******************************************************************/-->
<div class="al-summary-badge-container">
  <div class="wrapper">
    <al-card-slider>
      <al-site-selector [isSelectable]="true" [enabled]="true" class="site-selector"></al-site-selector>
      <al-date-selector class="date-selector"></al-date-selector>
      <al-card-slider-item [class.disabled]="disabled" #cardElement *ngFor="let card of cards"
        [isClickable]="card.isClickable" (click)="onCardClick(card, cardElement)" [color]="card.color"
        [icon]="card.icon" [iconType]="iconType" [count]="card.count" [card]="card">
        {{ card.message | translate }}
      </al-card-slider-item>
    </al-card-slider>
  </div>
</div>
