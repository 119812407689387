import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Workbox } from 'workbox-window';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const sendServiceWorkerState = (message: string) => {
  window.postMessage(message, window.location.href);
};

const loadServiceWorker = (): void => {
  if ('serviceWorker' in navigator) {
    const wb = new Workbox('/service-worker.js');

    wb.addEventListener('activated', (event) => {
      if (!event.isUpdate) {
        // TODO: Utiliser un logger global
        // eslint-disable-next-line no-console
        console.info('Service worker activated for the first time!');
      } else {
        // TODO: Utiliser un logger global
        // eslint-disable-next-line no-console
        console.info('Service worker activated!');
      }
      // TODO: postMessage
      // appService.setServiceWorkerState(true);
      sendServiceWorkerState('activated');
    });

    wb.addEventListener('waiting', () => {
      // TODO: Utiliser un logger global
      // eslint-disable-next-line no-console
      console.info(
        `A new service worker has installed, but it can't activate` +
          `until all tabs running the current version have fully unloaded.`
      );
    });

    wb.addEventListener('installed', (event) => {
      if (!event.isUpdate) {
        // TODO: Utiliser un logger global
        // eslint-disable-next-line no-console
        console.info('Service worker installed for the first time');
      } else {
        // TODO: Utiliser un logger global
        // eslint-disable-next-line no-console
        console.info('Service worker installed');
        // eslint-disable-next-line no-restricted-globals
        if (confirm('New content is available!. Click OK to refresh')) {
          window.location.reload();
        }
      }
    });

    wb.register();
  }
};

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(() => loadServiceWorker())
    .catch((err) => {
      // TODO: Utiliser un logger global
      // eslint-disable-next-line no-console
      console.error(err);
    });
});
