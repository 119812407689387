import { Component, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-quick-report',
  templateUrl: './quick-report.component.html',
  styleUrls: ['./quick-report.component.scss'],
})
export class QuickReportComponent {
  public constructor(private router: Router, public dialog: MatDialog) {}

  public openDialogWithRef(ref: TemplateRef<any>) {
    this.dialog.open(ref);
  }

  public returnHome(): void {
    this.router.navigate(['/']);
  }
}
