import { JsonObject, JsonProperty } from 'json2typescript';
import { DateJsonConverter } from '../../json-converters/date-json-converter';

import { PutRequest } from './put-request.model';
import { RelatedWoNumber } from './related-wo-number.model';

import { Attachment } from '../get/attachment.model';
import { Task } from '../get/task-model';
import { WorkOrderStatus } from '../../enums/work-order-status.enum';

/**
 * This class represents Work Order model
 */
@JsonObject('WorkOrderPut')
export class WorkOrderPut extends PutRequest {
  @JsonProperty('actualFinish', DateJsonConverter, true)
  public actualFinish: Date | null = null;

  @JsonProperty('actualStart', DateJsonConverter, true)
  public actualStart: Date | null = null;

  @JsonProperty('assetNum', String, true)
  public assetNum: string | null = null;

  @JsonProperty('attachments', [Attachment], true)
  public attachments: Attachment[] = [];

  @JsonProperty('description', String, true)
  public description: string | null = null;

  @JsonProperty('domain', String, true)
  public domain: string | null = null;

  @JsonProperty('id', Number, true)
  public id: number | null = null;

  @JsonProperty('jobType', String, true)
  public jobType: string | null = null;

  @JsonProperty('location', String, true)
  public location: string | null = null;

  @JsonProperty('number', String, true)
  public number: string | null = null;

  @JsonProperty('objectiveEnd', DateJsonConverter, true)
  public objectiveEnd: Date | null = null;

  @JsonProperty('priority', String, true)
  public priority: string | null = null;

  @JsonProperty('relatedTo', [RelatedWoNumber], true)
  public relatedTo: RelatedWoNumber[] = [];

  @JsonProperty('remark', String, true)
  public remark: string | null = null;

  @JsonProperty('remarkDetails', String, true)
  public remarkDetails: string | null = null;

  @JsonProperty('report', DateJsonConverter, true)
  public report: Date | null = null;

  @JsonProperty('siteId', String, true)
  public siteId: string | null = null;

  @JsonProperty('source', String, true)
  public source: string | null = null;

  // REMEMBER
  @JsonProperty('status', String, true)
  public status: WorkOrderStatus | null = null;

  @JsonProperty('tasks', [Task], true)
  public tasks: Task[] = [];

  @JsonProperty('type', String, true)
  public type: string | null = null;
}
