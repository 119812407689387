import { Component, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-bt',
  templateUrl: './create-bt.component.html',
  styleUrls: ['./create-bt.component.scss'],
})
export class CreateBTComponent {
  public constructor(private router: Router, public dialog: MatDialog) {}

  public openDialogWithRef(ref: TemplateRef<any>) {
    this.dialog.open(ref);
  }

  public returnHome(): void {
    this.router.navigate(['/']);
  }
}
