import { EnvironmentOptions } from '@al/environment';

export const environment: EnvironmentOptions = {
  production: false,
  development: false,
  title: 'e-Maintenance (alpha)',

  apiUrl: 'http://localhost:3100/',
  apigwUrl: 'https://ovvbbak4yc.execute-api.eu-west-1.amazonaws.com/alpha/',

  // # AMAZON COGNITO
  acgUserPoolDomain: 'apigee-maximo-dev.auth.eu-west-1.amazoncognito.com',
  acgUserPoolLogoutEndpoint: '/logout',
  acgUserPoolClientId: '142hnu2a8mrd1n1fh30a2hq9ve',
  acgUserPoolClientCallback: '/callback',

  // # GTM
  gtmContainerId: 'GTM-WRLM727',

  pingUrl: 'https://httpstat.us/200',

  canAddFile: false,
  canAddPhoto: false,

  googleClientId: '',
  googleDrive: '',
  googleDriveFolderAutomaticMapping: false,
  googleDriveFolderMap: {},
};
