<div class="container">
  <div mat-dialog-title>
    <div class="header">
      <h1 translate>create new</h1>
      <button mat-icon-button class="closeButton" (click)="closeDialog()">
        <mat-icon aria-label="close">close</mat-icon>
      </button>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="content">
      <div class="row" *ngFor="let creationTarget of creationTargets">
        <button [disabled]="!disableButton(creationTarget.labelKey)" class="button" mat-raised-button color="primary"
          (click)="navigateTo(creationTarget.target)">
          {{ creationTarget.labelKey | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
