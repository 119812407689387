/** *****************************************************************
 #*  Copyright Air Liquide S.A.
 #*  2020
 #***************************************************************** */
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';

import { IconTypeEnum, AlCardSliderItemComponent } from '@al/card-slider';
import { LocalStorageConfiguration } from '@al/entities';
import { WorkOrdersService } from '@al/state';

import { AlSiteSummaryService } from './al-site-summary.service';
import { KpiCard } from './kpi-card';

@Component({
  selector: 'al-site-summary',
  templateUrl: './al-site-summary.component.html',
  styleUrls: ['./al-site-summary.component.scss'],
})
export class AlSiteSummaryComponent implements AfterViewInit {
  @Output()
  public allCardDisabled: EventEmitter<null> = new EventEmitter<null>();

  @Output()
  public cardClick: EventEmitter<KpiCard> = new EventEmitter<KpiCard>();

  @ViewChildren(AlCardSliderItemComponent)
  public cardSliderItems?: QueryList<AlCardSliderItemComponent>;

  @Input()
  public disabled?: boolean;

  public cards: KpiCard[] = [];

  public iconType!: IconTypeEnum;

  public constructor(
    protected readonly siteSummaryService: AlSiteSummaryService,
    protected workOrderService: WorkOrdersService
  ) {
    this.iconType = IconTypeEnum.webFont;
    this.cards = this.siteSummaryService.getKpiCards();
  }

  public ngAfterViewInit(): void {
    this.initSelectedKpi();
  }

  public onCardClick(
    card: KpiCard,
    cardElement: AlCardSliderItemComponent
  ): void {
    // if disable or if card not clickable
    if (this.disabled || card.isClickable === false) {
      return;
    }

    let isActiveKPI = false;
    const cardsElement = this.cardSliderItems;
    cardsElement?.forEach(
      (cardSliderItemComponent: AlCardSliderItemComponent) => {
        if (
          cardSliderItemComponent !== cardElement ||
          cardSliderItemComponent.activated
        ) {
          if (cardSliderItemComponent.activated) {
            cardSliderItemComponent.disable();
            (cardSliderItemComponent.card as KpiCard).onUnselect(
              this.workOrderService
            );
          }
        } else {
          cardSliderItemComponent.enable();
          isActiveKPI = true;
        }
      }
    );

    if (isActiveKPI) {
      card.onSelect(this.workOrderService);
      this.cardClick.emit(card);
    } else {
      this.allCardDisabled.emit(null);
    }
  }

  private initSelectedKpi() {
    const currentFilter = localStorage.getItem(
      LocalStorageConfiguration.FILTER_STATUS
    );
    this.cardSliderItems?.forEach(
      (cardComponent: AlCardSliderItemComponent) => {
        const kpiCard = cardComponent.card as KpiCard;
        if (kpiCard.data === currentFilter) {
          cardComponent.enable();
          kpiCard.onSelect(this.workOrderService);
        }
      }
    );
  }
}
