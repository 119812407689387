import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  FailureReport,
  FailureReportPutResponse,
  FailureReportRequestPut,
  LocalStorageKey,
} from '@al/entities';
import { AlEnvironmentService, AwsEndPoint } from '@al/environment';

import { AbstractRestService } from './abstract-rest.service';

@Injectable({
  providedIn: 'root',
})
export class FailureReportRestService extends AbstractRestService<
  FailureReport,
  FailureReportRequestPut,
  FailureReportPutResponse
> {
  protected keyLastDate: LocalStorageKey;

  protected keyLastRowstamp: LocalStorageKey;

  protected get createEndPoint(): string {
    return `${this.alEnvironmentService.env.apigwUrl}${AwsEndPoint.saveFr}`;
  }

  protected get getEndPoint(): string {
    return `${AwsEndPoint.saveFr}`;
  }

  protected get updateEndPoint(): string {
    return `${this.alEnvironmentService.env.apigwUrl}${AwsEndPoint.saveFr}`;
  }

  public constructor(
    protected alEnvironmentService: AlEnvironmentService,
    protected http: HttpClient // private query: WorkOrdersQuery, // private store: WorkOrdersStore
  ) {
    super(http);
    this.keyLastDate = LocalStorageKey.FAILURE_REPORT_SYNCHRO_LAST_DATE;
    this.keyLastRowstamp = LocalStorageKey.FAILURE_REPORT_SYNCHRO_LAST_ROWSTAMP;
  }

  protected getClazz(): typeof FailureReport {
    return FailureReport;
  }

  protected getPutRequestClazz(): typeof FailureReportRequestPut {
    return FailureReportRequestPut;
  }

  protected getPutResponseClazz(): typeof FailureReportPutResponse {
    return FailureReportPutResponse;
  }
}
