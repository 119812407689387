import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import {
  RightActionEnum,
  RightStatusEnum,
  RightTypeEnum,
  Task,
  TaskStatus,
  WorkOrder,
} from '@al/entities';
import { SessionRights } from '@al/session';
import { WorkOrdersQuery } from '@al/state';

import { AlWorkOrderTaskListService } from './al-work-order-task-list.service';

@Component({
  selector: 'al-work-order-task-list',
  templateUrl: 'al-work-order-task-list.component.html',
  styleUrls: ['al-work-order-task-list.component.scss'],
})
export class AlWorkOrderTaskListComponent implements OnDestroy, OnInit {
  @Output()
  public completion = new EventEmitter<void>();

  @Input()
  public instantCompletion = false;

  public taskList: Task[] = [];

  public viewDetails: boolean;

  public workOrder!: WorkOrder;

  private hasReportedDate: boolean;

  private isCompletionStarted: boolean;

  private ngUnsubscribe = new Subject();

  private rights = new SessionRights();

  public get completedTaskCount(): number {
    return this.taskList.filter((task) => this.isCompleted(task)).length;
  }

  public get taskCount(): number {
    return this.taskList.length;
  }

  public constructor(
    private alWorkOrderTaskListService: AlWorkOrderTaskListService,
    private workOrdersQuery: WorkOrdersQuery
  ) {
    this.viewDetails = false;
    this.workOrdersQuery
      .selectActive()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: WorkOrder | undefined) => {
        if (res) {
          const previousWorkOrder = this.workOrder;
          this.workOrder = res;
          if (
            previousWorkOrder &&
            previousWorkOrder.number !== this.workOrder.number
          ) {
            this.viewDetails = false;
          }
        }
      });

    this.hasReportedDate = false;
    this.alWorkOrderTaskListService.hasReportedDate
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((hasReportedDate) => {
        this.hasReportedDate = hasReportedDate;
      });

    this.isCompletionStarted = false;
    this.alWorkOrderTaskListService.isCompletionStarted
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isCompletionStarted) => {
        this.isCompletionStarted = isCompletionStarted;
      });

    this.taskList = [];
    this.alWorkOrderTaskListService.taskList
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((taskList) => {
        this.taskList = taskList;
      });
  }

  public canStartCompletion(): boolean {
    return !this.hasReportedDate;
  }

  public isStartCompletionDisabled(): boolean {
    if (this.taskCount && this.taskCount > 0) {
      return !this.disableButton();
    }
    return !this.disableButtonInstantCompletion();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    this.viewDetails = false;
  }

  public startCompletion(): void {
    this.viewDetails = true;
    this.alWorkOrderTaskListService
      .startCompletion()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (
          this.instantCompletion &&
          this.taskCount === 0 &&
          (res?.status === 'OK' || res?.status === 'PENDING')
        ) {
          this.completion.emit();
        }
      });
  }

  public toggleViewDetails(): void {
    this.viewDetails = !this.viewDetails;
  }

  private disableButton(): boolean {
    return (
      this.rights.isAllowedAction(
        RightTypeEnum.WOTRACK,
        RightActionEnum.INSERT
      ) &&
      this.rights.isAllowedStatus(
        RightTypeEnum.WOTRACK,
        RightStatusEnum.COMP
      ) &&
      !this.isCompletionStarted
    );
  }

  private disableButtonInstantCompletion(): boolean {
    return (
      this.rights.isAllowedAction(
        RightTypeEnum.WOTRACK,
        RightActionEnum.INSERT
      ) &&
      this.rights.isAllowedStatus(
        RightTypeEnum.WOTRACK,
        RightStatusEnum.COMP
      ) &&
      !this.isCompletionStarted &&
      !!(this.instantCompletion && this.taskCount === 0)
    );
  }

  private isCompleted(task: Task): boolean {
    if (task.isTaskSkipped) {
      return true;
    }
    if (
      task.status === TaskStatus.CAN ||
      task.status === TaskStatus.CLOSE ||
      task.status === TaskStatus.COMP
    ) {
      return true;
    }
    return false;
  }
}
