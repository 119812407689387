<div class="container">
  <div class="navigation">
    <al-navigation>
      <al-navigation-button icon="home" [iconFirst]="true" (eventEmitterClick)="returnHome()"></al-navigation-button>
      <al-navigation-location [currentLocation]="'Synchronization queue list' | translate"></al-navigation-location>
    </al-navigation>
  </div>

  <div class="header">
    <div class="header-toggle">
      <button mat-button class="pending" type="button" [ngClass]="{'selected':showPending()}"
        (click)="setPending(true)">
        <span class="count">{{getPendingCount() | async}}</span><span translate>Pending</span>
      </button>
      <button mat-button class="errors" type="button" [ngClass]="{'selected' : showErrors()}"
        (click)="setPending(false)">
        <span class="count">{{getErrorCount() | async}}</span> <span translate>Errors</span>
      </button>
    </div>
    <button mat-flat-button type="button" class="launch-sync" [disabled]="!isOnline" (click)="refresh()"
      translate>Launch sync</button>
  </div>


  <div class="pending list" *ngIf="showPending()">
    <mat-table [dataSource]="pendingDataSource">
      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Work Type">
          <span *ngIf="element.number !== ''">{{ element.number }}</span>
          <span *ngIf="element.number ===''">{{ element.type }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Title">
          <span class="title">{{ element.title }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Status">
          <mat-icon class="status-icon status-icon-pending">cached</mat-icon>
          <span class="status" translate>Pending synchronization</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="review">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="review">
          <button class="review-button" (click)="openDialogWithRef(confirmReviewDialog,element)"
            translate>Review</button>
          <a class="delete-link" *ngIf="element.number ===''" (click)="openDialogWithRef(confirmDeletePendingDialog,element)"
            translate>Delete</a>
        </mat-cell>
      </ng-container>

      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

      <ng-container *matNoDataRow>
        <div class="no-data" translate>NO DATA</div>
      </ng-container>
    </mat-table>
  </div>

  <div class="error list" *ngIf="showErrors()">
    <mat-table [dataSource]="errorDataSource">
      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Work Type">
          <span *ngIf="element.number !== ''">{{ element.number }}</span>
          <span *ngIf="element.number ===''">{{ element.type }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Title">
          <span class="title">{{ element.title }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Status">
          <mat-icon class="status-icon status-icon-error">notifications_active</mat-icon>
          <span class="status">{{ element.message }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="review">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="review">
          <button class="review-button" (click)="openDialogWithRef(confirmReviewDialog,element)"
            translate>Review</button>
          <a class="delete-link" (click)="openDialogWithRef(confirmDeleteErrorDialog,element)" translate>Delete</a>
        </mat-cell>
      </ng-container>

      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

      <ng-container *matNoDataRow>
        <div class="no-data" translate>NO DATA</div>
      </ng-container>
    </mat-table>
  </div>

</div>

<ng-template #confirmDeleteErrorDialog>
  <al-confirm-dialog (confirm)='deleteHistory(currenSynchroInfo)'>
    <div title>{{ "Confirm Deletion of" | translate }} : {{currenSynchroInfo.title}}</div>
    <div content>{{ "Are you sure you want to delete? " | translate }}</div>
    <div cancel-button>{{ "No" | translate }}</div>
    <div confirm-button>{{ "Yes I want to delete" | translate }}</div>
  </al-confirm-dialog>
</ng-template>
<ng-template #confirmDeletePendingDialog>
  <al-confirm-dialog (confirm)='deletePending(currenSynchroInfo)'>
    <div title>{{ "Confirm Deletion of" | translate }} : {{currenSynchroInfo.title}}</div>
    <div content>{{ "Are you sure you want to delete? " | translate }}</div>
    <div cancel-button>{{ "No" | translate }}</div>
    <div confirm-button>{{ "Yes I want to delete" | translate }}</div>
  </al-confirm-dialog>
</ng-template>
<ng-template #confirmReviewDialog>
  <al-confirm-dialog (confirm)='review(currenSynchroInfo)'>
    <div title>{{ "Confirm Review for" | translate }} : {{currenSynchroInfo.title}}</div>
    <div content>{{ "Are you sure you want to review? " | translate }}</div>
    <div cancel-button>{{ "No" | translate }}</div>
    <div confirm-button>{{ "Yes I want to review" | translate }}</div>
  </al-confirm-dialog>
</ng-template>
