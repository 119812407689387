import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { RightActionEnum, RightTypeEnum, WorkOrder } from '@al/entities';
import { WorkOrdersService } from '@al/state';
import { SessionRights } from '@al/session';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'al-work-order-list',
  templateUrl: './al-work-order-list.component.html',
  styleUrls: ['./al-work-order-list.component.scss'],
})
export class AlWorkOrderListComponent implements OnInit, OnDestroy {
  @Input()
  public displayFilters = true;

  public dataSource = new MatTableDataSource<WorkOrder>([]);

  public displayedColumns: string[] = [
    'id',
    'description',
    'assetNum',
    'location',
    'objectiveEndDate',
    'workType',
    'priority',
  ];

  public rights = new SessionRights();

  public value = '';

  private ngUnsubscribe = new Subject();

  public constructor(
    private router: Router,
    private workOrdersService: WorkOrdersService
  ) {}

  public navigateSummary(row: WorkOrder): void {
    if (
      this.rights.isAllowedAction(
        RightTypeEnum.WOTRACK,
        RightActionEnum.INSERT
      ) ||
      this.rights.isAllowedAction(RightTypeEnum.WOTRACK, RightActionEnum.READ)
    ) {
      this.workOrdersService.setActive(row.id);
      this.router.navigate(['/', 'workorder']);
    }
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit() {
    this.workOrdersService.setActive(null);
    this.workOrdersService
      .selectAll()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (workOrders: WorkOrder[]) => {
          if (workOrders) {
            this.dataSource = new MatTableDataSource<WorkOrder>(workOrders);
          } else {
            this.dataSource = new MatTableDataSource<WorkOrder>([]);
          }
        },
        error: () => {
          this.dataSource = new MatTableDataSource<WorkOrder>([]);
        },
      });
  }
}
