import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlSpinnerService {
  public disconnectMessage = new BehaviorSubject(false);

  public progressSpinnerIsDisplayed = new BehaviorSubject(false);

  public snackBarIsDisplayed = new BehaviorSubject({});

  private listOfCurrentSpinners: Array<number> = [];

  public isProgressSpinnerDisplayed(): Observable<boolean> {
    return this.progressSpinnerIsDisplayed.asObservable();
  }

  public isSnackBarDisplayed(): Observable<any> {
    return this.snackBarIsDisplayed.asObservable();
  }

  public launchSnackBar(text: string, duration: number | null): void {
    this.snackBarIsDisplayed.next({ text, duration });
  }

  // currentMax ID = 16
  public startDisplay(spinnerID: number): void {
    if (!this.listOfCurrentSpinners.includes(spinnerID)) {
      this.listOfCurrentSpinners.push(spinnerID);
      if (spinnerID === 16) {
        this.disconnectMessage.next(true);
      }
      this.progressSpinnerIsDisplayed.next(true);
    }
  }

  public stopDisplay(spinnerID: number): void {
    if (spinnerID === 16) {
      this.disconnectMessage.next(false);
    }

    if (this.listOfCurrentSpinners.includes(spinnerID)) {
      const index = this.listOfCurrentSpinners.indexOf(spinnerID);
      if (index > -1) {
        this.listOfCurrentSpinners.splice(index, 1);
      }
    }
    if (this.listOfCurrentSpinners.length === 0) {
      this.progressSpinnerIsDisplayed.next(false);
    }
  }
}
