import { LocalStorageConfiguration } from '@al/entities';
import { AlOnlineService } from '@al/online';
import { AlSpinnerService } from '@al/spinner';
import { WorkOrdersService, WorkOrdersStore } from '@al/state';
import { AlSyncService } from '@al/sync';
import { SyncInfo, SyncInfoService, SyncInfoType } from '@al/sync-services';
import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-synchro-summary',
  templateUrl: './synchro-summary.component.html',
  styleUrls: ['./synchro-summary.component.scss'],
})
export class SynchroSummaryComponent implements OnInit, OnDestroy {
  public currenSynchroInfo!: SyncInfo;

  public displayedColumns: string[] = ['type', 'title', 'status', 'review'];

  public errorDataSource = new MatTableDataSource<SyncInfo>([]);

  public isOnline: boolean;

  public pending = true;

  public pendingDataSource = new MatTableDataSource<SyncInfo>([]);

  public syncPendingCount!: number;

  private ngUnsubscribe = new Subject();

  public constructor(
    public dialog: MatDialog,
    private alSyncService: AlSyncService,
    private onLineService: AlOnlineService,
    private router: Router,
    private synchroInfoService: SyncInfoService,
    private workOrdersStore: WorkOrdersStore,
    private workOrdersService: WorkOrdersService,
    private alSpinnerService: AlSpinnerService
  ) {
    this.isOnline = false;
  }

  public deleteHistory(element: SyncInfo): void {
    this.synchroInfoService.deleteFromHistory(element);
  }

  public deletePending(element: SyncInfo): void {
    this.synchroInfoService.deleteFromPending(element);
  }

  public getErrorCount(): Observable<number> {
    return this.synchroInfoService.getErrorsCount();
  }

  public getPendingCount(): Observable<number> {
    return this.synchroInfoService.getPendingCount();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    this.onLineService.status
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: boolean) => {
        this.isOnline = res;
      });

    this.synchroInfoService
      .getPending()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((count: any[]) => {
        this.syncPendingCount = count.length;
        if (count.length === 0) {
          this.alSpinnerService.stopDisplay(11);
        }
      });

    this.loadPendingFromCache();
    this.loadErrorFromCache();
  }

  public openDialogWithRef(ref: TemplateRef<any>, synchroInfo: SyncInfo) {
    this.currenSynchroInfo = synchroInfo;
    this.dialog.open(ref);
  }

  public refresh(): void {
    this.alSyncService.sync();
    if (this.syncPendingCount > 0) {
      this.alSpinnerService.startDisplay(11);
    }
  }

  public returnHome(): void {
    this.router.navigate(['/']);
  }

  public review(synchroInfo: SyncInfo): void {
    switch (synchroInfo.type) {
      case SyncInfoType.QR_CREATION:
        this.router.navigate(['/', 'review-qr', synchroInfo.uuid]);
        break;
      case SyncInfoType.SR_CREATION:
        this.router.navigate(['/', 'review-sr', synchroInfo.uuid]);
        break;
      case SyncInfoType.WO_CREATION:
        this.router.navigate(['/', 'review-bt', synchroInfo.uuid]);
        break;
      case SyncInfoType.WO_UPDATE:
        this.workOrdersStore.setActive(synchroInfo.uuid);
        localStorage.setItem(
          LocalStorageConfiguration.CURRENT_WORK_ORDER_ID,
          synchroInfo.uuid.toString()
        );
        this.router.navigate(['/', 'workorder']);
        break;
      default:
        break;
    }
  }

  public setPending(value: boolean): void {
    this.pending = value;
  }

  public showErrors(): boolean {
    return !this.pending;
  }

  public showPending(): boolean {
    return this.pending;
  }

  private loadErrorFromCache(): void {
    this.synchroInfoService
      .getErrors()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (pendings: SyncInfo[]) => {
          if (pendings) {
            this.errorDataSource = new MatTableDataSource<SyncInfo>(pendings);
          } else {
            this.errorDataSource = new MatTableDataSource<SyncInfo>([]);
          }
        },
        error: () => {
          this.errorDataSource = new MatTableDataSource<SyncInfo>([]);
        },
      });
  }

  private loadPendingFromCache(): void {
    this.synchroInfoService
      .getPending()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (pendings: SyncInfo[]) => {
          if (pendings) {
            this.pendingDataSource = new MatTableDataSource<SyncInfo>(pendings);
          } else {
            this.pendingDataSource = new MatTableDataSource<SyncInfo>([]);
          }
        },
        error: () => {
          this.pendingDataSource = new MatTableDataSource<SyncInfo>([]);
        },
      });
  }
}
