import { AlReferenceData } from './model/al-reference-data.model';

export const WorkOrderSourceReference: ReadonlyArray<AlReferenceData> = [
  { code: 'ALERT_GIE', label: 'LIE Alerts' },
  { code: 'ANALYS_ENV', label: 'Environmental Analysis' },
  { code: 'ARA', label: 'Accident Risk Assessment' },
  { code: 'ASTREINTE1', label: 'On-call with On-Site Travel' },
  { code: 'ASTREINTE2', label: 'On-call without On-Site Travel' },
  { code: 'ASTREINTE3', label: 'Potential On-call' },
  { code: 'AUDIT', label: 'Audit' },
  { code: 'AUDIT_EXT', label: 'External audit' },
  { code: 'AUDIT_INT', label: 'Internal audit' },
  { code: 'BREAK DOWN', label: 'Break down & incidents' },
  { code: 'BULL_GIE', label: 'LIE Bulletins' },
  { code: 'CAPIRES', label: 'Network Capitalizable Action' },
  { code: 'CHASSE ANO', label: 'Anomalies Inspection' },
  { code: 'CHSCT_CPPT', label: 'CHSCT  / CPPT' },
  { code: 'CLIENT', label: 'Customer / Contract' },
  { code: 'COMITE_PIL', label: 'Steering Committee' },
  { code: 'CTC', label: 'Plant Technical Committee' },
  { code: 'CTGIE', label: 'LIE Technical Committee' },
  { code: 'DIVERS', label: 'Miscellaneous' },
  { code: 'DOCS', label: 'Procedures & instructies' },
  { code: 'ELOGBOOK', label: 'Elogbook' },
  { code: 'EMAINTENANCE', label: 'eMaintenance' },
  { code: 'EMS', label: 'Efficiency Monitoring System (SIO.Perform)' },
  { code: 'EROUND', label: 'eRound' },
  {
    code: 'EVENT',
    label:
      'Situations that did not result in an incidents or IPG on SHEQ or reliability',
  },
  { code: 'FACILITY', label: 'Facility' },
  { code: 'FICHE MOD', label: 'Modification Sheet' },
  { code: 'FLASH', label: 'Flash group emergency' },
  { code: 'FOLLOWING INS', label: 'Following Inspection' },
  { code: 'FOOD', label: 'Food & HACCP' },
  { code: 'GOODPRACTICE', label: 'GOOD PRACTICE' },
  { code: 'HEALTHCARE', label: 'HEALTHCARE' },
  { code: 'IMGIE', label: 'IMGIE' },
  { code: 'IMPROVMENT', label: 'Improvment' },
  { code: 'IMS', label: 'INDUSTRIAL MANAGEMENT SYSTEM' },
  { code: 'INCI_REACT', label: 'REACT Incident' },
  { code: 'INCIDENT', label: 'Incidents, accidents' },
  { code: 'IROUND', label: 'IROUND' },
  { code: 'ITAR', label: 'ITAR' },
  { code: 'ITGIE', label: 'ITGIE' },
  { code: 'ITGM', label: 'ITGM' },
  { code: 'LEGAL', label: 'Legal' },
  { code: 'LI-ELEC-PM', label: 'LI ELECTRICAL PM TEAM' },
  { code: 'LI-HYDR-PM', label: 'LI HYDROGEN PM TEAM' },
  { code: 'LI-ONSITE-PM', label: 'LI ON SITE PM TEAM' },
  { code: 'LI-PIPE-PM', label: 'LI PIPELINE PM TEAM' },
  { code: 'MANDATORY', label: 'Rules' },
  { code: 'MECH-INT', label: 'MECHANICAL INTEGRITY TEAM' },
  { code: 'MEDECINE', label: 'Occupational Medecine' },
  { code: 'MOC', label: 'Management of Change' },
  { code: 'MP', label: 'Preventive Maintenance' },
  { code: 'NOTES', label: 'Bulletins' },
  { code: 'ONCALL1', label: 'On-call with On-Site Travel' },
  { code: 'ONCALL2', label: 'On-call without On-Site Travel' },
  { code: 'ONCALL3', label: 'Potential On-call during working hour' },
  { code: 'PBANA', label: 'problem analysis' },
  { code: 'PERENITY', label: 'Sustainability Studies' },
  { code: 'PLANT', label: 'PLANT REQUESTED' },
  { code: 'POI', label: 'Internal Operation Strategy' },
  { code: 'POSTPONED', label: 'Postponed Maintenance' },
  { code: 'PREDICT', label: 'Oil analysis / Partial Discharge /...' },
  { code: 'PROJECTS', label: 'Projects' },
  { code: 'PSSR', label: 'PRE-STARTUP SAFETY REVIEW' },
  { code: 'RCM', label: 'STREAMLINED RCM' },
  { code: 'RDD', label: 'Management Review' },
  { code: 'REGLEMENT', label: 'Regulation' },
  { code: 'ROOTCAUSE', label: 'RESULT OF ROOT CAUSE ANALYSIS' },
  { code: 'ROUTE', label: 'Route-Surveillance' },
  { code: 'SDGR/ARPT', label: 'Dynamic Risks Management System' },
  { code: 'SIO PREDICT', label: 'SIO Predict' },
  { code: 'SIXSIGMAS', label: 'SIX SIGMAS' },
  { code: 'TC', label: 'Technical committee' },
  { code: 'TECH_ALERT', label: 'Technical Alertes' },
  { code: 'TESTS', label: 'Specific Tests' },
  { code: 'TSI', label: 'Technical Safety Inspection' },
  { code: 'VCS/BSV', label: 'Behaviour Safety Visit (gedrag)' },
  { code: 'VS', label: 'Vulnerability Studies' },
  { code: 'X/ALERT', label: '' },
  { code: 'X/AUDIT_EXT', label: 'Audit external' },
  { code: 'X/AUDIT_INT', label: 'Audit internal' },
  { code: 'X/CUSTOMER', label: 'Customer' },
  { code: 'X/FLASH', label: '' },
  { code: 'X/IMPROVMENT', label: 'Improvment' },
  { code: 'X/ITAR', label: '' },
  { code: 'X/LEGAL', label: 'Legal' },
  { code: 'X/MANDATORY', label: 'Rules' },
  { code: 'X/MISCELLANANEOUS', label: 'Miscellaneous' },
  { code: 'X/MOC', label: 'Management of Change' },
  { code: 'X/RDD', label: 'Management Review' },
  { code: 'X/REACT INCIDENT', label: 'REACT Incident' },
  { code: 'X/RISK MANAGMENT', label: 'Risk managment' },
  { code: 'X/STEERING', label: 'Steering' },
  { code: 'X/VS', label: 'Vulnerability Studies' },
];
