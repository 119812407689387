import { LocalStorageKey, UserRights } from '@al/entities';
import { AlJsonConvert } from '@al/json';
import { Injectable } from '@angular/core';
import { LocalStorageManager } from '@al/cache';

@Injectable({
  providedIn: 'root',
})
export class UserRightsCreateCache {
  private alJsonConvert: AlJsonConvert;

  private cache: UserRights[];

  private cacheName = LocalStorageKey.USER_RIGHTS;

  private storageManager: LocalStorageManager<UserRights>;

  public constructor() {
    this.alJsonConvert = new AlJsonConvert();
    this.storageManager = new LocalStorageManager<UserRights>(this.cacheName);
    this.cache = this.alJsonConvert.deserializeArray(
      this.storageManager.load(),
      UserRights
    );
  }

  public clear(): void {
    this.storageManager.clear();
  }

  public put(value: UserRights): void {
    this.cache.push(value);
    this.storageManager.store(this.cache, UserRights);
  }

  public values(): UserRights[] {
    return this.cache;
  }
}
