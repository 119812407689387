import {
  LocalStorageKey,
  ServiceRequest,
  ServiceRequestPut,
  ServiceRequestPutResponse,
} from '@al/entities';
import { AlEnvironmentService, AwsEndPoint } from '@al/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractRestService } from './abstract-rest.service';

@Injectable({
  providedIn: 'root',
})
export class AttachmentSrRestService extends AbstractRestService<
  ServiceRequest,
  ServiceRequestPut,
  ServiceRequestPutResponse
> {
  protected keyLastDate: LocalStorageKey;

  protected keyLastRowstamp: LocalStorageKey;

  protected get createEndPoint(): string {
    return `${this.alEnvironmentService.env.apigwUrl}${AwsEndPoint.attachmentSr}`;
  }

  protected get getEndPoint(): string {
    return `${AwsEndPoint.attachmentSr}`;
  }

  protected get updateEndPoint(): string {
    return `${this.alEnvironmentService.env.apigwUrl}${AwsEndPoint.attachmentSr}`;
  }

  public constructor(
    protected alEnvironmentService: AlEnvironmentService,
    protected http: HttpClient
  ) {
    super(http);
    this.keyLastDate = LocalStorageKey.WORK_ORDER_SYNCHRO_LAST_DATE;
    this.keyLastRowstamp = LocalStorageKey.WORK_ORDER_SYNCHRO_LAST_ROWSTAMP;
  }

  protected getClazz(): typeof ServiceRequest {
    return ServiceRequest;
  }

  protected getPutRequestClazz(): typeof ServiceRequestPut {
    return ServiceRequestPut;
  }

  protected getPutResponseClazz(): typeof ServiceRequestPutResponse {
    return ServiceRequestPutResponse;
  }
}
