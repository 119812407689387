import { AttachmentItem } from '@al/entities';
import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AlIndexedDbService {
  public change = new Subject();

  private storeName: string;

  public constructor(private dbService: NgxIndexedDBService) {
    this.storeName = 'attachments';
  }

  public add(
    file: File,
    arrayBuffer: string | ArrayBuffer,
    site: string,
    workOrderNumber: string,
    putRequestUuid: string,
    newFileName: string,
    ticketId: number | null
  ): void {
    this.dbService
      .add<AttachmentItem>(this.storeName, {
        arrayBuffer,
        file,
        isUploaded: false,
        newFileName,
        site,
        workOrderNumber,
        putRequestUuid,
        ticketId,
      })
      .pipe(take(1))
      .subscribe({
        next: () => this.change.next(),
      });
  }

  public delete(key: string): void {
    const id = Number.parseInt(key, 10);
    this.dbService
      .delete(this.storeName, id)
      .pipe(take(1))
      .subscribe({
        next: () => this.change.next(),
      });
  }

  public deleteUploaded(): void {
    this.dbService
      .getAll<AttachmentItem>(this.storeName)
      .pipe(take(1))
      .subscribe((items) => {
        items
          .filter((item) => item.isUploaded)
          .forEach((item) => {
            const id = item.id ? Number.parseInt(item.id.toString(), 10) : null;
            if (id) {
              this.dbService.delete(this.storeName, id).subscribe();
            }
          });
      });
  }

  public get(key: string): void {
    const id = Number.parseInt(key, 10);
    this.dbService
      .getByID(this.storeName, id)
      .pipe(take(1))
      .subscribe({
        next: (result: any) => {
          const { arrayBuffer } = result;
          const fileName = result.newFileName;
          this.saveAs(arrayBuffer, fileName);
        },
      });
  }

  public getAll(): Observable<AttachmentItem[]> {
    return this.dbService.getAll<AttachmentItem>(this.storeName).pipe(take(1));
  }

  public getByNewFileName(newFileName: string): Observable<AttachmentItem> {
    return this.dbService.getByIndex<AttachmentItem>(
      this.storeName,
      'newFileName',
      newFileName
    );
  }

  public getByPutRequestUuid(uuid: UUID): Observable<AttachmentItem[]> {
    return this.dbService.getAllByIndex<AttachmentItem>(
      this.storeName,
      'putRequestUuid',
      IDBKeyRange.only(uuid)
    );
  }

  public getByWorkOrderNumber(
    workOrderNumber: string
  ): Observable<AttachmentItem[]> {
    return this.dbService.getAllByIndex<AttachmentItem>(
      this.storeName,
      'workOrderNumber',
      IDBKeyRange.only(workOrderNumber)
    );
  }

  public removeWithUuid(uuid: UUID): void {
    this.dbService
      .getAllByIndex<AttachmentItem>(
        this.storeName,
        'putRequestUuid',
        IDBKeyRange.only(uuid)
      )
      .subscribe((result) => {
        result.forEach((item) => {
          if (item.id) {
            this.dbService.delete(this.storeName, item.id).subscribe();
          }
        });
      });
  }

  public setUploaded(attachmentItem: AttachmentItem): void {
    const item = attachmentItem;
    item.isUploaded = true;
    this.dbService.update(this.storeName, item);
  }

  public updateByteArray(newFileName: string, arrayBuffer: ArrayBuffer): void {
    this.dbService
      .getAllByIndex<AttachmentItem>(
        this.storeName,
        'newFileName',
        IDBKeyRange.only(newFileName)
      )
      .subscribe({
        next: (result) => {
          result.forEach((item) => {
            const newItem = item;
            newItem.arrayBuffer = arrayBuffer;
            this.dbService.update(this.storeName, newItem);
          });
        },
      });
  }

  public updateTicketId(uuid: UUID, ticketId: number): Observable<boolean> {
    const obs = new BehaviorSubject(false);
    this.dbService
      .getAllByIndex<AttachmentItem>(
        this.storeName,
        'putRequestUuid',
        IDBKeyRange.only(uuid)
      )
      .subscribe({
        next: (result) => {
          result.forEach((item) => {
            const newItem = item;
            newItem.ticketId = ticketId;
            this.dbService.update(this.storeName, newItem);
          });
          obs.next(true);
        },
      });
    return obs.asObservable();
  }

  public updateWorkOrderNumber(
    uuid: UUID,
    workOrderNumber: string
  ): Observable<boolean> {
    const obs = new BehaviorSubject(false);
    this.dbService
      .getAllByIndex<AttachmentItem>(
        this.storeName,
        'putRequestUuid',
        IDBKeyRange.only(uuid)
      )
      .subscribe({
        next: (result) => {
          result.forEach((item) => {
            const newItem = item;
            newItem.workOrderNumber = workOrderNumber;
            this.dbService.update(this.storeName, newItem);
          });
          obs.next(true);
        },
      });
    return obs.asObservable();
  }

  private saveAs(data: ArrayBuffer, fileName: string): void {
    const blob = new Blob([data]);
    if (fileName.length > 0) {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(objectUrl);
    }
  }
}
