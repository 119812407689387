import { QuickReportPut, ServiceRequestPut, WorkOrderPut } from '@al/entities';
import { AlIndexedDbService } from '@al/indexed-db';
import { SiteQuery, WorkOrdersQuery } from '@al/state';
import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractCommonComponent } from '../common/abstract-common.component';

@Component({
  selector: 'al-file',
  templateUrl: './al-file.component.html',
  styleUrls: ['./al-file.component.scss'],
})
export class AlFileComponent
  extends AbstractCommonComponent
  implements OnDestroy
{
  @Input()
  public putRequest!:
    | (QuickReportPut | ServiceRequestPut | WorkOrderPut)
    | null;

  public constructor(
    protected alIndexedDbService: AlIndexedDbService,
    protected dialog: MatDialog,
    protected siteQuery: SiteQuery,
    protected workOrdersQuery: WorkOrdersQuery
  ) {
    super(alIndexedDbService, dialog, siteQuery, workOrdersQuery);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
