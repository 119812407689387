import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { LocalStorageConfiguration } from '@al/entities';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public errorType = '';

  public previousLoginFailed = false;

  public title: string;

  public constructor(private titleService: Title) {
    this.title = this.titleService.getTitle();
    const error = localStorage.getItem(LocalStorageConfiguration.USER_ID);
    if (error) {
      // TODO: Utiliser un logger global
      if (error === 'login_failed' || error === 'site_missing') {
        this.previousLoginFailed = true;
        this.errorType = error;
      }
    }
  }
}
