<div class="al-create-bt">
  <form [formGroup]="form" (submit)="submitWorkOrder()">
    <div class="content">

      <div class="side">
        <mat-card>
          <div class="field-group">
            <div class="field">
              <mat-label class="required-field">{{ "Work Type" | translate }}
              </mat-label>
              <mat-form-field appearance="outline">
                <mat-select type="text" placeholder="{{ 'work-type' | translate }}" formControlName="woType" required>
                  <mat-option *ngFor="let worktype of references.workType" [value]="worktype.type">
                    {{ worktype.type }}{{ worktype.label ? ": " : "" }}
                    {{ worktype.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="field">
              <mat-label class="required-field">{{ "Priority" | translate }}
              </mat-label>
              <mat-form-field appearance="outline">
                <mat-select type="text" placeholder="{{ 'work-priority' | translate }}" formControlName="woPriority"
                  required>
                  <mat-option *ngFor="let priority of references.taskPriority" [value]="priority.priority">
                    {{ priority.priority }}{{ priority.label ? ": " : "" }}
                    {{ priority.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="field">
              <mat-label class="required-field">{{ "Source" | translate }} </mat-label>
              <mat-form-field appearance="outline">
                <mat-select type="text" formControlName="source" placeholder="{{ 'source' | translate }}" required>
                  <mat-option *ngFor="let source of references.workOrderSource" [value]="source.code">
                    {{ source.code }}{{ source.label ? ": " : "" }}
                    {{ source.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="field">
            <mat-label>{{ "RelatedTo" | translate }} </mat-label>
            <mat-form-field appearance="outline">
              <input matInput type="number" formControlName="woParent">
            </mat-form-field>
          </div>

          <div class="field-group">
            <div class="field">
              <mat-label class="required-field">{{ "Date" | translate }}
              </mat-label>
              <mat-form-field appearance="outline"
                [ngClass]="{'dateField' : actualStartDateTime?.errors?.wrongDate || actualFinishDateTime?.errors?.wrongDate}">
                <input matInput type="datetime-local" formControlName="actualStartDateTime" required
                  (change)="checkDateValidators()">
              </mat-form-field>
            </div>
            <div class="field">
              <mat-label class="required-field">{{ "Objective end date" | translate }}
              </mat-label>
              <mat-form-field appearance="outline"
                [ngClass]="{'dateField' : actualStartDateTime?.errors?.wrongDate || actualFinishDateTime?.errors?.wrongDate}">
                <input matInput type="datetime-local" formControlName="actualFinishDateTime" required
                  (change)="checkDateValidators()">
              </mat-form-field>
            </div>
          </div>

          <div class="field">
            <mat-error *ngIf="actualStartDateTime?.errors?.wrongDate || actualFinishDateTime?.errors?.wrongDate">
              {{ "can't finish before start" | translate }}
            </mat-error>
          </div>

          <div class="field-group">
            <div class="field">
              <mat-label class="required-field">{{ "Domain" | translate }} </mat-label>
              <mat-form-field appearance="outline">
                <mat-select type="text" formControlName="domain" placeholder="{{ 'domain' | translate }}" required>
                  <mat-option *ngFor="let domain of references.domain" [value]="domain.domain">
                    {{ domain.domain }}{{ domain.label ? ": " : "" }}
                    {{ domain.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="field">
              <mat-label class="required-field">{{ "Job-type-option" | translate }} </mat-label>
              <mat-form-field appearance="outline">
                <mat-select type="text" placeholder="{{ 'work-type' | translate }}" formControlName="jobTypeOption"
                  required>
                  <mat-option *ngFor="let jobtype of references.workOrderJobType" [value]="jobtype.type">
                    {{ jobtype.type }}{{ jobtype.label ? ": " : "" }}
                    {{ jobtype.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="field">
            <mat-label>{{ "Location" | translate }} </mat-label>
            <mat-form-field appearance="outline">
              <button matPrefix mat-icon-button (click)="displayTree()" type="button">
                <mat-icon>search</mat-icon>
              </button>
              <input matInput readonly type="text" placeholder="{{'Select location' | translate}}"
                formControlName="location">
              <button *ngIf="locationObject" mat-button matSuffix mat-icon-button aria-label="Clear" type="button"
                (click)="clearLocation()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <div class="field">
            <mat-label>{{ "Asset" | translate }} </mat-label>
            <mat-form-field appearance="outline">
              <input matInput type="text" formControlName="asset" readonly>
            </mat-form-field>
          </div>
        </mat-card>
      </div>

      <div class="main">

        <mat-card *ngIf="synchroInfo && synchroInfo.status==='KO'">
          <div class="synchroInfo">
            <div class="error">
              <mat-icon class="error">notifications_active</mat-icon>
              <span>{{synchroInfo.message}}</span>
            </div>
          </div>
        </mat-card>

        <mat-card>
          <div class="field">
            <mat-label class="required-field"> {{ "Description" | translate }} </mat-label>
            <mat-form-field class="text-input" appearance="outline">
              <textarea matInput rows="2" cols="75" maxlength="DESCRIPTION_MAX_LENGTH" formControlName="description"
                required></textarea>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-label>
              {{ "Remarks" | translate }}
            </mat-label>
            <mat-form-field class="text-input" appearance="outline">
              <textarea matInput rows="2" cols="75" maxlength="DESCRIPTION_MAX_LENGTH" formControlName="remarks"></textarea>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-label> {{ "Long-description" | translate }} </mat-label>
            <mat-form-field class="text-input" appearance="outline">
              <textarea matInput rows="12" cols="75" maxlength="LONG_DESCRIPTION_MAX_LENGTH"
                formControlName="longDescription"></textarea>
            </mat-form-field>
          </div>
        </mat-card>

        <mat-card>
          <al-attachments [putRequest]="putRequest"></al-attachments>
        </mat-card>

      </div>

    </div>

    <div class="actions">
      <button mat-raised-button color="primary" type="button" (click)="openDialogWithRef(cancelDialog)">
        {{ "cancel" | translate }}
      </button>
      <button mat-raised-button color="primary" type="submit" [disabled]="form.invalid">
        {{ "submit" | translate }}
      </button>
    </div>

  </form>
</div>

<ng-template #cancelDialog>
  <al-confirm-dialog (confirm)='cancel()'>
    <div title>{{ "Cancelling WorkOrder creation" | translate }}</div>
    <div content>{{ "Are you sure you want to cancel? All unsaved progress will be lost" | translate }}</div>
    <div cancel-button>{{ "No" | translate }}</div>
    <div confirm-button>{{ "Yes I want to cancel" | translate }}</div>
  </al-confirm-dialog>
</ng-template>
