import {
  FailureReportPutResponse,
  FailureReportRequestPut,
  RightActionEnum,
  RightTypeEnum,
  WorkOrder,
} from '@al/entities';
import {
  AlReference,
  AlReferenceData,
  AlReferenceService,
  FailureCodeReference,
  RootCauseReference,
} from '@al/reference';
import {
  AlRequestInformationDialogComponent,
  AlRequestInformationDialogData,
  AlRequestInformationDialogDataFrPut,
} from '@al/request-information-dialog';
import { SessionRights } from '@al/session';
import { AlSpinnerService } from '@al/spinner';
import { SiteQuery, WorkOrdersQuery } from '@al/state';
import { SyncInfo, SyncInfoService, SyncInfoStatus } from '@al/sync-services';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import { AlWorkOrderFailureReportService } from './al-work-order-failure-report.service';

@Component({
  selector: 'al-work-order-failure-report',
  templateUrl: 'al-work-order-failure-report.component.html',
  styleUrls: ['al-work-order-failure-report.component.scss'],
})
export class AlWorkOrderFailureReportComponent implements OnInit, OnDestroy {
  @ViewChild(FormGroupDirective) public formGroupDirective:
    | FormGroupDirective
    | undefined;

  @ViewChild('overFlowed') public overFlowed: ElementRef | undefined;

  @Input()
  public viewDetails = false;

  public actualFinishDate: Date | null = null;

  public actualStartDate: Date | null = null;

  public checked = false;

  public details: string | null = null;

  public failureCode: string | null = null;

  public failureDate: Date | null = null;

  public filteredFailureCode!: Observable<AlReferenceData[] | undefined>;

  public form: FormGroup;

  public isFailDateRequired = false;

  public references: AlReference;

  public rights = new SessionRights();

  public rootCause: string | null = null;

  public secondDisplay = false;

  public summary: string | null = null;

  public synchroInfo!: SyncInfo | undefined;

  private ngUnsubscribe = new Subject();

  private woTypeMandatory = ['M13', 'M31', 'M32'];

  private workOrder!: WorkOrder;

  public constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private workOrdersQuery: WorkOrdersQuery,
    public alReferenceService: AlReferenceService,
    private alWorkOrderFailureReportService: AlWorkOrderFailureReportService,
    private siteQuery: SiteQuery,
    private synchroInfoService: SyncInfoService,
    private alSpinnerService: AlSpinnerService
  ) {
    this.references = alReferenceService.getReferenceData([
      'failureCode',
      'rootCause',
    ]);

    this.alWorkOrderFailureReportService.setFailureReportIsModified(false);
    this.form = this.buildFormGroup();
    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      if (this.form.dirty && !this.compareFailureReportRequestPutWithStored()) {
        this.alWorkOrderFailureReportService.setFailureReportIsModified(true);
      } else {
        this.alWorkOrderFailureReportService.setFailureReportIsModified(false);
      }
    });
  }

  public get actualFinishDateTime(): AbstractControl | null {
    return this.form.get('actualFinishDateTime');
  }

  public get actualStartDateTime(): AbstractControl | null {
    return this.form.get('actualStartDateTime');
  }

  public get areFieldsMandatory(): boolean {
    if (this.workOrder) {
      return this.woTypeMandatory.includes(this.workOrder.type);
    }
    return false;
  }

  public get isSecondisplay(): boolean {
    if (!this.secondDisplay) {
      this.checkOverflow(this.overFlowed);
    }
    return this.secondDisplay;
  }

  public checkDateValidators(): void {
    this.form.get('actualStartDateTime')?.updateValueAndValidity();
    this.form.get('actualFinishDateTime')?.updateValueAndValidity();
    this.form.get('rootCause')?.updateValueAndValidity();
    this.form.get('failureCode')?.updateValueAndValidity();
  }

  public checkOverflow(element: ElementRef<any> | undefined) {
    if (element) {
      if (
        element.nativeElement.offsetHeight <
          element.nativeElement.scrollHeight ||
        element.nativeElement.offsetWidth < element.nativeElement.scrollWidth
      ) {
        this.secondDisplay = true;
      }
    }
  }

  public clearDate(): void {
    this.form.get('failureDateTime')?.setValue('');
  }

  public clearFailureCode(): void {
    this.form.get('failureCode')?.setValue('');
  }

  public disableButton(): boolean {
    return (
      this.rights.isAllowedAction(
        RightTypeEnum.WOTRACK,
        RightActionEnum.INSERT
      ) && !this.form.invalid
    );
  }

  public disableInputs(): boolean {
    return this.rights.isAllowedAction(
      RightTypeEnum.WOTRACK,
      RightActionEnum.INSERT
    );
  }

  public displayCreation(data: FailureReportPutResponse): void {
    this.alSpinnerService.stopDisplay(10);
    AlRequestInformationDialogComponent.open<
      FailureReportPutResponse,
      AlRequestInformationDialogDataFrPut
    >(data, this.dialog, this.buildDialogInformation.bind(this))
      .afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((status) => {
        if (status === 'OK' || status === 'PENDING') {
          this.resetForm();
        }
      });
  }

  public displayFailureCodeLabel(failureCode: AlReferenceData): string {
    if (failureCode !== null && failureCode.failureCode && failureCode.label) {
      return `${failureCode.failureCode} : ${failureCode.label}`;
    }
    return '';
  }

  public displayRootCauseLabel(rootCause: AlReferenceData): string {
    if (rootCause.rootCause && rootCause.label) {
      return `${rootCause.rootCause} : ${rootCause.label}`;
    }
    return '';
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    this.workOrdersQuery
      .selectActive()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: WorkOrder | undefined) => {
        if (res) {
          this.workOrder = res;
          this.initFormGroup();
        }
      });
    // this.form = this.buildFormGroup();
    this.initFormGroup();
    this.filteredFailureCode = this.form.controls.failureCode.valueChanges.pipe(
      startWith(''),
      map((val) => this.filter(val))
    );
  }

  public submitWorkOrder(): void {
    const failureReportRequestPut = this.createFailureReportPut();
    this.alSpinnerService.startDisplay(10);

    this.alWorkOrderFailureReportService
      .create(failureReportRequestPut)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (
          this.synchroInfo &&
          (res.status === SyncInfoStatus.OK ||
            res.status === SyncInfoStatus.PENDING)
        ) {
          this.synchroInfoService.deleteFromHistory(this.synchroInfo);
          this.synchroInfo = undefined;
        }
        this.displayCreation(res);
      });
  }

  public viewDetailsSwitch(): void {
    this.viewDetails = !this.viewDetails;
  }

  public wrongDateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let forbidden = false;
      if (control.value) {
        const futureDate = new Date(control.parent?.value.actualFinishDateTime);
        const pastDate = new Date(control.value);
        forbidden = +futureDate <= +pastDate;
      } else {
        forbidden = false;
      }
      return forbidden ? { wrongDate: { value: control.value } } : null;
    };
  }

  public wrongFinishDateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let forbidden = false;
      if (control.value) {
        const futureDate = new Date(control.value);
        const pastDate = new Date(control.parent?.value.actualStartDateTime);
        forbidden = +futureDate <= +pastDate;
      } else {
        forbidden = false;
      }
      return forbidden ? { wrongDate: { value: control.value } } : null;
    };
  }

  private buildDialogInformation(
    res: FailureReportPutResponse
  ): AlRequestInformationDialogData<AlRequestInformationDialogDataFrPut> {
    const { type, error, siteId, status, workOrderId, workOrderNum } = res;

    return {
      title: 'Failure report creation',
      type: 'frPut',
      information: {
        action: type,
        error: error ?? undefined,
        siteId,
        status,
        workOrderId: workOrderId ?? undefined,
        workOrderNum,
      },
    };
  }

  private buildFormGroup(): FormGroup {
    return this.formBuilder.group({
      actualStartDateTime: this.formBuilder.control(false, [
        // Validators.required,
        this.wrongDateValidator(),
      ]),
      actualFinishDateTime: this.formBuilder.control(false, [
        // Validators.required,
        this.wrongFinishDateValidator(),
      ]),
      rootCause: this.formBuilder.control(''),
      failureCode: this.formBuilder.control(''),
      failureDateTime: this.formBuilder.control(''),
      checked: this.formBuilder.control(''),
      summary: this.formBuilder.control('', Validators.required),
      details: this.formBuilder.control(''),
    });
  }

  private compareFailureReportRequestPutWithStored(): boolean {
    const failureReportRequestPut = this.createFailureReportPut();
    let failureAcceptableIsValid = false;
    failureAcceptableIsValid =
      failureReportRequestPut.failureAcceptable ===
      this.workOrder.failureAcceptable;
    let newActualFinish = new Date();
    let newActualStart = new Date();
    let newFailureDate = new Date();
    if (failureReportRequestPut.actualFinish) {
      newActualFinish = new Date(
        failureReportRequestPut.actualFinish.getTime() -
          failureReportRequestPut.actualFinish.getTimezoneOffset() * 60000
      );
      failureReportRequestPut.actualFinish = newActualFinish;
    }
    if (failureReportRequestPut.actualStart) {
      newActualStart = new Date(
        failureReportRequestPut.actualStart.getTime() -
          failureReportRequestPut.actualStart.getTimezoneOffset() * 60000
      );
      failureReportRequestPut.actualFinish = newActualStart;
    }
    if (failureReportRequestPut.failureDate) {
      newFailureDate = new Date(
        failureReportRequestPut.failureDate.getTime() -
          failureReportRequestPut.failureDate.getTimezoneOffset() * 60000
      );
      failureReportRequestPut.actualFinish = newFailureDate;
    }
    if (
      this.workOrder.failureMarkDescriptionLong === null &&
      failureReportRequestPut.remarkDetails === ''
    ) {
      failureReportRequestPut.remarkDetails = null;
    }

    if (
      failureReportRequestPut.failureAcceptable === false &&
      this.workOrder.failureAcceptable === null
    ) {
      failureAcceptableIsValid = true;
    }
    return (
      newActualFinish.getTime() === this.workOrder.actDateFin?.getTime() &&
      newActualStart.getTime() === this.workOrder.actDateDebut?.getTime() &&
      failureAcceptableIsValid &&
      failureReportRequestPut.failureCode === this.workOrder.failureCode &&
      newFailureDate.getTime() === this.workOrder.failureDate?.getTime() &&
      failureReportRequestPut.remark ===
        this.workOrder.failureMarkDescription &&
      failureReportRequestPut.remarkDetails ===
        this.workOrder.failureMarkDescriptionLong &&
      failureReportRequestPut.rootCause === this.workOrder.rootCause
    );
  }

  private createFailureReportPut(): FailureReportRequestPut {
    const failureReportRequestPut: FailureReportRequestPut =
      new FailureReportRequestPut();
    const formValues = this.form.value;
    const siteId = this.siteQuery.getActive()?.maximoId;
    if (siteId !== undefined) {
      failureReportRequestPut.siteId = siteId;
    }
    failureReportRequestPut.number = this.workOrder.number;
    failureReportRequestPut.actualStart = new Date(
      formValues.actualStartDateTime
    );
    failureReportRequestPut.actualFinish = new Date(
      formValues.actualFinishDateTime
    );

    failureReportRequestPut.failureDate = new Date(formValues.failureDateTime);

    if (formValues.rootCause) {
      failureReportRequestPut.rootCause = formValues.rootCause.enumKey;
    }

    if (formValues.failureCode) {
      failureReportRequestPut.failureCode = formValues.failureCode.enumKey;
    }

    failureReportRequestPut.failureAcceptable = formValues.checked;
    failureReportRequestPut.remark = formValues.summary;
    failureReportRequestPut.remarkDetails = formValues.details;

    return failureReportRequestPut;
  }

  private filter(value: string): AlReferenceData[] {
    if (this.references.failureCode) {
      if (!value || value === '') {
        return this.references.failureCode.concat();
      }
      return this.references.failureCode?.filter((s) =>
        new RegExp(value, 'gi').test(s.label + s.failureCode)
      );
    }
    return [];
  }

  private initFormGroup(): void {
    const today = new Date();
    const addOneMin = new Date();
    const timeZoneDiff = today.getTimezoneOffset();
    today.setMinutes(today.getMinutes() - timeZoneDiff);
    addOneMin.setMinutes(addOneMin.getMinutes() - timeZoneDiff + 1);

    this.form
      .get('actualStartDateTime')
      ?.setValue(
        this.workOrder && this.workOrder.actDateDebut
          ? new Date(this.workOrder.actDateDebut).toISOString().substring(0, 16)
          : today.toISOString().substring(0, 16)
      );

    this.form
      .get('actualFinishDateTime')
      ?.setValue(
        this.workOrder && this.workOrder.actDateFin
          ? new Date(this.workOrder.actDateFin).toISOString().substring(0, 16)
          : addOneMin.toISOString().substring(0, 16)
      );

    this.form
      .get('failureDateTime')
      ?.setValue(
        this.workOrder && this.workOrder.failureDate
          ? new Date(this.workOrder.failureDate).toISOString().substring(0, 16)
          : addOneMin.toISOString().substring(0, 16)
      );

    this.form
      .get('checked')
      ?.setValue(this.workOrder ? this.workOrder.failureAcceptable : false);

    this.form
      .get('summary')
      ?.setValue(
        this.workOrder && this.workOrder.failureMarkDescription
          ? this.workOrder.failureMarkDescription
          : ''
      );

    this.form
      .get('details')
      ?.setValue(
        this.workOrder && this.workOrder.failureMarkDescriptionLong
          ? this.workOrder.failureMarkDescriptionLong
          : ''
      );

    if (this.workOrder && this.workOrder.rootCause) {
      const v = this.workOrder.rootCause;
      const t = RootCauseReference.filter((struct) => {
        return struct.enumKey === v;
      })[0];
      this.form.get('rootCause')?.setValue(t);
    }
    if (this.workOrder && this.workOrder.failureCode) {
      const v = this.workOrder.failureCode;
      const t = FailureCodeReference.filter((struct) => {
        return struct.enumKey === v;
      })[0];
      this.form.get('failureCode')?.setValue(t);
      this.form.get('failureDateTime')?.setValidators(Validators.required);
      this.isFailDateRequired = true;
    }

    if (this.areFieldsMandatory) {
      this.form.get('rootCause')?.setValidators(Validators.required);
      this.form.get('failureCode')?.setValidators(Validators.required);
    }

    this.form.get('failureCode')?.valueChanges.subscribe((value) => {
      if (value) {
        this.form.get('failureDateTime')?.setValidators(Validators.required);
        this.isFailDateRequired = true;
      } else {
        this.form.get('failureDateTime')?.setValidators(null);
        this.form.get('failureDateTime')?.updateValueAndValidity();

        this.isFailDateRequired = false;
      }
    });

    this.form.get('actualStartDateTime')?.markAsTouched();
    this.form.get('actualFinishDateTime')?.markAsTouched();
    this.form.get('failureDateTime')?.markAsTouched();

    this.checkDateValidators();
  }

  private resetForm(): void {
    this.synchroInfo = undefined;
    setTimeout(() => {
      this.formGroupDirective?.resetForm();
      this.initFormGroup();
    }, 0);
  }
}
