import { Component, Inject, Optional } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  AlRequestInformationDialogDataFrPut,
  AlRequestInformationDialogDataQrPut,
  AlRequestInformationDialogDataSrPut,
  AlRequestInformationDialogDataWoComp,
  AlRequestInformationDialogDataWoPut,
} from './model';
import { AlRequestInformationDialogData } from './model/al-request-information-dialog-data';

@Component({
  selector: 'al-request-information-dialog',
  templateUrl: './al-request-information-dialog.component.html',
  styleUrls: ['./al-request-information-dialog.component.scss'],
})
export class AlRequestInformationDialogComponent {
  public constructor(
    public dialogRef: MatDialogRef<AlRequestInformationDialogComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: AlRequestInformationDialogData<
      | AlRequestInformationDialogDataFrPut
      | AlRequestInformationDialogDataQrPut
      | AlRequestInformationDialogDataSrPut
      | AlRequestInformationDialogDataWoPut
      | AlRequestInformationDialogDataWoComp
    >
  ) {}

  public static open<T, R>(
    data: T,
    matDialog: MatDialog,
    callback: (data: T) => AlRequestInformationDialogData<R>
  ): MatDialogRef<AlRequestInformationDialogComponent> {
    return matDialog.open(AlRequestInformationDialogComponent, {
      data: callback(data),
    });
  }
}
