<div class="quick-report">

  <div class="navigation">
    <al-navigation>
      <al-navigation-button
        icon="home"
        [iconFirst]="true"
        (eventEmitterClick)="openDialogWithRef(cancelDialog)"
        title="{{ 'Return home' | translate }}"
      ></al-navigation-button>
      <al-navigation-location
        [currentLocation]="'quick-report-title' | translate"
      ></al-navigation-location>
    </al-navigation>
  </div>

  <div class="content">
    <div class="main">
      <al-quick-report></al-quick-report>
    </div>
  </div>

</div>
<ng-template #cancelDialog>
  <al-confirm-dialog (confirm)='returnHome()'>
    <div title>{{ "Cancelling Quick Report creation" | translate }}</div>
    <div content>{{ "Are you sure you want to cancel? All unsaved progress will be lost" | translate }}</div>
    <div cancel-button>{{ "No" | translate }}</div>
    <div confirm-button>{{ "Yes I want to cancel" | translate }}</div>
  </al-confirm-dialog>
</ng-template>
