<div class="work-order">
  <div class="navigation">
    <al-navigation>
      <al-navigation-button icon="home" [iconFirst]="true" (eventEmitterClick)="returnHome()"
        title="{{ 'Return home' | translate }}"></al-navigation-button>
      <span class="work-order-description">{{getWorkOrderDescription}}</span>
      <span class="work-order-status">
        <mat-chip-list>
          <mat-chip title="{{getWorkOrderStatus | translate}}">{{getWorkOrderStatus}}</mat-chip>
        </mat-chip-list>
      </span>
      <span class="spacer"></span>
      <al-navigation-button icon="keyboard_arrow_left" text="{{ 'Previous' | translate }}"
        title="{{ 'Previous' | translate }}" [iconFirst]="true" (eventEmitterClick)="previousWorkOrder()">
      </al-navigation-button>
      <al-navigation-button icon="keyboard_arrow_right" text="{{ 'Next' | translate }}" title="{{ 'Next' | translate }}"
        [iconFirst]="false" (eventEmitterClick)="nextWorkOrder()"></al-navigation-button>
    </al-navigation>
  </div>

  <div class="content">

    <mat-sidenav-container autosize>

      <mat-sidenav mode="side" opened>
        <div class="side">
          <al-work-order-summary></al-work-order-summary>
        </div>
      </mat-sidenav>

      <mat-sidenav-content>
        <div class="main">

          <mat-card>
            <al-work-order-description></al-work-order-description>
            <al-work-order-task-list (completion)="submitWorkOrder()" [instantCompletion]="isFRMandatory">
            </al-work-order-task-list>
            <al-work-order-children-list></al-work-order-children-list>
            <al-work-order-failure-report></al-work-order-failure-report>
          </mat-card>

          <div class="buttons" *ngIf="displaySubmitWorkOrder">
            <button mat-raised-button color="primary" class="inside-button" (click)="submitWorkOrder()"
              [disabled]="!disableButtonSubmit()" translate>
              {{'SUBMIT' | translate}}
            </button>
            <button mat-raised-button color="primary" class="inside-button" (click)="submitAndCloseWorkOrder()"
              [disabled]="!disableButtonClose()" translate>
              {{'SUBMIT & CLOSE' | translate}}
            </button>
          </div>

          <div class="buttons">
            <button mat-raised-button color="primary" class="inside-button" (click)="navigateTo('/quick-report')"
              translate [disabled]="!disableButton('QR')">{{'CREATE QUICK REPORT' | translate}}</button>
            <button mat-raised-button color="primary" class="inside-button" (click)="navigateTo('/create-bt')" translate
              [disabled]="!disableButton('WO')">{{'CREATE WORK ORDER' | translate}}</button>
          </div>
        </div>
      </mat-sidenav-content>

    </mat-sidenav-container>

  </div>

</div>

<ng-template #cancelDialog>
  <!-- <al-confirm-dialog (confirm)='cancel()'> -->
  <al-confirm-dialog (confirm)="closeDialog(true)">
    <div title>{{ "Failure Report not saved" | translate }}</div>
    <div content>{{ "Leaving this page may cause you to loose Failure Report unsaved data, are you sure?" | translate }}
    </div>
    <div cancel-button>{{ "No" | translate }}</div>
    <div confirm-button>{{ "Yes" | translate }}</div>
  </al-confirm-dialog>
</ng-template>
