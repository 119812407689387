import { AlCommonComponent } from '@al/common';
import {
  QuickReportPut,
  QuickReportPutResponse,
  WorkOrder,
} from '@al/entities';
import { AlIndexedDbService } from '@al/indexed-db';
import { AlReferenceService } from '@al/reference';
import {
  AlRequestInformationDialogComponent,
  AlRequestInformationDialogData,
  AlRequestInformationDialogDataQrPut,
} from '@al/request-information-dialog';
import { AlSpinnerService } from '@al/spinner';
import {
  EquipmentsService,
  ProcessGroupsService,
  ProductionUnitsService,
  QuickReportService,
  SiteQuery,
  WorkOrdersQuery,
} from '@al/state';
import { SyncInfoService, SyncInfoStatus } from '@al/sync-services';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-quick-report',
  templateUrl: './al-quick-report.component.html',
  styleUrls: ['./al-quick-report.component.scss'],
})
export class AlQuickReportComponent
  extends AlCommonComponent
  implements OnInit, OnDestroy
{
  @ViewChild(FormGroupDirective)
  public formGroupDirective: FormGroupDirective | undefined;

  public putRequest: QuickReportPut | null;

  protected reloaded: QuickReportPut | null;

  private inherit = false;

  private workOrderParent!: WorkOrder;

  public get actualStartDateTime() {
    return this.form.get('actualStartDateTime');
  }

  public get actualFinishDateTime() {
    return this.form.get('actualFinishDateTime');
  }

  public constructor(
    public alReferenceService: AlReferenceService,
    public dialog: MatDialog,
    protected alIndexedDbService: AlIndexedDbService,
    protected router: Router,
    private activatedRoute: ActivatedRoute,
    private alSpinnerService: AlSpinnerService,
    private readonly formBuilder: FormBuilder,
    private quickReportService: QuickReportService,
    private synchroInfoService: SyncInfoService,
    private workOrdersQuery: WorkOrdersQuery,
    fb: FormBuilder,
    processGroupService: ProcessGroupsService,
    productionUnitsService: ProductionUnitsService,
    siteQuery: SiteQuery,
    equipmentsService: EquipmentsService
  ) {
    super(
      dialog,
      alIndexedDbService,
      router,
      fb,
      processGroupService,
      productionUnitsService,
      equipmentsService,
      siteQuery
    );
    this.putRequest = this.getNewPutRequest();
    this.reloaded = null;
    this.references = alReferenceService.getReferenceData([
      'domain',
      'workOrderJobType',
      'taskPriority',
      'workOrderSource',
      'workType',
    ]);
    if (this.router.getCurrentNavigation()?.extras?.state?.inherit) {
      this.inherit = true;
    }
    this.form = this.buildFormGroup();
  }

  public checkDateValidators(): void {
    this.form.get('actualStartDateTime')?.updateValueAndValidity();
    this.form.get('actualFinishDateTime')?.updateValueAndValidity();
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    this.workOrdersQuery
      .selectActive()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: WorkOrder | undefined) => {
        if (res) {
          this.workOrderParent = res;
        }
      });

    const synchroInfoUUid = this.activatedRoute.snapshot.params.synchroInfoUuid;
    this.reloaded =
      this.synchroInfoService.getCreateQuickReportPut(synchroInfoUUid);

    this.synchroInfoService
      .getErrorByUuid(synchroInfoUUid)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((info) => {
        if (info) {
          this.synchroInfo = info;
        }
      });
    this.synchroInfoService
      .getPendingByUuid(synchroInfoUUid)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((info) => {
        if (info) {
          this.synchroInfo = info;
        }
      });

    this.initFormGroup();
  }

  public submitQuickReport(): void {
    if (this.form.valid && this.putRequest) {
      const formValues = this.form.value;

      this.putRequest.parent = formValues.woParent;
      this.putRequest.type = formValues.woType;
      this.putRequest.source = formValues.source;
      this.putRequest.domain = formValues.domain;
      this.putRequest.jobType = formValues.jobTypeOption;
      this.putRequest.priority = formValues.priority;
      this.putRequest.location = this.getLocation();
      this.putRequest.assetNum = this.getAsset();
      this.putRequest.duration = this.getDuration();
      this.putRequest.actualStart = this.getActualStart();
      this.putRequest.actualFinish = this.getActualFinish();
      this.putRequest.priority = formValues.priority;
      this.putRequest.remark = formValues.remarks;
      this.putRequest.remarkDetails = formValues.longDescription;
      this.putRequest.description = formValues.description;
      this.putRequest.failureAcceptable = formValues.failureAcceptable;
      this.putRequest.siteId = this.getSiteId();

      if (this.reloaded && this.reloaded.uuid) {
        this.putRequest.uuid = this.reloaded.uuid;
      }

      this.alSpinnerService.startDisplay(8);

      this.quickReportService
        .create(this.putRequest)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: QuickReportPutResponse) => {
          if (
            this.synchroInfo &&
            (res.status === SyncInfoStatus.OK ||
              res.status === SyncInfoStatus.PENDING)
          ) {
            this.synchroInfoService.deleteFromHistory(this.synchroInfo);
            this.synchroInfo = undefined;
            this.reloaded = null;
          }
          if (
            this.putRequest &&
            this.putRequest.uuid &&
            res &&
            res.workOrderNum
          ) {
            this.alIndexedDbService.updateWorkOrderNumber(
              this.putRequest.uuid,
              res.workOrderNum
            );
          }
          this.displayCreation(res);
        });
    }
  }

  protected buildDialogInformation(
    res: QuickReportPutResponse
  ): AlRequestInformationDialogData<AlRequestInformationDialogDataQrPut> {
    const { type, error, siteId, status, workOrderId, workOrderNum } = res;

    return {
      title: 'quick-report-title',
      type: 'qrPut',
      information: {
        action: type,
        error: error ?? undefined,
        siteId,
        status,
        workOrderId: workOrderId ?? undefined,
        workOrderNum,
      },
    };
  }

  protected buildFormGroup(): FormGroup {
    return this.formBuilder.group({
      woParent: this.formBuilder.control(''),
      woType: this.formBuilder.control('', Validators.required),
      source: this.formBuilder.control('', Validators.required),
      domain: this.formBuilder.control('', Validators.required),
      jobTypeOption: this.formBuilder.control('', Validators.required),
      priority: this.formBuilder.control('', Validators.required),
      location: this.formBuilder.control('', Validators.required),
      asset: this.formBuilder.control(''),
      duration: this.formBuilder.control(''),
      actualStartDateTime: this.formBuilder.control('', [
        Validators.required,
        this.wrongDateValidator(),
      ]),
      actualFinishDateTime: this.formBuilder.control('', [
        Validators.required,
        this.wrongFinishDateValidator(),
      ]),
      remarks: this.formBuilder.control('', [
        Validators.maxLength(this.DESCRIPTION_MAX_LENGTH),
        Validators.required,
      ]),
      description: this.formBuilder.control('', [
        Validators.maxLength(this.DESCRIPTION_MAX_LENGTH),
        Validators.required,
      ]),
      longDescription: this.formBuilder.control(
        '',
        Validators.maxLength(this.LONG_DESCRIPTION_MAX_LENGTH)
      ),
      failureAcceptable: this.formBuilder.control(''),
      attachment: this.formBuilder.control(''),
    });
  }

  protected displayCreation(data: QuickReportPutResponse): void {
    this.alSpinnerService.stopDisplay(8);
    AlRequestInformationDialogComponent.open<
      QuickReportPutResponse,
      AlRequestInformationDialogDataQrPut
    >(data, this.dialog, this.buildDialogInformation.bind(this))
      .afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((status) => {
        if (status === 'OK' || status === 'PENDING') {
          this.resetForm();
        }
      });
  }

  protected getNewPutRequest(): QuickReportPut {
    return new QuickReportPut();
  }

  protected initFormGroup(): void {
    const today = new Date();
    const addOneMin = new Date();
    const timeZoneDiff = today.getTimezoneOffset();

    today.setMinutes(today.getMinutes() - timeZoneDiff);
    addOneMin.setMinutes(addOneMin.getMinutes() - timeZoneDiff + 1);

    this.initLocation();

    this.form
      .get('priority')
      ?.setValue(this.reloaded ? this.reloaded.priority : '');

    this.form
      .get('woParent')
      ?.setValue(
        this.reloaded && this.reloaded.parent ? this.reloaded.parent : ''
      );
    this.form.get('woType')?.setValue(this.reloaded ? this.reloaded.type : '');
    this.form
      .get('source')
      ?.setValue(this.reloaded ? this.reloaded.source : '');
    this.form
      .get('domain')
      ?.setValue(this.reloaded ? this.reloaded.domain : '');
    this.form
      .get('jobTypeOption')
      ?.setValue(this.reloaded ? this.reloaded.jobType : '');
    this.form
      .get('priority')
      ?.setValue(this.reloaded ? this.reloaded.priority : '');
    this.form
      .get('asset')
      ?.setValue(this.reloaded ? this.reloaded.assetNum : '');
    this.form
      .get('duration')
      ?.setValue(this.reloaded ? this.reloaded.duration : '');
    this.form
      .get('actualStartDateTime')
      ?.setValue(
        this.reloaded && this.reloaded.actualStart
          ? new Date(this.reloaded.actualStart).toISOString().substring(0, 16)
          : today.toISOString().substring(0, 16)
      );
    this.form
      .get('actualFinishDateTime')
      ?.setValue(
        this.reloaded && this.reloaded.actualFinish
          ? new Date(this.reloaded.actualFinish).toISOString().substring(0, 16)
          : addOneMin.toISOString().substring(0, 16)
      );
    this.form
      .get('remarks')
      ?.setValue(this.reloaded ? this.reloaded.remark : '');
    this.form
      .get('description')
      ?.setValue(this.reloaded ? this.reloaded.description : '');
    this.form
      .get('longDescription')
      ?.setValue(this.reloaded ? this.reloaded.remarkDetails : '');
    this.form
      .get('failureAcceptable')
      ?.setValue(this.reloaded ? this.reloaded.failureAcceptable : false);

    this.form.get('actualStartDateTime')?.markAsTouched();
    this.form.get('actualFinishDateTime')?.markAsTouched();

    if (this.inherit) {
      this.form.get('woParent')?.setValue(this.workOrderParent.number);
    }
    this.checkDateValidators();
  }

  private getActualFinish(): Date | null {
    const formValues = this.form.value;
    const date = Date.parse(formValues.actualFinishDateTime);
    return Number.isNaN(date) ? null : new Date(date);
  }

  private getActualStart(): Date | null {
    const formValues = this.form.value;
    const date = Date.parse(formValues.actualStartDateTime);
    return Number.isNaN(date) ? null : new Date(date);
  }

  private getDuration(): number | null {
    const formValues = this.form.value;
    const duration = Number(formValues.duration);
    return typeof duration !== 'number' ? null : duration;
  }

  private wrongDateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let forbidden = false;
      if (control.value) {
        const futureDate = new Date(control.parent?.value.actualFinishDateTime);
        const pastDate = new Date(control.value);
        forbidden = +futureDate <= +pastDate;
      } else {
        forbidden = false;
      }
      return forbidden ? { wrongDate: { value: control.value } } : null;
    };
  }

  private wrongFinishDateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let forbidden = false;
      if (control.value) {
        const futureDate = new Date(control.value);
        const pastDate = new Date(control.parent?.value.actualStartDateTime);
        forbidden = +futureDate <= +pastDate;
      } else {
        forbidden = false;
      }
      return forbidden ? { wrongDate: { value: control.value } } : null;
    };
  }
}
