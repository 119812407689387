import { Component, Input } from '@angular/core';

import { Equipment } from '@al/entities';

@Component({
  selector: 'al-equipment-details',
  templateUrl: './al-equipment-details.component.html',
  styleUrls: ['./al-equipment-details.component.scss'],
})
export class AlEquipmentDetailsComponent {
  @Input()
  public equipment!: Equipment | null;

  @Input()
  public location!: string | null | undefined;
}
