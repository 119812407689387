export enum LocalStorageKey {
  // # ATTACHMENT
  ATTACHMENT_CREATE_CACHE = 'ATTACHMENT_CREATE_CACHE',
  ATTACHMENT_CREATE_REQUEST_HISTORY = 'ATTACHMENT_CREATE_REQUEST_HISTORY',
  ATTACHMENT_UPDATE_CACHE = 'ATTACHMENT_UPDATE_CACHE',
  ATTACHMENT_UPDATE_REQUEST_HISTORY = 'ATTACHMENT_UPDATE_REQUEST_HISTORY',

  // # EQUIPMENT
  EQUIPMENT_SYNCHRO_LAST_DATE = 'EQUIPMENT_SYNCHRO_LAST_DATE',
  EQUIPMENT_SYNCHRO_LAST_ROWSTAMP = 'EQUIPMENT_SYNCHRO_LAST_ROWSTAMP',

  // # FAILURE REPORT
  FAILURE_REPORT_CACHE = 'FAILURE_REPORT_CACHE',
  FAILURE_REPORT_CREATE_CACHE = 'FAILURE_REPORT_CREATE_CACHE',
  FAILURE_REPORT_CREATE_REQUEST_HISTORY = 'FAILURE_REPORT_CREATE_REQUEST_HISTORY',
  FAILURE_REPORT_SYNCHRO_LAST_DATE = 'FAILURE_REPORT_SYNCHRO_LAST_DATE',
  FAILURE_REPORT_SYNCHRO_LAST_ROWSTAMP = 'FAILURE_REPORT_SYNCHRO_LAST_ROWSTAMP',
  FAILURE_REPORT_UPDATE_CACHE = 'FAILURE_REPORT_UPDATE_CACHE',
  FAILURE_REPORT_UPDATE_REQUEST_HISTORY = 'FAILURE_REPORT_UPDATE_REQUEST_HISTORY',

  // # GOOGLE TAG MANAGEMENT
  GTM_CACHE = 'GTM_CACHE',
  GTM_EMV2_SOURCE = 'GTM_EMV2_SOURCE',

  // # PROCESS GROUP
  PROCESS_GROUP_SYNCHRO_LAST_DATE = 'PROCESS_GROUP_SYNCHRO_LAST_DATE',
  PROCESS_GROUP_SYNCHRO_LAST_ROWSTAMP = 'PROCESS_GROUP_SYNCHRO_LAST_ROWSTAMP',

  // # PRODUCTION UNIT
  PRODUCTION_UNIT_SYNCHRO_LAST_DATE = 'PRODUCTION_UNIT_SYNCHRO_LAST_DATE',
  PRODUCTION_UNIT_SYNCHRO_LAST_ROWSTAMP = 'PRODUCTION_UNIT_SYNCHRO_LAST_ROWSTAMP',

  // # QUICK REPORT
  QUICK_REPORT_CREATE_CACHE = 'QUICK_REPORT_CREATE_CACHE',
  QUICK_REPORT_CREATE_REQUEST_HISTORY = 'QUICK_REPORT_CREATE_REQUEST_HISTORY',
  QUICK_REPORT_SYNCHRO_LAST_DATE = 'QUICK_REPORT_SYNCHRO_LAST_DATE',
  QUICK_REPORT_SYNCHRO_LAST_ROWSTAMP = 'QUICK_REPORT_SYNCHRO_LAST_ROWSTAMP',
  QUICK_REPORT_UPDATE_CACHE = 'QUICK_REPORT_UPDATE_CACHE',
  QUICK_REPORT_UPDATE_REQUEST_HISTORY = 'QUICK_REPORT_UPDATE_REQUEST_HISTORY',

  // # SERVICE REQUEST
  SERVICE_REQUEST_CREATE_CACHE = 'SERVICE_REQUEST_CREATE_CACHE',
  SERVICE_REQUEST_CREATE_REQUEST_HISTORY = 'SERVICE_REQUEST_CREATE_REQUEST_HISTORY',
  SERVICE_REQUEST_SYNCHRO_LAST_DATE = 'SERVICE_REQUEST_SYNCHRO_LAST_DATE',
  SERVICE_REQUEST_SYNCHRO_LAST_ROWSTAMP = 'SERVICE_REQUEST_SYNCHRO_LAST_ROWSTAMP',
  SERVICE_REQUEST_UPDATE_CACHE = 'SERVICE_REQUEST_UPDATE_CACHE',
  SERVICE_REQUEST_UPDATE_REQUEST_HISTORY = 'SERVICE_REQUEST_UPDATE_REQUEST_HISTORY',

  // # START COMPLETION
  START_COMPLETION_CREATE_CACHE = 'START_COMPLETION_CREATE_CACHE',
  START_COMPLETION_CREATE_REQUEST_HISTORY = 'START_COMPLETION_CREATE_REQUEST_HISTORY',
  START_COMPLETION_UPDATE_CACHE = 'START_COMPLETION_UPDATE_CACHE',
  START_COMPLETION_UPDATE_REQUEST_HISTORY = 'START_COMPLETION_UPDATE_REQUEST_HISTORY',

  // # SUBMIT AND CLOSE
  SUBMIT_AND_CLOSE_CREATE_CACHE = 'SUBMIT_AND_CLOSE_CREATE_CACHE',
  SUBMIT_AND_CLOSE_CREATE_REQUEST_HISTORY = 'SUBMIT_AND_CLOSE_CREATE_REQUEST_HISTORY',
  SUBMIT_AND_CLOSE_UPDATE_CACHE = 'SUBMIT_AND_CLOSE_UPDATE_CACHE',
  SUBMIT_AND_CLOSE_UPDATE_REQUEST_HISTORY = 'SUBMIT_AND_CLOSE_UPDATE_REQUEST_HISTORY',

  // # SUBMIT AND COMPLETE
  SUBMIT_AND_COMPLETE_CREATE_CACHE = 'SUBMIT_AND_COMPLETE_CREATE_CACHE',
  SUBMIT_AND_COMPLETE_CREATE_REQUEST_HISTORY = 'SUBMIT_AND_COMPLETE_CREATE_REQUEST_HISTORY',
  SUBMIT_AND_COMPLETE_UPDATE_CACHE = 'SUBMIT_AND_COMPLETE_UPDATE_CACHE',
  SUBMIT_AND_COMPLETE_UPDATE_REQUEST_HISTORY = 'SUBMIT_AND_COMPLETE_UPDATE_REQUEST_HISTORY',

  // # TASK
  TASK_CREATE_CACHE = 'TASK_CREATE_CACHE',
  TASK_CREATE_REQUEST_HISTORY = 'TASK_CREATE_REQUEST_HISTORY',
  TASK_SYNCHRO_LAST_DATE = 'TASK_SYNCHRO_LAST_DATE',
  TASK_SYNCHRO_LAST_ROWSTAMP = 'TASK_SYNCHRO_LAST_ROWSTAMP',
  TASK_UPDATE_CACHE = 'TASK_UPDATE_CACHE',
  TASK_UPDATE_REQUEST_HISTORY = 'TASK_UPDATE_REQUEST_HISTORY',

  // # USER
  USER_RIGHTS = 'USER_RIGHTS',

  //  # WORK ORDER
  WORK_ORDER_CACHE = 'WORK_ORDER_CACHE',
  WORK_ORDER_CREATE_CACHE = 'WORK_ORDER_CREATE_CACHE',
  WORK_ORDER_CREATE_REQUEST_HISTORY = 'WORK_ORDER_CREATE_REQUEST_HISTORY',
  WORK_ORDER_SYNCHRO_LAST_DATE = 'WORK_ORDER_SYNCHRO_LAST_DATE',
  WORK_ORDER_SYNCHRO_LAST_ROWSTAMP = 'WORK_ORDER_SYNCHRO_LAST_ROWSTAMP',
  WORK_ORDER_UPDATE_CACHE = 'WORK_ORDER_UPDATE_CACHE',
  WORK_ORDER_UPDATE_REQUEST_HISTORY = 'WORK_ORDER_UPDATE_REQUEST_HISTORY',
}
