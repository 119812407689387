<div class="app">

  <div *ngIf="!isOnline && isLoggedIn" class="offline">
    <mat-icon>cloud_off</mat-icon><span translate>Offline</span>
  </div>

  <div class="header" *ngIf="isLoggedIn">
    <mat-toolbar class="toolbar">
      <div class="logo">
        <img src="assets/images/logo_airliquide_header_horizontal.png" [alt]="title" class="default">
        <img src="assets/images/logo_airliquide_header_vertical.png" [alt]="title" class="small">
        <img src="assets/images/logo_emaintenance.png" class="default" [alt]="title">
      </div>
      <!-- <img src="assets/images/logo_airliquide_emaintenance.png" [alt]="title" class="logo"> -->

      <al-site-selector *ngIf="!isDashboard"></al-site-selector>

      <span class="spacer"></span>

      <al-maximo-synchronization></al-maximo-synchronization>

      <span class="help">
        <button mat-icon-button matTooltip="Help" (click)="openHelp()">
          <span translate>Help</span>
        </button>
      </span>
      <al-sync></al-sync>
      <al-account></al-account>
    </mat-toolbar>
  </div>

  <div class="content">
    <router-outlet></router-outlet>
  </div>
  <al-spinner></al-spinner>
</div>
