import {
  Attachment,
  QuickReportPut,
  ServiceRequestPut,
  WorkOrder,
  WorkOrderPut,
} from '@al/entities';
import { AlEnvironmentService } from '@al/environment';
import { AlIndexedDbService } from '@al/indexed-db';
import { WorkOrdersQuery } from '@al/state';
import { Platform } from '@angular/cdk/platform';
import { Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-attachments',
  templateUrl: 'al-attachments.component.html',
  styleUrls: ['al-attachments.component.scss'],
})
export class AlAttachmentsComponent implements OnDestroy {
  @Input()
  public putRequest!:
    | (QuickReportPut | ServiceRequestPut | WorkOrderPut)
    | null;

  @Input()
  public title: string;

  public canAddFile: boolean;

  public canAddPhoto: boolean;

  public isMobile: boolean;

  private attachmentsDrive!: Attachment[];

  private attachmentsLocal!: Attachment[];

  private ngUnsubscribe = new Subject();

  private workOrderNumber!: string;

  public constructor(
    private alEnvironmentService: AlEnvironmentService,
    private alIndexedDbService: AlIndexedDbService,
    private platform: Platform,
    private workOrdersQuery: WorkOrdersQuery
  ) {
    this.canAddFile = this.alEnvironmentService.env.canAddFile;
    this.canAddPhoto = this.alEnvironmentService.env.canAddFile;

    this.isMobile = this.platform.ANDROID || this.platform.IOS;

    this.title = '';

    this.attachmentsDrive = [];
    this.attachmentsLocal = [];

    this.workOrdersQuery
      .selectActive()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: WorkOrder | undefined) => {
        if (res) {
          const workOrder = res;
          this.attachmentsDrive = workOrder.attachments;
          this.workOrderNumber = workOrder.number;
          this.updateAttachmentsLocal();
        }
      });

    this.alIndexedDbService.change
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.updateAttachmentsLocal());
  }

  public get attachments(): Attachment[] {
    const attachmentsDrive = this.attachmentsDrive.filter((attachment) => {
      return attachment && attachment.url
        ? attachment.url.startsWith('http')
        : false;
    });
    return [...attachmentsDrive, ...this.attachmentsLocal];
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private updateAttachmentsLocal(): void {
    this.attachmentsLocal = [];
    if (this.workOrderNumber) {
      this.alIndexedDbService
        .getByWorkOrderNumber(this.workOrderNumber)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.attachmentsLocal = [];
            this.attachmentsLocal = result.map((item: any) => {
              return {
                filename: item.newFileName,
                url: '',
                uuid: item.id,
              } as Attachment;
            });
          },
        });
    }
    if (this.putRequest && this.putRequest.uuid) {
      this.alIndexedDbService
        .getByPutRequestUuid(this.putRequest.uuid)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.attachmentsLocal = [];
            this.attachmentsLocal = result.map((item: any) => {
              return {
                filename: item.newFileName,
                url: '',
                uuid: item.id,
              } as Attachment;
            });
          },
        });
    }
  }
}
