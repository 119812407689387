import { Component, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { WorkOrder } from '@al/entities';
import { WorkOrdersQuery } from '@al/state';

@Component({
  selector: 'al-work-order-description',
  templateUrl: 'al-work-order-description.component.html',
  styleUrls: ['al-work-order-description.component.scss'],
})
export class AlWorkOrderDescriptionComponent implements OnDestroy {
  public seeMore: boolean;

  public workOrder!: WorkOrder;

  private ngUnsubscribe = new Subject();

  public constructor(private workOrdersQuery: WorkOrdersQuery) {
    this.seeMore = false;
    this.workOrdersQuery
      .selectActive()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: WorkOrder | undefined) => {
        if (res) {
          this.workOrder = res;
        }
      });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public seeMoreSwitch(): void {
    this.seeMore = !this.seeMore;
  }

  public get getWorkOrderAssetDescription(): string {
    return this.workOrder && this.workOrder.assetDescription
      ? this.workOrder.assetDescription
      : '';
  }
}
