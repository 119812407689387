import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { WorkOrdersService } from '@al/state';
import {
  AlReference,
  AlReferenceData,
  AlReferenceService,
} from '@al/reference';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-table-column-header-filtering',
  templateUrl: './table-column-header-filtering.component.html',
  styleUrls: ['./table-column-header-filtering.component.scss'],
})
export class TableColumnHeaderFilteringComponent implements OnInit, OnDestroy {
  @Input()
  public field!: string;

  @Input()
  public fieldType!: string;

  @Input()
  public title: string;

  public form: FormGroup;

  public objectiveEndDate = '';

  public references: AlReference;

  public selectedPriority = '';

  public selectedWorktype = '';

  public tempPrioritys: AlReferenceData[] | undefined;

  public tempWorktypes: AlReferenceData[] | undefined;

  private ngUnsubscribe = new Subject();

  public constructor(
    private fb: FormBuilder,
    private workOrdersService: WorkOrdersService,
    public alReferenceService: AlReferenceService
  ) {
    this.title = '';
    this.form = this.fb.group({
      value: this.fb.control(''),
    });
    this.references = alReferenceService.getReferenceData([
      'taskPriority',
      'workType',
    ]);
  }

  public clearValue(): void {
    this.submit();
    if (this.fieldType === 'dropDown' && this.field === 'type') {
      this.selectedWorktype = '';
    }
    if (this.fieldType === 'dropDown' && this.field === 'priority') {
      this.selectedPriority = '';
    }
    this.form.get('value')?.setValue('');
    this.workOrdersService.removeFilter(this.field);
    localStorage.removeItem(this.field);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    // eslint-disable-next-line prettier/prettier
    this.workOrdersService.selectAll().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
        if (this.field !== 'targetCompDate') {
          this.form
            .get('value')
            ?.setValue(this.workOrdersService.getFilterValue(this.field));
        }
        this.selectedPriority = this.workOrdersService.getFilterValue(
          this.field
        );
        this.selectedWorktype = this.workOrdersService.getFilterValue(
          this.field
        );
        if (
          this.field === 'targetCompDate' &&
          this.workOrdersService.getFilterValue(this.field) &&
          (this.objectiveEndDate === '' || this.objectiveEndDate === undefined)
        ) {
          const tempDate = new Date(
            this.workOrdersService.getFilterValue(this.field)
          );
          const finalDate = new Date(
            tempDate.getTime() - tempDate.getTimezoneOffset() * 60000
          );
          // eslint-disable-next-line prefer-destructuring
          this.objectiveEndDate = finalDate.toISOString().split('T')[0];
        }

        if (
          this.field === 'priority' &&
          (this.selectedPriority === '' || this.selectedPriority === undefined)
        ) {
          this.tempPrioritys = this.references.taskPriority?.filter((el) => {
            return res.some((f) => {
              return f.priority === el.priority;
            });
          });
        }
        if (
          this.field === 'type' &&
          (this.selectedWorktype === '' || this.selectedWorktype === undefined)
        ) {
          this.tempWorktypes = this.references.workType?.filter((el) => {
            return res.some((f) => {
              return f.type === el.type;
            });
          });
        }
      });
  }

  public submit(): void {
    if (this.fieldType === 'datePicker') {
      this.workOrdersService.setTargetEndDateFilter(
        this.field,
        new Date(this.form.get('value')?.value)
      );
      localStorage.setItem(
        this.field,
        new Date(this.form.get('value')?.value).toISOString()
      );
    } else if (this.fieldType === 'input') {
      if (
        this.form.get('value')?.value != null &&
        this.form.get('value')?.value !== ''
      ) {
        this.workOrdersService.setFilter(
          this.field,
          this.form.get('value')?.value
        );
        localStorage.setItem(
          this.field,
          this.workOrdersService.getFilterValue(this.field)
        );
      }
    } else if (this.fieldType === 'dropDown') {
      if (this.field === 'type') {
        this.workOrdersService.setFilter(this.field, this.selectedWorktype);
        localStorage.setItem(this.field, this.selectedWorktype);
      } else if (this.field === 'priority') {
        this.workOrdersService.setFilter(this.field, this.selectedPriority);
        localStorage.setItem(this.field, this.selectedPriority);
      }
    }
  }
}
