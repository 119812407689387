import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PutRequest, PutResponse, Get, LocalStorageKey } from '@al/entities';

import { AlJsonConvert } from '@al/json';

@Injectable({
  providedIn: 'root',
})
export abstract class AbstractRestService<
  T extends Get,
  U extends PutRequest,
  V extends PutResponse
> {
  protected atJsonConvert: AlJsonConvert;

  protected abstract keyLastDate: LocalStorageKey;

  protected abstract keyLastRowstamp: LocalStorageKey;

  public constructor(protected http: HttpClient) {
    this.atJsonConvert = new AlJsonConvert();
  }

  protected abstract get createEndPoint(): string;

  protected abstract get getEndPoint(): string;

  protected abstract get updateEndPoint(): string;

  public create(item: U): Observable<V> {
    return this.http.put<V>(
      this.createEndPoint,
      this.atJsonConvert.serializeObject(item, this.getPutRequestClazz())
    );
  }

  public get(refresh = false): Observable<T[]> {
    const httpHeaders = this.getSynchroHeaders(refresh);

    return this.http
      .get<T[]>(this.getEndPoint, {
        headers: httpHeaders,
        observe: 'response',
      })
      .pipe(
        map((data: HttpResponse<T[]>) => {
          this.updateLocalStorageWithHeaders(data);

          const items = this.atJsonConvert.deserializeArray<T>(
            data.body || [],
            this.getClazz()
          );

          return items;
        })
      );
  }

  public update(item: U): Observable<V> {
    return this.http.put<V>(
      this.updateEndPoint,
      this.atJsonConvert.serializeObject(item, this.getPutRequestClazz())
    );
  }

  private getSynchroHeaders(refresh = false): HttpHeaders {
    let previousRowstamp: string | null = localStorage.getItem(
      this.keyLastRowstamp
    );
    if (!previousRowstamp) {
      previousRowstamp = '0';
    }
    let previousSynchro: string | null = localStorage.getItem(this.keyLastDate);
    if (!previousSynchro || refresh) {
      previousSynchro = new Date(0).toISOString();
    }
    const httpHeaders = new HttpHeaders({
      rowstamp: previousRowstamp,
      sync: previousSynchro,
    });
    return httpHeaders;
  }

  private updateLocalStorageWithHeaders(entities: any): boolean {
    // get last synchro time
    const lastSynchro = localStorage.getItem(this.keyLastDate);

    // get rowstamp and store it in localStorage
    const rowstamp: string | null = entities.headers.get('rowstamp');
    if (rowstamp != null) {
      localStorage.setItem(
        LocalStorageKey.WORK_ORDER_SYNCHRO_LAST_ROWSTAMP,
        rowstamp
      );
    }

    // get syncDate and store it in localStorage
    const syncDate: string | null = entities.headers.get('syncDate');
    if (syncDate) {
      localStorage.setItem(
        LocalStorageKey.WORK_ORDER_SYNCHRO_LAST_DATE,
        syncDate
      );
    }

    // get new synchro time
    const newSynchro = localStorage.getItem(this.keyLastDate);

    return lastSynchro !== newSynchro;
  }

  protected abstract getClazz(): any;

  protected abstract getPutRequestClazz(): any;

  protected abstract getPutResponseClazz(): any;
}
