import { LocalStorageConfiguration } from '@al/entities';
import { AlEnvironmentService } from '@al/environment';
import { dbConfig } from '@al/indexed-db';
import { AlOnlineService } from '@al/online';
import { AlSessionService } from '@al/session';
import { AlSpinnerService } from '@al/spinner';
import {
  DateSelectorService,
  EquipmentsService,
  ProcessGroupsService,
  ProductionUnitsService,
  SiteService,
  WorkOrdersService,
  WorkOrdersStore,
} from '@al/state';
import { SyncInfoService } from '@al/sync-services';
import { Component, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Auth } from 'aws-amplify';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  public displayProgressSpinner = false;

  public isOnline: boolean;

  public title: string;

  private currentUrl: string;

  private isLoggedIn$: boolean;

  private ngUnsubscribe = new Subject();

  public get isDashboard(): boolean {
    const regexp = /^\/(dashboard)/;
    return regexp.test(this.currentUrl);
  }

  public get isLoggedIn(): boolean {
    const regexp = /^\/(login|logout)/;
    return this.isLoggedIn$ && !regexp.test(this.currentUrl);
  }

  public constructor(
    private alEnvironmentService: AlEnvironmentService,
    private alSessionService: AlSessionService,
    private appService: AppService,
    private dateSelectorService: DateSelectorService,
    private dbService: NgxIndexedDBService,
    private equipmentsService: EquipmentsService,
    private gtmService: GoogleTagManagerService,
    private onLineService: AlOnlineService,
    private processGroupsService: ProcessGroupsService,
    private productionUnitsService: ProductionUnitsService,
    private router: Router,
    private siteService: SiteService,
    private synchroInfoService: SyncInfoService,
    private titleService: Title,
    private translateService: TranslateService,
    private workOrderService: WorkOrdersService,
    private workOrderStore: WorkOrdersStore,
    private alSpinnerService: AlSpinnerService
  ) {
    this.dbService.createObjectStore(dbConfig.objectStoresMeta[0]);

    this.isOnline = false;
    this.onLineService.status
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.isOnline = res;
      });

    this.title = this.alEnvironmentService.env.title;
    this.titleService.setTitle(this.title);

    this.currentUrl = this.router.url;
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((event: any) => {
        this.currentUrl = event.urlAfterRedirects;
        this.navigationGtmEvent(this.currentUrl);
      });

    this.isLoggedIn$ = false;

    window.addEventListener('message', (message: MessageEvent<string>) => {
      if (message.data === 'activated') {
        this.appService.setServiceWorkerState(true);
      }
    });

    Auth.currentSession()
      .then((session) => {
        this.isLoggedIn$ = true;

        // TODO: Add Callback content
        const idTokenDecoded =
          this.alSessionService.setIdTokenToLocalStorage(session);
        if (idTokenDecoded.defaultSite) {
          this.alSessionService.setAccessTokenToLocalStorage(session);
          this.alSessionService.setUserIdToLocalStorage(session);
          this.alSessionService.setDefaultSiteToLocalStorage(session);
          this.alSessionService.setUserRightsToCache(session);
          this.alSessionService.setLanguage(session);

          this.initStaticStores();

          this.initUserValues();
          this.alSpinnerService.startDisplay(14);
          this.workOrderService
            .get()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: () => {
                this.setActiveWorkOrder();
                this.initFilters();
                this.alSpinnerService.stopDisplay(14);
                this.synchroInfoService.initStoreFromCache();
              },
              error: (error) => {
                // TODO: Utiliser un logger global
                // eslint-disable-next-line no-console
                console.error('intitial load Error', error);
                this.alSpinnerService.stopDisplay(14);
                this.alSpinnerService.launchSnackBar(
                  "your WO didn't load, please reload page",
                  null
                );
              },
            });

          setInterval(
            () =>
              this.workOrderService
                .get()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(),
            60 * 60 * 1000
          );

          this.equipmentsService
            .get()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe();
          this.processGroupsService
            .get()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe();
          this.productionUnitsService
            .get()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe();
        } else {
          localStorage.clear();
          localStorage.setItem(
            LocalStorageConfiguration.USER_ID,
            'site_missing'
          );
          this.router.navigate(['/']);
        }
      })
      .catch(() => {
        localStorage.clear();
        localStorage.setItem(LocalStorageConfiguration.USER_ID, 'login_failed');
        this.router.navigate(['/']);
      });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public openHelp(): void {
    window.open(
      'https://urldefense.com/v3/__https:/drive.google.com/drive/u/0/folders/16HrFzVvJQ0OSH9TKO5zN0sz85o-nBovf__;!!GDyMDYbfvpeH7A!BKeJ-BQetjIENDlrzD7KUCB8QU9WqsSM6NngM6UyZqn8RNOwBQUuBP1J3Uvs9C7d_wo$'
    );
  }

  private initFilterFromLocalStorage(
    localKey: LocalStorageConfiguration
  ): void {
    const item = localStorage.getItem(localKey);
    if (item) {
      this.workOrderService.setFilter(localKey, item);
    }
  }

  private initFilters() {
    // set filters on 'standard' filtered column
    this.initFilterFromLocalStorage(LocalStorageConfiguration.FILTER_NUMBER);
    this.initFilterFromLocalStorage(
      LocalStorageConfiguration.FILTER_DESCRIPTION
    );
    this.initFilterFromLocalStorage(
      LocalStorageConfiguration.FILTER_ASSET_NUMBER
    );
    this.initFilterFromLocalStorage(LocalStorageConfiguration.FILTER_LOCATION);
    this.initFilterFromLocalStorage(LocalStorageConfiguration.FILTER_TYPE);
    this.initFilterFromLocalStorage(LocalStorageConfiguration.FILTER_PRIORITY);

    // set filter on the unique date column
    const dateFilter = localStorage.getItem(
      LocalStorageConfiguration.FILTER_TARGET_DATE
    );
    if (dateFilter) {
      const targetCompDate = new Date(dateFilter);
      this.workOrderService.setTargetEndDateFilter(
        LocalStorageConfiguration.FILTER_TARGET_DATE,
        targetCompDate
      );
    }
  }

  private initStaticStores(): void {
    this.siteService.populate();
    this.dateSelectorService.populate();
  }

  private initUserValues(): void {
    const idToken = localStorage.getItem(
      LocalStorageConfiguration.ID_TOKEN_DECODED
    );
    if (idToken) {
      const idTokenDecoded = JSON.parse(idToken);
      this.alSessionService.initSessionStore(idTokenDecoded);
    }
    this.siteService.setActiveFromLocalStorage();
    this.dateSelectorService.setActiveFromLocalStorage();
    const language = localStorage.getItem(
      LocalStorageConfiguration.LANGUAGE_KEY
    );
    if (language) {
      this.translateService.setDefaultLang(language);
      this.translateService.use(language);
    } else {
      this.translateService.setDefaultLang('fr');
    }
  }

  private navigationGtmEvent(currentUrl: string): void {
    const gtmTag = {
      event: 'page',
      pageUrl: currentUrl,
    };
    this.gtmService.pushTag(gtmTag);
  }

  private setActiveWorkOrder(): void {
    const currentWorkOrder = localStorage.getItem(
      LocalStorageConfiguration.CURRENT_WORK_ORDER_ID
    );
    if (currentWorkOrder) {
      this.workOrderStore.setActive(currentWorkOrder);
    }
  }
}
