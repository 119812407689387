import {
  Attachment,
  AttachmentItem,
  LocalStorageConfiguration,
  LocalStorageKey,
  Site,
  Task,
  TaskPut,
  WorkOrder,
  WorkOrderPut,
  WorkOrderPutResponse,
  WorkOrderStatus,
} from '@al/entities';
import {
  AttachmentWoSyncService,
  StartCompletionSyncService,
  SubmitAndCloseSyncService,
  SubmitAndCompleteSyncService,
  SyncInfoService,
  TaskSyncService,
  WorkOrderSyncService,
} from '@al/sync-services';
import { HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { arrayUpdate, ID } from '@datorama/akita';
import { AkitaFiltersPlugin } from 'akita-filters-plugin';
import { UUID } from 'angular2-uuid';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { DateSelectorService } from './date-selector.service';
import { SiteService } from './site.service';
import { WorkOrdersQuery } from './work-orders.query';
import { WorkOrdersState, WorkOrdersStore } from './work-orders.store';

@Injectable({ providedIn: 'root' })
export class WorkOrdersService implements OnDestroy {
  private TAG = 'WorkOrdersService>>>';

  private filtersWorkOrder: AkitaFiltersPlugin<WorkOrdersState>;

  private ngUnsubscribe = new Subject();

  public constructor(
    private attachmentWoSyncService: AttachmentWoSyncService,
    private dateSelectorService: DateSelectorService,
    private siteService: SiteService,
    private startCompletionSynchroService: StartCompletionSyncService,
    private submitAndCloseSynchroService: SubmitAndCloseSyncService,
    private submitAndCompleteSynchroService: SubmitAndCompleteSyncService,
    private synchroInfoService: SyncInfoService,
    private taskSynchroService: TaskSyncService,
    private workOrdersQuery: WorkOrdersQuery,
    private workOrdersStore: WorkOrdersStore,
    private workOrderSynchroService: WorkOrderSyncService
  ) {
    this.filtersWorkOrder = new AkitaFiltersPlugin<WorkOrdersState>(
      this.workOrdersQuery
    );
    // pour éviter la dependance circulaire lorsque le synchro info service cherche un wo pour les merges
    // des PutReponses concernant un meme wo
    this.synchroInfoService.setGetWorkOrderHandler((number: string) => {
      return this.findByNumber(number);
    });

    this.submitAndCompleteSynchroService.setGetWorkOrderHandler(
      (number: string) => {
        return this.findByNumber(number);
      }
    );

    this.submitAndCloseSynchroService.setGetWorkOrderHandler(
      (number: string) => {
        return this.findByNumber(number);
      }
    );
  }

  public addAttachment(
    attachmentItem: AttachmentItem,
    googleDriveFileId: string,
    googleDriveFileName: string
  ): Observable<boolean> {
    const obs = new BehaviorSubject(false);
    this.workOrdersQuery
      .selectEntity(
        (wo: WorkOrder) => wo.number === attachmentItem.workOrderNumber
      )
      .pipe(take(1))
      .subscribe({
        next: (item) => {
          const workOrder = item;
          if (workOrder) {
            // https://drive.google.com/file/d/11IRf9KO28tXfzIyc1szdCbzawnJhCRSi/view?usp=sharing
            const attachmentUrl = `https://drive.google.com/file/d/${googleDriveFileId}/view`;
            const attachment = new Attachment();
            attachment.filename = googleDriveFileName;
            attachment.url = attachmentUrl;
            attachment.uuid = UUID.UUID();
            const attachments = [attachment, ...workOrder.attachments];
            // STORE
            this.workOrdersStore.update(
              (wo: WorkOrder) => wo.id === workOrder.id,
              { attachments }
            );
            // BACK
            const workOrderPut = new WorkOrderPut();
            if (workOrder.siteId) {
              workOrderPut.siteId = workOrder.siteId;
            }
            if (workOrder.id) {
              workOrderPut.id = workOrder.id;
            }
            if (workOrder.number) {
              workOrderPut.number = workOrder.number;
            }
            workOrderPut.attachments = [attachment];
            // CACHE
            this.workOrderSynchroService.addToCache(
              this.workOrdersQuery.getEntity(workOrder.id)
            );
            this.attachmentWoSyncService
              .update(workOrderPut)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe(() => {
                obs.next(true);
              });
          } else {
            const attachmentUrl = `https://drive.google.com/file/d/${googleDriveFileId}/view`;
            const attachment = new Attachment();
            attachment.filename = googleDriveFileName;
            attachment.url = attachmentUrl;
            attachment.uuid = UUID.UUID();
            const workOrderPut = new WorkOrderPut();
            if (attachmentItem.site) {
              workOrderPut.siteId = attachmentItem.site;
            }
            if (attachmentItem.workOrderNumber) {
              workOrderPut.number = attachmentItem.workOrderNumber;
            }
            //  else if (attachmentItem.ticketId) {
            //   workOrderPut.number = attachmentItem.ticketId.toString();
            // }
            workOrderPut.attachments = [attachment];
            this.attachmentWoSyncService
              .update(workOrderPut)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe(() => {
                obs.next(true);
              });
          }
        },
      });
    return obs.asObservable();
  }

  // vide le store : pour le changement de site
  public clear(): void {
    this.workOrdersStore.reset();
  }

  public create(request: WorkOrderPut): Observable<WorkOrderPutResponse> {
    return this.workOrderSynchroService.create(request);
  }

  public findByNumber(num: string): Observable<WorkOrder> {
    return this.workOrdersQuery.selectEntity(
      (number: WorkOrder) => number.number === num
    );
  }

  public get(reload: boolean = false): Observable<void> {
    return this.workOrderSynchroService
      .getFromSiteAndPeriod(
        this.siteService.getActive()
          ? this.siteService.getActive()
          : new Site(),
        this.dateSelectorService.getActive(),
        reload
      )
      .pipe(
        map((data: WorkOrder[]) => {
          // update store with back return
          data.forEach((value) => {
            this.workOrdersStore.upsert(value.id, value);
          });
          // update store with cache
          this.workOrdersStore.upsertMany(
            this.workOrderSynchroService.getCacheValue()
          );
        })
      );
  }

  public getFilterValue(id: string): any | null {
    return this.filtersWorkOrder.filtersQuery.getEntity(id)?.value;
  }

  public getWorkOrderByParent(id: string | null): Observable<WorkOrder[]> {
    return this.workOrdersQuery.selectAll({
      filterBy: (index) => index.parent === id,
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public removeFilter(id: string): void {
    this.filtersWorkOrder.removeFilter(id);
  }

  public selectAll(): Observable<WorkOrder[]> {
    return this.filtersWorkOrder.selectAllByFilters() as Observable<
      WorkOrder[]
    >;
  }

  public setActive(id: ID | null): void {
    this.workOrdersStore.setActive(id);
    if (id) {
      localStorage.setItem(
        LocalStorageConfiguration.CURRENT_WORK_ORDER_ID,
        id.toString()
      );
    } else {
      localStorage.removeItem(LocalStorageConfiguration.CURRENT_WORK_ORDER_ID);
    }
  }

  public setActiveByNumber(myNumber: string): void {
    const workOrderObs = this.workOrdersQuery.selectEntity(
      (entity: WorkOrder) => {
        return entity.number === myNumber;
      }
    );
    workOrderObs.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      if (res) {
        this.setActive(res.id);
      }
    });
  }

  public setActiveWorkOrderInCache(): void {
    const currentWorkOrder = this.workOrdersQuery.getActive();
    if (currentWorkOrder) {
      this.workOrderSynchroService.addToCache(currentWorkOrder);
    }
  }

  // TODO: Filter may have to be review
  public setDelayedFilter(): void {
    this.filtersWorkOrder.setFilter({
      id: 'DELAYED',
      value: new Date(),
      predicate: (value: WorkOrder) => {
        return !!(
          value.status === WorkOrderStatus.RLSD &&
          value.schedDateFin &&
          new Date(value.schedDateFin) < new Date()
        );
      },
    });
  }

  public setFilter(field: string, form: string): void {
    this.filtersWorkOrder.setFilter({
      id: field,
      value: form,
      predicate: (value: WorkOrder) =>
        (value as any)[field] != null &&
        (value as any)[field]
          .toString()
          .toLowerCase()
          .includes(form.toString().toLowerCase()),
    });
  }

  public setMeasure(workOrder: WorkOrder, task: Task, rebootAdj: boolean) {
    // STORE
    this.workOrdersStore.update(
      (wo: WorkOrder) => wo.id === workOrder.id,
      ({ tasks }) => ({
        tasks: arrayUpdate(tasks, task.id, task),
      })
    );
    // BACK
    const taskPut = new TaskPut();
    if (workOrder.siteId) {
      taskPut.siteId = workOrder.siteId;
    }
    if (workOrder.number) {
      taskPut.number = workOrder.number;
    }
    if (task.id) {
      taskPut.taskId = task.id;
    }
    if (task.measurementDate) {
      taskPut.measurementDate = task.measurementDate;
    }
    if (task.measurementValue || task.measurementValue === 0) {
      taskPut.measurementValue = task.measurementValue;
    }
    if (task.status) {
      taskPut.status = task.status;
    }
    if (task.status1) {
      taskPut.status1 = task.status1;
    }
    if (rebootAdj) {
      taskPut.status2 = task.status2;
      taskPut.measurementAfterAdj = task.measurementAfterAdj;
    }
    this.taskSynchroService
      .update(taskPut)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe();
    // CACHE
    this.setActiveWorkOrderInCache();
  }

  public setNextActive(): void {
    this.workOrdersStore.setActive({ next: true });
    this.updateLocalStorage();
  }

  public setPrevActive(): void {
    this.workOrdersStore.setActive({ prev: true });
    this.updateLocalStorage();
  }

  public setSkippedOrDone(workOrder: WorkOrder, task: Task): void {
    // STORE
    this.workOrdersStore.update(
      (wo: WorkOrder) => wo.id === workOrder.id,
      ({ tasks }) => ({
        tasks: arrayUpdate(tasks, (t) => t.id === task.id, task),
      })
    );

    // BACK
    const taskPut = new TaskPut();
    if (workOrder.siteId) {
      taskPut.siteId = workOrder.siteId;
    }
    if (workOrder.number) {
      taskPut.number = workOrder.number;
    }
    if (task.id) {
      taskPut.taskId = task.id;
    }
    if (task.endDate) {
      taskPut.endDate = task.endDate;
    }
    if (task.status) {
      taskPut.status = task.status;
    }
    this.taskSynchroService
      .update(taskPut)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe();
    // CACHE
    this.setActiveWorkOrderInCache();
  }

  public setTargetEndDateFilter(field: string, targetEndDate: Date): void {
    this.filtersWorkOrder.setFilter({
      id: field,
      value: targetEndDate,
      predicate: (workOrder: WorkOrder) => {
        return (
          ((workOrder as any).schedDateFin != null &&
            new Date((workOrder as any).schedDateFin).setHours(0, 0, 0, 0) ===
              targetEndDate.setHours(0, 0, 0, 0)) ||
          ((workOrder as any).targetCompDate != null &&
            (workOrder as any).schedDateFin === null &&
            new Date((workOrder as any).targetCompDate).setHours(0, 0, 0, 0) ===
              targetEndDate.setHours(0, 0, 0, 0))
        );
      },
    });
  }

  public setToFinishFilter(): void {
    this.filtersWorkOrder.setFilter({
      id: 'TO_FINISH',
      value: new Date(),
      predicate: (value: WorkOrder) =>
        !!(value.status === WorkOrderStatus.RLSD && value.actDateDebut),
    });
  }

  public startCompletion(
    workOrder: WorkOrder
  ): Observable<WorkOrderPutResponse | null> {
    if (!workOrder.actDateDebut) {
      const date = new Date();
      const actualStartDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0,
        0,
        0,
        0
      );
      // STORE
      const userId = localStorage.getItem('userId');
      if (userId) {
        this.workOrdersStore.update((wo: WorkOrder) => wo.id === workOrder.id, {
          actDateDebut: actualStartDate,
          leadUid: userId,
        });
      }
      // BACK
      const workOrderPut = new WorkOrderPut();
      if (workOrder.siteId) {
        workOrderPut.siteId = workOrder.siteId;
      }
      if (workOrder.id) {
        workOrderPut.id = workOrder.id;
      }
      workOrderPut.actualStart = actualStartDate;
      if (workOrder.number) {
        workOrderPut.number = workOrder.number;
      }
      // CACHE
      this.setActiveWorkOrderInCache();
      return this.startCompletionSynchroService
        .update(workOrderPut)
        .pipe(takeUntil(this.ngUnsubscribe));
    }
    return new Observable<null>();
  }

  public submitAndClose(
    workOrder: WorkOrder
  ): Observable<WorkOrderPutResponse> {
    const put = this.getPutMinimalPutRequest(workOrder);
    put.actualFinish = workOrder.failureDate
      ? workOrder.failureDate
      : new Date();
    put.status = WorkOrderStatus.CLOSE;

    this.setActiveWorkOrderInCache();
    return this.submitAndCloseSynchroService.update(put).pipe(
      map((response) => {
        this.updateStoreWithResponse(response, workOrder, put);
        return response;
      })
    );
  }

  public submitAndComplete(
    workOrder: WorkOrder
  ): Observable<WorkOrderPutResponse> {
    const put = this.getPutMinimalPutRequest(workOrder);
    put.actualFinish = workOrder.failureDate
      ? workOrder.failureDate
      : new Date();
    put.status = WorkOrderStatus.COMP;

    return this.submitAndCompleteSynchroService.update(put).pipe(
      map((response) => {
        this.updateStoreWithResponse(response, workOrder, put);
        return response;
      })
    );
  }

  private getPutMinimalPutRequest(workOrder: WorkOrder): WorkOrderPut {
    const workOrderPut = new WorkOrderPut();

    if (workOrder.siteId) {
      workOrderPut.siteId = workOrder.siteId;
    }
    if (workOrder.id) {
      workOrderPut.id = workOrder.id;
    }
    if (workOrder.number) {
      workOrderPut.number = workOrder.number;
    }
    return workOrderPut;
  }

  private updateLocalStorage(): void {
    const uuid = this.workOrdersQuery.getActive()?.id.toString();
    localStorage.setItem(
      LocalStorageConfiguration.CURRENT_WORK_ORDER_ID,
      <string>uuid
    );
  }

  private updateLocalStorageWithHeaders(
    entities: HttpResponse<WorkOrder[]>
  ): void {
    // get rowstamp and store it in localStorage
    const rowstamp: string | null = entities.headers.get('rowstamp');
    if (rowstamp != null) {
      localStorage.setItem(
        LocalStorageKey.WORK_ORDER_SYNCHRO_LAST_ROWSTAMP,
        rowstamp
      );
    }

    // get syncDate and store it in localStorage
    const syncDate: string | null = entities.headers.get('syncDate');
    if (syncDate) {
      localStorage.setItem(
        LocalStorageKey.WORK_ORDER_SYNCHRO_LAST_DATE,
        syncDate
      );
    }
  }

  private updateStoreWithResponse(
    response: WorkOrderPutResponse,
    workOrder: WorkOrder,
    workOrderPut: WorkOrderPut
  ) {
    if (response.status === 'OK' || response.status === 'PENDING') {
      this.workOrdersStore.update((wo: WorkOrder) => wo.id === workOrder.id, {
        status: workOrderPut.status,
        actDateFin: workOrderPut.actualFinish,
      });
      this.setActiveWorkOrderInCache();
    }
  }
}
