import { AlReferenceData } from './model/al-reference-data.model';

export const DomainReference: ReadonlyArray<AlReferenceData> = [
  { domain: 'ARA', label: 'ARA' },
  { domain: 'ATEX', label: 'Atex' },
  { domain: 'AUTHORITYBRZO', label: 'BRZO' },
  { domain: 'AUTHORITYSEVESO', label: 'Seveso' },
  { domain: 'CNHM', label: 'CnHm' },
  { domain: 'CUSTOMERS', label: 'Customers' },
  { domain: 'EFF', label: 'EFFICIENCY' },
  { domain: 'EIS', label: 'EIS' },
  { domain: 'ENV', label: 'Environment' },
  { domain: 'ENV_ANOMALY', label: 'ENV ANOMALY' },
  { domain: 'ENV_INCIDENT', label: 'ENV INCIDENT' },
  { domain: 'FIAB', label: '' },
  { domain: 'FINANCE', label: 'Finance' },
  { domain: 'FOOD', label: 'Food Safety' },
  { domain: 'GMP/GDPMEDICAL', label: 'GMP / GDP Medical' },
  {
    domain: 'HACCP (FOOD & PHARMA)',
    label: 'Maintenance intrusive en aval des stockages',
  },
  { domain: 'IMSGROUP', label: 'IMS Groeps audit' },
  { domain: 'INSURANCE', label: 'Insurance' },
  { domain: 'ISO14001', label: 'ISO-14001 Certification ' },
  { domain: 'ISO17025', label: 'ISO-17025 Certification ' },
  { domain: 'ISO22000', label: 'ISO-22000 Certification ' },
  { domain: 'ISO90001', label: 'ISO-9001 Certification ' },
  { domain: 'LEGALCOMPLIANCEVBS', label: 'VBS (int audit dr ext gedaan)' },
  { domain: 'MAINT', label: '' },
  { domain: 'MOC', label: 'Management of change' },
  { domain: 'PED', label: 'drukvaten keuring' },
  { domain: 'PHARMA', label: 'Pharmacopée' },
  { domain: 'PROCESS', label: 'Process  (ex support …)' },
  { domain: 'PRODUCTQUALITY', label: 'Product Quality' },
  { domain: 'PROJECT', label: 'Project' },
  { domain: 'QUA', label: 'Quality' },
  { domain: 'QUA_ANOMALY', label: 'QUA ANOMALY' },
  { domain: 'QUA_INCIDENT', label: 'QUA INCIDENT' },
  { domain: 'RADIATION', label: 'Radiation' },
  { domain: 'RECALL', label: 'Recall' },
  { domain: 'REGULATORY', label: 'REGULATORY' },
  { domain: 'REPORT', label: 'Marked for a reporting' },
  { domain: 'RIAB', label: 'Reliability' },
  { domain: 'SEC', label: 'Health & Safety' },
  { domain: 'SEC_ACCIDENT', label: 'H&S_ACCIDENT' },
  { domain: 'SEC_IPG', label: 'H&S_IPG' },
  { domain: 'SECU_ALIM', label: 'Sécurité alimentaire (HACCP, ISO 22000)' },
  { domain: 'SMILE/MANAGEMENTSYSTEM', label: 'Milieu' },
  { domain: 'SPAREPARTS/WAREHOUSE', label: 'Spare Parts / Warehouse' },
  { domain: 'TECH', label: '' },
  { domain: 'WORKPERMITLOTO', label: 'Work Permit - LOTO' },
];
