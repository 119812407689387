import { LocalStorageKey, Site } from '@al/entities';
import { AlOnlineService } from '@al/online';
import { AlSpinnerService } from '@al/spinner';
import {
  EquipmentsService,
  ProcessGroupsService,
  ProductionUnitsService,
  SiteQuery,
  SiteService,
  WorkOrdersService,
} from '@al/state';
import { SyncInfoService } from '@al/sync-services';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-site-selector',
  templateUrl: './al-site-selector.component.html',
  styleUrls: ['./al-site-selector.component.scss'],
})
export class AlSiteSelectorComponent implements OnInit, OnDestroy {
  @Input()
  public enabled: boolean;

  @Input()
  public isSelectable: boolean;

  public isDoneLoading = 0;

  public isOnline!: boolean;

  public isSynchronized!: boolean;

  public items: Site[];

  public get selectedItemName(): string {
    return this.selectedItem ? this.selectedItem.name : '';
  }

  public selectedItemUuid: UUID | null;

  private ngUnsubscribe = new Subject();

  private selectedItem!: Site;

  public constructor(
    private equipmentServices: EquipmentsService,
    private onLineService: AlOnlineService,
    private processGroupsService: ProcessGroupsService,
    private productionUnitsService: ProductionUnitsService,
    private siteService: SiteService,
    private siteQuery: SiteQuery,
    private synchroInfoService: SyncInfoService,
    private workOrdersService: WorkOrdersService,
    private alSpinnerService: AlSpinnerService
  ) {
    this.enabled = false;
    this.isSelectable = false;
    this.isSynchronized = false;
    this.selectedItemUuid = null;
    this.items = [];
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public ngOnInit(): void {
    this.alSpinnerService.startDisplay(1);
    this.siteQuery
      .selectAll()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (items) => {
          this.items = items;
          if (this.items.length > 0) {
            const activeSite = this.siteQuery.getActive();
            this.selectedItem = activeSite || this.items[0];
            this.selectedItemUuid = this.selectedItem.uuid;
            this.siteService.setActive(this.selectedItem.uuid);
            this.alSpinnerService.stopDisplay(1);
          }
        },
      });
    this.onLineService.status
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.isOnline = res;
      });

    this.synchroInfoService.isSynchronized
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.isSynchronized = res;
      });
  }

  public onValueChange(uuid: UUID): void {
    this.alSpinnerService.startDisplay(2);
    this.isDoneLoading = 4;
    this.siteService.setActive(uuid);

    localStorage.removeItem(LocalStorageKey.EQUIPMENT_SYNCHRO_LAST_DATE);
    localStorage.removeItem(LocalStorageKey.PROCESS_GROUP_SYNCHRO_LAST_DATE);
    localStorage.removeItem(LocalStorageKey.PRODUCTION_UNIT_SYNCHRO_LAST_DATE);
    localStorage.removeItem(LocalStorageKey.QUICK_REPORT_SYNCHRO_LAST_DATE);
    localStorage.removeItem(LocalStorageKey.WORK_ORDER_SYNCHRO_LAST_DATE);

    localStorage.removeItem(LocalStorageKey.EQUIPMENT_SYNCHRO_LAST_ROWSTAMP);
    localStorage.removeItem(
      LocalStorageKey.PROCESS_GROUP_SYNCHRO_LAST_ROWSTAMP
    );
    localStorage.removeItem(
      LocalStorageKey.PRODUCTION_UNIT_SYNCHRO_LAST_ROWSTAMP
    );
    localStorage.removeItem(LocalStorageKey.QUICK_REPORT_SYNCHRO_LAST_ROWSTAMP);
    localStorage.removeItem(LocalStorageKey.WORK_ORDER_SYNCHRO_LAST_ROWSTAMP);
    // si changement de site alors il faut vider le store
    this.workOrdersService.clear();
    this.workOrdersService
      .get()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.displayProgressSpinner(1);
      });

    this.equipmentServices
      .get()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.displayProgressSpinner(1);
      });

    this.processGroupsService
      .get()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.displayProgressSpinner(1);
      });

    this.productionUnitsService
      .get()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.displayProgressSpinner(1);
      });
  }

  private displayProgressSpinner(value: number) {
    this.isDoneLoading -= value;
    if (this.isDoneLoading === 0) {
      this.alSpinnerService.stopDisplay(2);
    }
  }
}
