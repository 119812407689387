<div class="al-attachments">
  <h2>{{title | translate}}</h2>
  <div class="attachment-column">
    <ng-container *ngIf="attachments.length > 0; else elseTemplate">
      <al-attachment-item *ngFor="let attachment of attachments" [attachment]=attachment></al-attachment-item>
    </ng-container>
    <ng-template #elseTemplate>
      <p translate>No attachment</p>
    </ng-template>
  </div>
  <div class="upload">
    <al-photo [putRequest]="putRequest" *ngIf="isMobile && canAddPhoto"></al-photo>
    <al-file [putRequest]="putRequest" *ngIf="canAddFile"></al-file>
  </div>
</div>
