import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  fromEvent,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';

import { AlEnvironmentService, AwsEndPoint } from '@al/environment';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AlOnlineService implements OnDestroy {
  public mustRenewToken: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public status: Observable<boolean>;

  private isInternet: boolean;

  private isNetworkConnected: boolean;

  private ngUnsubscribe = new Subject();

  private offlineEvent!: Observable<Event>;

  private onlineEvent!: Observable<Event>;

  private status$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private subscriptions: Subscription[] = [];

  public constructor(
    private environmentService: AlEnvironmentService,
    private http: HttpClient
  ) {
    this.status = this.status$.asObservable();

    this.isInternet = false;

    this.isNetworkConnected = navigator.onLine;
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(
      this.onlineEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
        this.isNetworkConnected = true;
        this.checkOnlineRequest();
      })
    );
    this.subscriptions.push(
      this.offlineEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
        this.isNetworkConnected = false;
        this.checkOnlineRequest();
      })
    );
    this.checkOnlineRequest();

    setInterval(() => this.checkOnlineRequest(), 30000);
  }

  public checkOnlineRequest(): void {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('crossorigin', 'anonymous');
    const url = this.environmentService.env.apigwUrl + AwsEndPoint.healthCheck;
    if (this.isNetworkConnected) {
      this.http
        .get(url, {
          headers,
          observe: 'body',
        })
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.isInternet = true;
            this.status$.next(this.onLine());
          },
          error: () => {
            this.isInternet = false;
            this.status$.next(this.onLine());
          },
        });
    } else {
      this.isInternet = false;
      this.status$.next(this.onLine());
    }
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onLine(): boolean {
    return this.isNetworkConnected && this.isInternet;
  }
}
