import { Component, Input, OnInit } from '@angular/core';

import { CalibrationStatus, Task, WorkOrder } from '@al/entities';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlWorkOrderTaskListService } from './al-work-order-task-list.service';

@Component({
  selector: 'al-work-order-task-calibration',
  templateUrl: './al-work-order-task-calibration.component.html',
  styleUrls: ['./al-work-order-task-calibration.component.scss'],
})
export class AlWorkOrderTaskCalibrationComponent implements OnInit {
  @Input()
  public calibrationIndex!: number;

  @Input()
  public editable!: boolean;

  @Input()
  public task!: Task;

  @Input()
  public workOrder!: WorkOrder;

  public calibrationMeasure!: number | null;

  public calibrationStatus!: CalibrationStatus | null;

  public form!: FormGroup;

  public constructor(
    private alWorkOrderTaskListService: AlWorkOrderTaskListService,
    private formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group({
      measure: [this.calibrationMeasure, Validators.required],
    });
  }

  public cancel(): void {
    this.calibrationMeasure = this.getCalibrationMeasure();
    this.calibrationStatus = this.getCalibrationStatus();
    this.form.get('measure')?.setValue(this.calibrationStatus);
    this.formatMeasure();
  }

  public checkCalibrationMeasure(): void {
    this.formatMeasure();
    this.setCalibrationStatus();
  }

  public get delta(): number {
    if (
      this.task &&
      typeof this.task.standardInput === 'number' &&
      typeof this.calibrationMeasure === 'number'
    ) {
      return this.calibrationMeasure - this.task.standardInput;
    }
    return 0;
  }

  public get deltaPercent(): number {
    if (this.task && this.task.standardInput) {
      return (this.delta * 100) / this.task.standardInput;
    }
    return 0;
  }

  public get getTaskType(): string {
    return this.task && this.task.taskType ? this.task.taskType : '-';
  }

  public get getTaskUnit(): string {
    return this.task && this.task.unit ? this.task.unit : '';
  }

  public measureMinus(): void {
    const measure = Number(this.form.get('measure')?.value);
    this.form.get('measure')?.setValue(measure - 1);
    this.form.markAsDirty();
    this.checkCalibrationMeasure();
  }

  public measurePlus(): void {
    const measure = Number(this.form.get('measure')?.value);
    this.form.get('measure')?.setValue(measure + 1);
    this.form.markAsDirty();
    this.checkCalibrationMeasure();
  }

  public ngOnInit(): void {
    this.calibrationMeasure = this.getCalibrationMeasure();
    this.calibrationStatus = this.getCalibrationStatus();
    this.formatMeasure();
  }

  public startEdition(): void {
    this.editable = true;
    this.form.get('measure')?.setValue(this.calibrationMeasure);
  }

  public validate(): void {
    if (
      this.calibrationStatus &&
      (this.calibrationMeasure || this.calibrationMeasure === 0)
    ) {
      this.editable = false;
      if (this.calibrationIndex === 0) {
        if (
          this.calibrationStatus === CalibrationStatus.CHECKED &&
          this.task.status2
        ) {
          this.alWorkOrderTaskListService.setMeasurement(
            this.task,
            this.calibrationMeasure,
            this.calibrationStatus,
            true
          );
        } else {
          this.alWorkOrderTaskListService.setMeasurement(
            this.task,
            this.calibrationMeasure,
            this.calibrationStatus,
            false
          );
        }
      } else {
        this.alWorkOrderTaskListService.setMeasurementAfterAdj(
          this.task,
          this.calibrationMeasure,
          this.calibrationStatus
        );
      }
      this.alWorkOrderTaskListService.nextTask();
    }
  }

  // decimal: 3 decimal
  // size: 15
  // decimal separator: , (coma)
  // thousand separator: space
  private formatMeasure(): void {
    const measureFormControl = this.form.get('measure');
    if (
      measureFormControl &&
      (measureFormControl.value || measureFormControl.value === 0)
    ) {
      const { value } = measureFormControl;
      const measure = this.parseFloat(value);
      const formattedValue = measure.toFixed(3);

      this.calibrationMeasure = measure;
      measureFormControl.setValue(formattedValue);
    }
  }

  private getCalibrationMeasure(): number | null {
    const measurement =
      this.calibrationIndex === 0
        ? this.task.measurementValue
        : this.task.measurementAfterAdj;
    return measurement;
  }

  private getCalibrationStatus(): CalibrationStatus | null {
    return this.calibrationIndex === 0 ? this.task.status1 : this.task.status2;
  }

  private parseFloat(value: string | number): number {
    const str = value.toString();
    const parsed = parseFloat(str);
    return typeof parsed === 'number' ? parsed : 0;
  }

  /**
   * Three status must be implemented to qualify the calibration measures:
   * a. CHECKED : status displayed if the measure correspond to the expected value in Maximo
   * b. ADJUST : status displayed if (standard input - Tolerance) < measure > standard input
   *             OR standard input < measure > (standard input + Tolerance)
   * c. ALARM : status displayed if measure < (standard input - Tolerance)
   */
  private setCalibrationStatus(): void {
    const standardInput = this.parseFloat(this.task.standardInput || 0);
    let minChecked: number;
    let maxChecked: number;
    let minAdjustment: number;
    let maxAdjustment: number;

    if (this.task.tolerance) {
      minChecked = standardInput - (this.task.tolerance || 0);
      maxChecked = standardInput + (this.task.tolerance || 0);
      minAdjustment = standardInput - (2 * this.task?.tolerance || 0);
      maxAdjustment = standardInput + (2 * this.task.tolerance || 0);
    } else {
      minChecked = standardInput;
      maxChecked = standardInput;
      minAdjustment = standardInput;
      maxAdjustment = standardInput;
    }
    if (
      (this.calibrationMeasure || this.calibrationMeasure === 0) &&
      this.calibrationMeasure >= minChecked &&
      (this.calibrationMeasure || this.calibrationMeasure === 0) &&
      maxChecked >= this.calibrationMeasure
    ) {
      this.calibrationStatus = CalibrationStatus.CHECKED;
    } else if (
      (this.calibrationMeasure || this.calibrationMeasure === 0) &&
      this.calibrationMeasure > minAdjustment &&
      (this.calibrationMeasure || this.calibrationMeasure === 0) &&
      maxAdjustment > this.calibrationMeasure
    ) {
      this.calibrationStatus = CalibrationStatus.ADJUSTMENT;
    } else if (
      (this.calibrationMeasure || this.calibrationMeasure === 0) &&
      (this.calibrationMeasure <= minAdjustment ||
        maxAdjustment <= this.calibrationMeasure)
    ) {
      this.calibrationStatus = CalibrationStatus.ALARM;
    } else {
      this.calibrationStatus = null;
    }
  }
}
